import React, { useState } from 'react'
import { Image } from 'react-native'

export type ImgProps = {
	source: string
	style: any
	hideDefault?: boolean
}
/**
 * Web-only compoenent to display images
 * Use standard React Native Image component as FastImage isn't compatible with web
 */
export default function ImageObject(props: ImgProps) {
	const { source, style, hideDefault } = props
	const [loading, setLoading] = useState(true)
	const handleLoad = () => setLoading(false)

	if (hideDefault) {
		return (
			<>
				<Image
					resizeMethod={'resize'}
					source={{ uri: source }}
					style={[style, { display: loading ? 'none' : 'flex' }]}
					onLoadEnd={handleLoad}
				/>
			</>
		)
	} else {
		return (
			<>
				{loading && (
					<Image
						resizeMethod={'auto'}
						resizeMode="cover"
						source={require('../../assets/images/shimmer.gif')}
						style={style}
					/>
				)}
				<Image
					resizeMethod={'auto'}
					resizeMode="cover"
					source={{ uri: source }}
					style={[style, { display: loading ? 'none' : 'flex' }]}
					onLoadEnd={handleLoad}
				/>
			</>
		)
	}
}

export function preLoadImage(source = '') {
	return null
}
