import React, { useContext } from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import { Text } from './Themed'
import { SafeAreaView } from 'react-native-safe-area-context'
import { xsTextSize } from '../constants/GlobalStyle'

const InternetConnectionBanner = ({ hide }) => {
	if (hide) return null
	return (
		<SafeAreaView
			edges={['left', 'right', 'top']}
			style={[
				styles.container,
				{
					backgroundColor: global.orgColor,
					paddingTop: Platform.OS === 'web' ? 5 : 0,
					paddingBottom: 5,
				},
			]}
		>
			<Text style={styles.connectionText}>No internet connection. Trying to reconnect...</Text>
		</SafeAreaView>
	)
}

const styles = StyleSheet.create({
	container: {
		justifyContent: 'center',
		alignItems: 'center',
	},
	connectionText: {
		color: '#FFFFFF',
		fontSize: xsTextSize,
		fontWeight: 'bold',
	},
})

export default InternetConnectionBanner
