import { StatusBar } from 'expo-status-bar'
import React, { useState } from 'react'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import useCachedResources from './hooks/useCachedResources'
import useColorScheme from './hooks/useColorScheme'
import Navigation from './navigation'
import { Provider } from 'react-redux'
import { Dimensions, Linking, LogBox, Platform } from 'react-native'
import { CardField } from '@stripe/stripe-react-native'
import RetrieveData from './helpers/fbDataRetrieval/RetrieveData'
import qs from 'qs'
import IdleContextProvider from './components/Kiosk/IdleContextProvider'
import DataContextProvider from './helpers/fbDataRetrieval/DataContextProvider'
import * as Font from 'expo-font'
import {
	Feather,
	MaterialCommunityIcons,
	MaterialIcons,
	FontAwesome5,
	Ionicons,
	AntDesign,
} from '@expo/vector-icons'
import { store } from './state/store'
import { logAppOpenAnalytics } from './firebase/analytics'
import RetrieveLocation from './helpers/fbDataRetrieval/RetrieveLocation'
import Idle from './components/Kiosk/Idle'
import Colors from './constants/Colors'
import InternetConnection from './components/InternetConnection'
export function WebCardElement() {
	return null
}

export function MobileCardElement({ setIsCardValid, setAnalyticsMethod = null }) {
	if (Platform.OS !== 'web') {
		return (
			<>
				<CardField
					onFocus={cardDetails => {
						console.log('focus', cardDetails)
					}}
					onCardChange={cardDetails => {
						console.log(cardDetails)
						const isValid =
							cardDetails.complete &&
							cardDetails.validNumber &&
							cardDetails.validCVC &&
							cardDetails.validExpiryDate
						setIsCardValid(isValid === 'Valid')
						if (setAnalyticsMethod) {
							setAnalyticsMethod({
								brand: cardDetails.brand || '',
								last4: cardDetails.last4 || '',
							})
						}
					}}
					ref={global.payRef}
					countryCode="CA"
					postalCodeEnabled={true}
					placeholders={{
						number: 'Card number',
					}}
					cardStyle={{
						backgroundColor: Colors.custom.appBackground,
						textColor: '#000000',
					}}
					style={{
						width: '95%',
						height: 50,
						marginVertical: 30,
					}}
				/>
			</>
		)
	}
}

export default function App() {
	const [rCode, setRCode] = useState('')

	const [logAppOpen, setLogAppOpen] = useState(true)

	if (logAppOpen) {
		logAppOpenAnalytics()
		setLogAppOpen(false)
	}
	Linking.getInitialURL().then(url => {
		if (url) {
			const { referralCode } = qs.parse(url.split('?')[1])
			if (typeof referralCode === 'string') {
				setRCode(referralCode)
			}
		}
	})
	Font.loadAsync({
		// Load the Feather font
		...Feather.font,
		...MaterialCommunityIcons.font,
		...MaterialIcons.font,
		...FontAwesome5.font,
		...Ionicons.font,
		...AntDesign.font,
		DefaultFont:
			global.org === 'CA-BC-Vancouver-MemphisBlues' && Platform.OS !== 'web'
				? require('./assets/fonts/western.otf')
				: 'System',
		//HeaderFont: require('./assets/fonts/western.woff2'),
	})

	const isLoadingComplete = useCachedResources()

	// currently only light mode is supported.
	const colorScheme = useColorScheme()

	// This line will prevent displaying logs of this type in your emulator
	LogBox.ignoreLogs(['new NativeEventEmitter'])

	// This line will prevent displaying all warning logs in your emulator
	LogBox.ignoreAllLogs()

	if (!isLoadingComplete) {
		return null
	} else {
		return (
			<Provider store={store}>
				<SafeAreaProvider>
					<DataContextProvider>
						<IdleContextProvider>
							<InternetConnection>
								{global.kiosk ? (
									<>
										<Idle>
											<Navigation colorScheme={colorScheme} />
											<StatusBar translucent={true} hidden={true} />
										</Idle>
									</>
								) : (
									<RetrieveData referralCode={rCode} loadingTracker={null}>
										<RetrieveLocation />

										<Navigation colorScheme={colorScheme} />
										<StatusBar />
									</RetrieveData>
								)}
							</InternetConnection>
						</IdleContextProvider>
					</DataContextProvider>
				</SafeAreaProvider>
			</Provider>
		)
	}
}
