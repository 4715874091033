import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import {
	View,
	Image,
	FlatList,
	Dimensions,
	TouchableHighlight,
	Platform,
	TouchableOpacity,
	Animated,
	TextInput,
} from 'react-native'
import { Text } from '../../../components/Themed'
import MenuItemCard from './MenuItemCard'
import GlobalStyle, {
	MED_WIDTH,
	SIDEBAR_WIDTH,
	SMALL_WIDTH,
	headerTextSize,
	mediumTextSize,
	smallTextSize,
	titleTextSize,
} from '../../../constants/GlobalStyle'
import { MenuHeader } from './Headers'
import CategorySidebar from './CategorySidebar'
import { styles } from '../Helpers/style'
import Colors from '../../../constants/Colors'
import { responsiveHeight } from 'react-native-responsive-dimensions'
import { FontAwesome5 } from '@expo/vector-icons'
import Fuse from 'fuse.js'
import _ from 'lodash'
import { DataContext, RestaurantScreenContext } from '../../../state/context'
import NavFooter from '../../../components/Footers/NavFooter'
import { calculateOptimalCardWidth } from '../../../helpers/calculateOptimalCardWidth'
import {
	logSearchMenuAnalytics,
	logSelectItemAnalytics,
	logSelectMenuAnalytics,
} from '../../../firebase/analytics'
import { LocationSelectModal } from '../../../components/LocationSelect'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../state/reducer'
import TapToLogout from '../../../components/Kiosk/TapToLogout'
import POSNavBar from '../../../navigation/POSNavBar'
import ExitIcon from '../../../components/Headers/UIButtons'
import { WebHeader } from '../../../components/Headers/WebHeader'
import CopyrightFooter from '../../../components/Footers/CopyrightFooter'
import KioskStartScreen from '../../RestaurantSelect/Components/KioskStartScreen'
import DeliveryPickupToggle from '../../../components/Delivery/DeliveryPickupToggle'
import { checkSnooze } from '../../../helpers/checkSnooze'
import {
	Timestamp,
	collection,
	doc,
	getDocs,
	limit,
	onSnapshot,
	orderBy,
	query,
	startAfter,
	where,
} from 'firebase/firestore'
import { DATABASE_NAME } from '@env'
import { auth, db } from '../../../firebase/config'
import {
	setLocalTimezone,
	setLocId,
	setAppId,
	setRestaurantId,
	setDeveloperFee,
	setImageLogo,
	setImageHero,
	setAddress,
	setPrepTime,
	setName,
	setCouponCodes,
	setSocial,
	setSchedulingJump,
	setSnoozeUntil,
	setSlogan,
	setIsSnoozed,
	setHours,
} from '../../../state/actions'
/**
 * Menu page for a specific brand
 * Differs from category menu in that it shows all items in a brand,
 * Has a search bar, more detailed header, and a category sidebar/top bar
 */
const BrandMenu = ({
	navigation,
	ctgTokens,
	storeOpen,
	heroImageURI,
	logoImageURI,
	rName,
	hoursText,
	rId,
	address,
}) => {
	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)

	const locationSettings = useSelector<ReduxStoreState, ReduxStoreState['locationSettings']>(
		state => state.locationSettings
	)

	const organizationLogo = useSelector<ReduxStoreState, ReduxStoreState['organizationLogo']>(
		state => state.organizationLogo
	)

	const restaurants = useSelector<ReduxStoreState, ReduxStoreState['restaurants']>(
		state => state.restaurants
	)

	const visibleRestaurant = restaurants.find(restaurant => restaurant.id === rId)

	const cart = useSelector<ReduxStoreState, ReduxStoreState['items']>(state => state.items)

	const singleBrandEnabled = loadedData[global.org].singleBrandEnabled || false

	//Find screen width accounting for margins / padding
	const multiplier = singleBrandEnabled ? 0.1 : 0
	const screenWidth = SMALL_WIDTH
		? Dimensions.get('window').width * 0.93
		: Dimensions.get('window').width > 1450 && !MED_WIDTH && singleBrandEnabled
		? Dimensions.get('window').width * 0.95 - SIDEBAR_WIDTH - 20
		: Dimensions.get('window').width > 1450 && !MED_WIDTH
		? 1225 - SIDEBAR_WIDTH
		: !MED_WIDTH
		? Dimensions.get('window').width * (0.85 + multiplier) - SIDEBAR_WIDTH - 20
		: Dimensions.get('window').width * (0.82 + multiplier)

	// if (singleBrandEnabled && Dimensions.get('window').width > 1300) {
	// 	screenWidth += Dimensions.get('window').width * 0.1
	// }

	const card = calculateOptimalCardWidth(
		screenWidth,
		!SMALL_WIDTH && MED_WIDTH && !singleBrandEnabled ? 180 : 200
	)
	const CARD_WIDTH = card.width

	const searchScreenWidth =
		Dimensions.get('window').width > 1450
			? 1300 * 0.9
			: SMALL_WIDTH
			? Dimensions.get('window').width * 0.9
			: Dimensions.get('window').width * 0.8
	const searchCard = calculateOptimalCardWidth(
		searchScreenWidth,
		!SMALL_WIDTH && MED_WIDTH ? 180 : 200
	)
	const SEARCH_CARD_WIDTH = searchCard.width

	const flatListRef = useRef(null)
	const inputRef = useRef(null)
	const stickySidebarRef = useRef(null) // question why same ref to multiple components appear to not work

	const [isSticky, setSticky] = useState(false)
	const [loading, setLoading] = useState(false)
	const [menuItems, setMenuItems] = useState([])
	const [fuse, setFuse] = useState(null)
	const [slideAnim, setSlideAnim] = useState(new Animated.Value(-100))
	const [isBottom, setIsBottom] = useState(false)
	const [isSnoozedState, setIsSnoozedState] = useState(false)

	const {
		searchText,
		setSearchText,
		showResults,
		setShowResults,
		showDropdown,
		setShowDropdown,
		isSearchActive,
		setSearchActive,
	} = useContext(RestaurantScreenContext)

	const {
		restaurantList,
		showKioskLocations,
		setShowKioskLocations,
		showKioskStartScreen,
		setShowKioskStartScreen,
		setActiveRID,
		activeRID,
		setActiveOrder,
		updatedData,
		deliveryEnabled,
	} = useContext(DataContext)

	const [locationsModalVisible, setLocationsModalVisible] = useState(
		locationSettings !== null ? false : true
	)

	const toggleDropdown = () => {
		if (showDropdown) {
			//dropdown is open, close it
			setShowResults(false)

			setTimeout(() => {
				if (inputRef.current) {
					inputRef.current.blur()
				}
				setShowDropdown(false)
			}, 300)
		} else {
			if (inputRef.current) {
				inputRef.current.focus()
			}
			setShowDropdown(!showDropdown)
		}
	}

	const handleScroll = event => {
		const offsetY = event.nativeEvent.contentOffset.y
		const contentHeight = event.nativeEvent.contentSize.height
		const height = event.nativeEvent.layoutMeasurement.height

		const paddingBottom = 200
		if (offsetY + height >= contentHeight - paddingBottom) {
			setIsBottom(true)
		} else {
			setIsBottom(false)
		}

		if (!showDropdown) {
			const scrollY = event.nativeEvent.contentOffset.y
			const stickyThreshold = 200
			if (scrollY >= stickyThreshold && !isSticky) {
				setSticky(true)
			} else if (scrollY < stickyThreshold && isSticky) {
				setSticky(false)
			}
		}
	}

	const [visibleCategory, setVisibleCategory] = useState(null)
	const [visibleCategories, setVisibleCategories] = useState([])

	useEffect(() => {
		if (stickySidebarRef && visibleCategory) {
			const index = buildCategoryList.findIndex(item => item.name === visibleCategory)
			stickySidebarRef.current?.scrollToIndex({
				index,
				animated: true,
				viewOffset: 100,
			})
		}
	}, [visibleCategory])

	const dispatch = useDispatch()
	useEffect(() => {
		if (singleBrandEnabled) {
			if (rId !== undefined && rId !== '' && visibleRestaurant) {
				const restaurant = visibleRestaurant
				logSelectMenuAnalytics('brand_menu', restaurant.id) //log analytics

				dispatch(setHours(restaurant.hours))
				dispatch(setLocId(restaurant.locationId))
				dispatch(setAppId(restaurant.applicationId))
				dispatch(setRestaurantId(restaurant.id))
				dispatch(setDeveloperFee(restaurant.developerFee))
				dispatch(setImageLogo(restaurant.logoImg))
				dispatch(setImageHero(restaurant.heroImg))
				dispatch(setAddress(restaurant.address))
				dispatch(setPrepTime(restaurant.prepTime))
				dispatch(setName(restaurant.name))
				dispatch(setCouponCodes(restaurant.couponCodes))
				dispatch(setSocial(restaurant.social))
				dispatch(setSchedulingJump(restaurant.schedulingJump))
				dispatch(setIsSnoozed(restaurant.isSnoozed))
				dispatch(setSnoozeUntil(restaurant.snoozeUntil))
				dispatch(setSlogan(restaurant.slogan))
			} else {
				console.log('Could not load.')
			}
		}
	}, [])

	const viewabilityConfig = useRef({
		itemVisiblePercentThreshold: 50,
	}).current

	const addExtraComponents = (list, searchResults = false) => {
		const cardsPerRow = searchResults ? searchCard.cardsPerRow : card.cardsPerRow
		if (list.length % cardsPerRow > 1) {
			for (let i = 0; i < cardsPerRow; i++) {
				list.push({ id: `invisible_${i}`, invisible: true })
			}
		}
		return list
	}

	// todo: remove duplication with CategoryMenu
	const renderRowItems = (name, rowItems, searchResults = false) => (
		<View
			style={{
				flexDirection: 'row',
				flexWrap: 'wrap',
				justifyContent: 'space-between',
				marginBottom: searchResults ? 0 : '4%',
			}}
		>
			{rowItems.map((token, index) => (
				<View key={token.id + '_branditem_' + index}>
					{token.invisible ? (
						<View style={{ width: searchResults ? SEARCH_CARD_WIDTH : CARD_WIDTH }}>
							<View style={{ width: '100%', height: 0 }} />
						</View>
					) : (
						<TouchableHighlight
							style={{ marginBottom: '15%' }}
							key={token.id + index}
							underlayColor={Colors.custom.appBackground}
							onPress={() => {
								if (global.kiosk && !storeOpen) {
									console.log('Closed')
								} else {
									logSelectItemAnalytics(rId, rName, token.price, {
										item_id: token.id,
										item_name: token.name,
										item_category: name,
									})

									navigation.navigate('Item', {
										token: token.id,
										editing: null,
										isOpen: storeOpen,
										menuItemCategory: name,
										menuId: rId,
										isRestaurantSnoozed: isSnoozedState,
									})
								}
							}}
						>
							<View style={{ width: searchResults ? SEARCH_CARD_WIDTH : CARD_WIDTH }}>
								<MenuItemCard
									storeOpen={storeOpen}
									key={token.id + index}
									id={token.id}
									name={token.name}
									desc={token.desc}
									price={token.price}
									imageUri={token.image}
									promoPrice={token.promoPrice}
									width={searchResults ? SEARCH_CARD_WIDTH : CARD_WIDTH}
									variationMinPrice={token.variationMinPrice}
									variationMaxPrice={token.variationMaxPrice}
									cart={cart}
								/>
							</View>
						</TouchableHighlight>
					)}
				</View>
			))}
		</View>
	)

	const buildCategoryList = useMemo(() => {
		const categoryList = []

		const items = []

		Object.entries(ctgTokens).forEach(([name, tokens]: any) => {
			categoryList.push({
				name: name,
				items: addExtraComponents(tokens),
			})
			tokens.forEach(token => {
				if (!token.id.startsWith('invisible_')) {
					items.push({
						...token,
					})
				}
			})
		})
		// Set weighting for search term relation to items
		const options = {
			threshold: 0.45,
			keys: [
				{ name: 'name', weight: 0.45 },
				{ name: 'desc', weight: 0.3 },
				{ name: 'category', weight: 0.25 },
			],
		}

		setFuse(new Fuse(items, options)) // Initialize the fuzzy search
		return categoryList
	}, [ctgTokens, visibleCategory])

	const onViewableItemsChanged = useRef(({ viewableItems }) => {
		// if (viewableItems.length > 0) {
		// 	setVisibleCategory(viewableItems[0].item.name)
		// }
		setVisibleCategories(viewableItems)
	}).current

	useEffect(() => {
		if (
			isBottom &&
			visibleCategories.some(
				item => item.key === buildCategoryList[buildCategoryList.length - 1].items[0].id
			)
		) {
			setVisibleCategory(visibleCategories[visibleCategories.length - 1].item.name)
		} else if (visibleCategories.length > 0) {
			setVisibleCategory(visibleCategories[0].item.name)
		}
	}, [visibleCategories])

	useEffect(() => {
		if (isSearchActive) {
			// Animate to the height of the dropdown when it's open
			Animated.timing(slideAnim, {
				toValue: 0,
				duration: 300,
				useNativeDriver: false,
			}).start()
			Animated.timing(slideAnim, {
				toValue: 0,
				duration: 300,
				useNativeDriver: false,
			}).start()
		} else {
			Animated.timing(slideAnim, {
				toValue: -100,
				duration: 300,
				useNativeDriver: false,
			}).start()
		}
	}, [isSearchActive])

	// Function to group search results by category while maintaining order
	const groupResultsByCategoryOrder = results => {
		const groupedByCategory = {}
		const orderedCategories = []

		results.forEach(({ item }) => {
			const category = item.category

			// Check if this category has already been encountered
			if (!groupedByCategory[category]) {
				groupedByCategory[category] = []
				orderedCategories.push(category) // Maintain the order of categories
			}

			groupedByCategory[category].push(item)
		})

		// Convert the grouped object into an ordered array of objects
		return orderedCategories.map(categoryName => {
			return {
				name: categoryName,
				items: addExtraComponents(groupedByCategory[categoryName], true),
			}
		})
	}
	const debouncedCompareTextRef = useRef(null)
	useEffect(() => {
		if (fuse) {
			debouncedCompareTextRef.current = _.debounce(text => {
				if (text.length > 0) {
					const result = fuse.search(text)
					setMenuItems(groupResultsByCategoryOrder(result))
					logSearchMenuAnalytics(text)
					setLoading(false)
				} else {
					setMenuItems([])
					setLoading(false)
				}
			}, 400)
		}
		return () => {
			if (debouncedCompareTextRef.current) {
				debouncedCompareTextRef.current.cancel()
			}
		}
	}, [fuse])

	const onSearchTextChange = text => {
		if (text.trim() !== '' && text !== null) {
			setShowResults(true)
			setLoading(true)
			setSearchText(text)
			if (debouncedCompareTextRef.current) {
				debouncedCompareTextRef.current(text)
			}
		} else {
			setShowResults(false)
			setSearchText('')
		}
	}

	// Drops down a text input when the search icon is pressed
	const Dropdown = () => {
		return (
			<View style={[styles.searchOverlay, { display: showDropdown ? 'flex' : 'none' }]}>
				<Animated.View
					style={[
						styles.searchDropdown,
						{ marginTop: slideAnim },
						Platform.OS !== 'web' && {
							height: '15%',
							paddingTop: Platform.OS === 'android' ? 10 : '10%',
						},
					]}
				>
					<TextInput
						ref={inputRef}
						autoFocus={isSearchActive}
						placeholder={`Search ${rName}`}
						onChangeText={text => {
							onSearchTextChange(text)
						}}
						value={searchText}
						style={styles.searchInput}
					/>
					<TouchableOpacity
						onPress={() => {
							toggleDropdown()
							onSearchTextChange('')
							setSearchActive(false)
						}}
					>
						<Text style={{ fontSize: smallTextSize }}>Cancel</Text>
					</TouchableOpacity>
				</Animated.View>

				<View
					style={[
						styles.searchResultContainer,
						{ display: loading && showResults ? 'flex' : 'none' },
					]}
				>
					<Image
						style={{
							width: 100,
							height: 100,
							alignSelf: 'center',
							marginTop: responsiveHeight(40),
							//marginLeft: responsiveWidth(50),
						}}
						source={require('../../../assets/images/loadImg.gif')}
					/>
				</View>

				<View
					style={[
						styles.searchResultContainer,
						{ display: loading || menuItems.length <= 0 || !showResults ? 'none' : 'flex' },
					]}
				>
					<FlatList
						data={menuItems}
						renderItem={({ item }) => (
							<View
								key={item.name + item.items[0].id}
								style={{ marginRight: '5%', marginLeft: '5%' }}
							>
								{item.name && (
									<Text style={[GlobalStyle.titleText, { color: global.orgColor2 }]}>
										{item.name.toUpperCase()}
									</Text>
								)}

								{renderRowItems(item.name, item.items, true)}
							</View>
						)}
						keyExtractor={item => item.items[0].id}
						contentContainerStyle={{
							paddingTop: responsiveHeight(15) > 100 ? responsiveHeight(15) : 100, // dropdown is 10% tall w/ minHeight 80px
							paddingBottom: !singleBrandEnabled
								? responsiveHeight(15) > 100
									? responsiveHeight(12)
									: 100
								: 0, //bottom bar is max 80px
						}}
					/>
				</View>

				<View
					style={[
						styles.searchResultContainer,
						{
							display:
								!loading &&
								menuItems.length <= 0 &&
								searchText !== '' &&
								searchText !== ' ' &&
								searchText !== null
									? 'flex'
									: 'none',
							alignItems: 'center',
							justifyContent: 'center',
						},
					]}
				>
					<FontAwesome5
						name="drumstick-bite"
						size={headerTextSize}
						color={Colors.greyscale[7]}
						style={{ alignSelf: 'center' }}
					/>

					<Text style={{ fontSize: mediumTextSize, color: Colors.greyscale[7], marginTop: 10 }}>
						{"We couldn't find a match"}
					</Text>
					<Text style={{ fontSize: smallTextSize, color: Colors.greyscale[5], marginTop: 3 }}>
						{'Please try a new search'}
					</Text>
				</View>
			</View>
		)
	}
	useEffect(() => {
		if (!global.kiosk) {
			setActiveRID(rId)
		}
		setIsSnoozedState(checkSnooze(loadedData[rId].isSnoozed, loadedData[rId].snoozeUntil))
	}, [updatedData])

	useEffect(() => {
		if (!global.kiosk) {
			const uid = auth.currentUser ? auth.currentUser.uid : ''
			const orderRef = collection(doc(db, DATABASE_NAME, global.org), 'Orders')
			const timeWindow = Timestamp.fromDate(new Date(Date.now() - 2 * 60 * 60 * 1000)) // 2 hours window

			const fetchOrders = (startAfterTimestamp = null) => {
				let q = query(
					orderRef,
					where('userId', '==', uid),
					where('orderDate', '>=', timeWindow),
					orderBy('orderDate', 'desc'),
					limit(3)
				)

				if (startAfterTimestamp) {
					q = query(q, startAfter(startAfterTimestamp))
				}

				const unsubscribe = onSnapshot(q, snapshot => {
					let foundOrder = null

					for (const change of snapshot.docChanges()) {
						const data = change.doc.data()
						if (
							data.status !== 'Pending' &&
							data.status !== 'Failed' &&
							data.status !== 'Completed'
						) {
							foundOrder = data
							break
						}
					}

					if (foundOrder) {
						setActiveOrder(foundOrder)
					} else if (!snapshot.empty) {
						const lastVisible = snapshot.docs[snapshot.docs.length - 1]
						fetchOrders(lastVisible)
					}
				})

				return unsubscribe
			}

			const unsubscribe = fetchOrders()
			return () => unsubscribe() // Cleanup on unmount
		}
	}, [])

	return singleBrandEnabled && showKioskStartScreen && !global.pos && global.kiosk ? (
		<KioskStartScreen
			defaultLogo={organizationLogo}
			imageList={loadedData.promoImages ? loadedData.promoImages : []}
			setVisible={setShowKioskStartScreen}
		/>
	) : (
		<>
			{singleBrandEnabled && restaurantList.length > 1 && !global.kiosk && (
				<LocationSelectModal
					visible={global.kiosk ? showKioskLocations : locationsModalVisible}
					setVisible={global.kiosk ? setShowKioskLocations : setLocationsModalVisible}
					navigation={navigation}
					rIdToken={rId}
				/>
			)}

			{Platform.OS === 'web' && singleBrandEnabled && (
				<WebHeader
					logo={isSticky && MED_WIDTH ? organizationLogo : null}
					navigation={navigation}
					returnToTop={() => {
						flatListRef.current.scrollToOffset({ offset: 0, animated: true })
					}}
				/>
			)}

			<View style={{ flex: 1 }}>
				<View
					style={
						isSticky && MED_WIDTH
							? {
									position: 'absolute',
									top: 0,
									left: 0,
									right: 0,
									zIndex: 1,
									display: 'flex',
									paddingTop: Platform.OS === 'web' ? 20 : 60,
									backgroundColor: 'white',
							  }
							: { display: 'none' }
					}
				>
					<CategorySidebar
						categories={Object.keys(ctgTokens)}
						style={{ flex: 1 }}
						logo={logoImageURI}
						horizontalScroll={true}
						selectedCategory={visibleCategory}
						ref={stickySidebarRef}
						onSelectCategory={category => {
							const index = buildCategoryList.findIndex(item => item.name === category)
							flatListRef.current?.scrollToIndex({
								index,
								animated: true,
								viewOffset: 100,
							})
						}}
					/>
				</View>
				{Dropdown()}
				{!singleBrandEnabled && !showDropdown && (
					<ExitIcon
						onPress={() => {
							if (Platform.OS === 'web') {
								navigation.replace('HomeStack', { screen: 'RestaurantSelect' })
							} else {
								navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
							}
						}}
					/>
				)}
				{ctgTokens && Object.entries(ctgTokens).length !== 0 ? (
					<FlatList
						data={buildCategoryList}
						initialNumToRender={3}
						ref={flatListRef}
						onViewableItemsChanged={onViewableItemsChanged}
						viewabilityConfig={viewabilityConfig}
						onScroll={handleScroll}
						scrollEventThrottle={16}
						windowSize={41}
						renderItem={({ item }) => (
							<View
								key={item.name + item.items[0].id}
								style={{
									marginRight: '5%',
									marginLeft: MED_WIDTH ? '5%' : 270,
									opacity: isSnoozedState ? 0.65 : 1,
								}}
							>
								{item.name && (
									<Text
										style={[
											GlobalStyle.titleText,
											{ color: global.orgColor2, fontSize: titleTextSize },
										]}
									>
										{item.name.toUpperCase()}
									</Text>
								)}

								{renderRowItems(item.name, item.items)}
							</View>
						)}
						keyExtractor={item => item.items[0].id}
						ListHeaderComponent={
							<>
								<MenuHeader
									HeroImageURI={heroImageURI}
									LogoImageURI={MED_WIDTH ? logoImageURI : null}
									navigation={navigation}
									fill={MED_WIDTH ? 'white' : Colors.custom.appBackground}
									rName={rName}
									hoursText={isSnoozedState ? 'Not taking orders right now.' : hoursText}
									toggle={() => {
										toggleDropdown()
										setSearchActive(true)
									}}
									sidebarVisible={!MED_WIDTH}
									address={address}
									setLocationPopup={
										singleBrandEnabled && restaurantList.length > 1
											? setLocationsModalVisible
											: singleBrandEnabled && !global.kiosk
											? -1
											: null
									}
									rId={rId}
								/>

								<TouchableOpacity
									style={{ alignSelf: 'flex-end' }}
									onPress={() => {
										toggleDropdown()
										setSearchActive(true)
									}}
								></TouchableOpacity>

								{!isSticky && MED_WIDTH ? (
									<>
										{!global.kiosk && (
											<View
												style={{
													backgroundColor: 'white',
													paddingHorizontal: '5%',
													paddingTop: 5,
													paddingBottom: 20,
													display: deliveryEnabled ? 'flex' : 'none',
												}}
											>
												<DeliveryPickupToggle />
											</View>
										)}

										<CategorySidebar
											categories={Object.keys(ctgTokens)}
											style={{ flex: 1 }}
											logo={logoImageURI}
											horizontalScroll={true}
											selectedCategory={visibleCategory}
											ref={null}
											onSelectCategory={category => {
												const index = buildCategoryList.findIndex(item => item.name === category)
												flatListRef.current?.scrollToIndex({
													index,
													animated: true,
													viewOffset: 100,
												})
											}}
										/>
									</>
								) : (
									<View style={{ padding: 15 }}></View>
								)}
								{global.kiosk && !global.pos && (
									<Text
										style={[
											styles.subHeaderText,
											{
												marginRight: '5%',
												marginLeft: MED_WIDTH ? '5%' : 270,
												marginTop: MED_WIDTH ? '5%' : 0,
											},
										]}
									>{`WHAT WOULD YOU LIKE TODAY?`}</Text>
								)}
							</>
						}
						ListHeaderComponentStyle={{ marginBottom: 20, zIndex: 10 }}
						style={{
							marginBottom:
								!singleBrandEnabled && !showDropdown ? Dimensions.get('window').height * 0.1 : 0,
						}}
						ListFooterComponent={
							<>{Platform.OS === 'web' && singleBrandEnabled && <CopyrightFooter />}</>
						}
					/>
				) : (
					<View style={{ alignItems: 'center', justifyContent: 'center' }}>
						<Image
							style={{
								width: 100,
								height: 100,
							}}
							source={require('../../../assets/images/loadImg.gif')}
						/>
					</View>
				)}

				{!MED_WIDTH && (
					<>
						<CategorySidebar
							horizontalScroll={false}
							onReturnToTop={() => {
								flatListRef.current.scrollToOffset({ offset: 0, animated: true })
							}}
							categories={Object.keys(ctgTokens)}
							style={{
								flex: 1,
								bottom:
									singleBrandEnabled && cart.length > 0 && Platform.OS !== 'web'
										? Dimensions.get('window').height * 0.1
										: Platform.OS === 'web'
										? 50
										: 0,
							}}
							logo={logoImageURI}
							selectedCategory={visibleCategory}
							ref={stickySidebarRef}
							onSelectCategory={category => {
								const index = buildCategoryList.findIndex(item => item.name === category)
								flatListRef.current?.scrollToIndex({
									index,
									animated: true,
									viewOffset: responsiveHeight(5),
								})
							}}
						/>
					</>
				)}
			</View>
			{global.kiosk && singleBrandEnabled ? (
				<>
					<TapToLogout navigation={navigation} />
					{global.pos ? (
						<POSNavBar navigation={navigation} screenName={'HomeStack'} />
					) : (
						<NavFooter navigation={navigation} checkoutBar={true} />
					)}
				</>
			) : null}
			{!singleBrandEnabled && !showDropdown && (
				<View style={[{ display: showDropdown ? 'none' : 'flex' }]}>
					<NavFooter
						navigation={navigation}
						isMenu={true}
						isSearchActive={isSearchActive}
						onCancelSearch={() => {
							toggleDropdown()
							onSearchTextChange('')
							setSearchActive(false)
						}}
					/>
				</View>
			)}
		</>
	)
}

export default BrandMenu
