import React, { useContext, useEffect, useState } from 'react'
import {
	Modal,
	View,
	TouchableOpacity,
	StyleSheet,
	ScrollView,
	Dimensions,
	Platform,
} from 'react-native'
import { DataContext } from '../state/context'
import Colors from '../constants/Colors'
import GlobalStyle, {
	formStyle,
	largeTextSize,
	mediumTextSize,
	smallTextSize,
	xlTextSize,
} from '../constants/GlobalStyle'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../state/reducer'
import { getDBHours } from './ScheduleHours/RestaurantHours'
import { parsePrepTime, prepTimeDur } from '../helpers/prepTimeFunctions'
import { alertResponse, alertResponseSingle } from './Alerts/Alerts'
import { useDispatch } from 'react-redux'
import { setItems, setLocationSettings } from '../state/actions'
import { getSquareLocationId } from '../helpers/fbDataRetrieval/getSquareLocationId'
import { Text } from './Themed'

export function LocationSelectModal({
	visible,
	setVisible,
	navigation,
	rIdToken,
	isCheckout = false,
}) {
	const {
		restaurantList,
		setSelectedLocation,
		selectedLocation,
		setSquareLocationId,
		hasInternet,
		setActiveRID,
		allowOrdersOutsideOrgHours,
	} = useContext(DataContext)

	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)
	const schedDuration = useSelector<ReduxStoreState, ReduxStoreState['schedDuration']>(
		state => state.schedDuration
	)

	const isSnoozed = useSelector<ReduxStoreState, ReduxStoreState['isSnoozed']>(
		state => state.isSnoozed
	)

	const cart = useSelector<ReduxStoreState, ReduxStoreState['items']>(state => state.items)
	const prepTimeFormatted =
		schedDuration === '' || schedDuration === undefined
			? prepTimeDur(loadedData && loadedData[rIdToken] ? loadedData[rIdToken].prepTime : 15)
			: schedDuration

	const parsedPrep = parsePrepTime(prepTimeFormatted)

	const dispatch = useDispatch()

	const handleConfirm = () => {
		if (cart.length > 0 && selectedLocation.id !== rIdToken) {
			if (Platform.OS === 'web') {
				setVisible(false)
			}
			alertResponse(
				'Your current order will be cleared. Continue?',
				'',
				'Change Location',
				'Cancel',
				'default',
				'cancel',
				() => {
					dispatch(setItems([])) //clear cart
					while (cart.length > 0) {
						cart.pop()
					}
					setVisible(false)
					navigation.navigate('HomeStack', { screen: 'Menu', params: { rId: selectedLocation.id } })
				},
				handleCancel
			)
		} else {
			setVisible(false)
			if (isCheckout) {
				navigation.navigate('TabCheckout')
			} else {
				navigation.navigate('HomeStack', { screen: 'Menu', params: { rId: selectedLocation.id } })
			}
		}
	}

	const handleCancel = () => {
		setVisible(false)
		setSelectedLocation(loadedData[rIdToken])
		setLocationSettings(loadedData[rIdToken])
	}

	useEffect(() => {
		setSelectedLocation(loadedData[rIdToken])
		setLocationSettings(loadedData[rIdToken])
	}, [])

	useEffect(() => {
		setActiveRID(rIdToken)
	}, [rIdToken])

	useEffect(() => {
		const fetchLocation = async () => {
			if (selectedLocation?.id) {
				const locationId = await getSquareLocationId(selectedLocation.id)
				setSquareLocationId(locationId)
			}
		}
		fetchLocation()
	}, [visible])

	const LocationCard = ({ location }) => {
		let addressSpace = ''
		const address = location.address
		if (address.addressLine2 !== '') {
			addressSpace = ' '
		}
		const formattedAddressLine = `${location.address.addressLine1} ${
			location.address.addressLine2 ? location.address.addressLine2 : ''
		}`
		const formattedCityLine = `${location.address.city}, ${location.address.provinceState} ${location.address.postCode}`
		const distance = `${Math.round(location.distance)} km away`

		const restaurantHours = getDBHours(
			loadedData[global.org].hours ?? null,
			loadedData[location.id].hours,
			loadedData[location.id].timezone,
			parsedPrep,
			schedDuration !== '',
			isSnoozed,
			false,
			allowOrdersOutsideOrgHours
		)

		return (
			<View style={styles.container}>
				<TouchableOpacity
					onPress={() => {
						setSelectedLocation(location)
						setLocationSettings(location)
					}}
					style={styles.row}
					activeOpacity={0.7}
				>
					<MaterialCommunityIcons
						name={
							selectedLocation.id === location.id
								? 'checkbox-marked-circle-outline'
								: 'checkbox-blank-circle-outline'
						}
						size={24}
						color={global.orgColor}
						style={styles.icon}
					/>

					<View style={styles.textContainer}>
						<Text style={styles.boldText}>{formattedAddressLine}</Text>
						<Text style={styles.boldText}>{formattedCityLine}</Text>
						<Text style={styles.normalText}>{location.distance ? distance : ''}</Text>
					</View>
					<Text style={[styles.openUntilText, { color: global.orgColor }]}>
						{restaurantHours.todayHours}
					</Text>
				</TouchableOpacity>
			</View>
		)
	}

	return (
		<Modal
			animationType="fade"
			transparent={true}
			visible={visible}
			onRequestClose={() => {
				setVisible(false)
			}}
		>
			<View style={styles.centeredView}>
				<View style={styles.modalView}>
					<Text style={[formStyle.headerText, { marginVertical: 20, fontSize: largeTextSize }]}>
						SELECT A LOCATION
					</Text>
					<View style={styles.separator} />

					<ScrollView
						style={{ maxHeight: Dimensions.get('window').height * 0.5, maxWidth: '100%' }}
					>
						{restaurantList.map((location, i) => {
							return (
								<View key={'location_' + i}>
									<LocationCard key={i} location={location} />
									{i === restaurantList.length - 1 ? null : <View style={styles.separator} />}
								</View>
							)
						})}
					</ScrollView>
					<View style={styles.separator} />

					<View style={styles.buttonRow}>
						<TouchableOpacity
							style={{ ...styles.button, backgroundColor: Colors.greyscale[5] }}
							onPress={handleCancel}
						>
							<Text style={styles.buttonText}>Cancel</Text>
						</TouchableOpacity>
						<TouchableOpacity
							style={{
								...styles.button,
								backgroundColor: !hasInternet ? Colors.greyscale[6] : global.orgColor,
							}}
							onPress={handleConfirm}
							disabled={!hasInternet}
						>
							<Text style={styles.buttonText}>Confirm</Text>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		</Modal>
	)
}

const styles = StyleSheet.create({
	container: {
		backgroundColor: 'white',
	},
	row: {
		flexDirection: 'row',
		alignItems: 'center',
		padding: 15,
	},
	icon: {
		width: '10%',
	},
	textContainer: {
		width: '50%',
	},
	boldText: {
		fontSize: mediumTextSize,
		fontWeight: 'bold',
		color: 'black',
	},
	normalText: {
		fontSize: mediumTextSize,
		color: 'grey',
		marginTop: 5,
	},
	openUntilText: {
		marginLeft: 5,
		paddingRight: 5,
		alignSelf: 'flex-start',
		fontSize: smallTextSize,
		width: '40%',
		textAlign: 'right',
	},
	separator: {
		height: 1,
		backgroundColor: Colors.greyscale[4],
		width: '100%',
	},

	centeredView: {
		flex: 1,
		justifyContent: 'center',
		paddingTop: 22,
		paddingHorizontal: '5%',
		backgroundColor: 'rgba(0,0,0,0.5)',
		alignItems: 'center',
	},
	modalView: {
		backgroundColor: 'white',
		borderRadius: 20,
		alignItems: 'center',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
		maxWidth: 600,
		width: '100%',
	},
	button: {
		borderRadius: 10,
		padding: 10,
		paddingHorizontal: 25,
	},
	buttonText: {
		fontSize: xlTextSize,
		color: 'white',
		fontWeight: '500',
		textAlign: 'center',
	},
	buttonRow: {
		paddingHorizontal: 20,
		paddingVertical: 15,
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
	},
})
