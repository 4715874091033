import React, { useMemo } from 'react'
import { View, Image, FlatList, Dimensions, TouchableHighlight, Platform } from 'react-native'
import MenuItemCard from './MenuItemCard'
import { Text } from '../../../components/Themed'
import GlobalStyle, { MED_WIDTH, SMALL_WIDTH } from '../../../constants/GlobalStyle'
import { MenuHeader } from './Headers'
import { styles } from '../Helpers/style'
import { responsiveWidth } from 'react-native-responsive-dimensions'
import NavFooter from '../../../components/Footers/NavFooter'
import { calculateOptimalCardWidth } from '../../../helpers/calculateOptimalCardWidth'
import Colors from '../../../constants/Colors'
import { logSelectItemAnalytics } from '../../../firebase/analytics'
import ExitIcon from '../../../components/Headers/UIButtons'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../state/reducer'

/**
 * Menu page for a specific category, can be from multiple brands
 * where rId is category_{categoryName}
 */
const CategoryMenu = ({
	navigation,
	ctgTokens,
	storeOpen,
	isCategory,
	heroImageURI,
	logoImageURI,
	categoryName,
}) => {
	const screenWidth = SMALL_WIDTH
		? Dimensions.get('window').width * 0.93 //-40
		: Dimensions.get('window').width > 1450
		? 1300 * 0.93
		: Dimensions.get('window').width * 0.82

	const card = calculateOptimalCardWidth(screenWidth, !SMALL_WIDTH && MED_WIDTH ? 180 : 200)

	const CARD_WIDTH = card.width

	const cart = useSelector<ReduxStoreState, ReduxStoreState['items']>(state => state.items)

	const addExtraComponents = list => {
		const cardsPerRow = card.cardsPerRow
		if (list.length % cardsPerRow > 1) {
			for (let i = 0; i < cardsPerRow; i++) {
				list.push({ id: `invisible_${i}`, invisible: true })
			}
		}
		return list
	}

	const renderRowItems = (name, rowItems) => (
		<View
			style={{
				flexDirection: 'row',
				flexWrap: 'wrap',
				justifyContent: 'space-between',
				marginBottom: '8%',
			}}
		>
			{rowItems.map((token, index) => (
				<View key={token.id + '_ctgitem_' + index}>
					{token.invisible ? (
						<View style={{ width: CARD_WIDTH }}>
							<View style={{ width: '100%', height: 0 }} />
						</View>
					) : (
						<TouchableHighlight
							key={token.id + index}
							underlayColor={Colors.custom.appBackground}
							onPress={() => {
								if (global.kiosk && !storeOpen) {
									console.log('Closed')
								} else {
									logSelectItemAnalytics('category_' + categoryName, categoryName, token.price, {
										item_id: token.id,
										item_name: token.name,
										item_category: name,
									})

									navigation.navigate('Item', {
										token: token.id,
										editing: null,
										isOpen: storeOpen,
										menuItemCategory: name,
										menuId: 'category_' + categoryName,
										category: true,
									})
								}
							}}
						>
							<View style={{ width: CARD_WIDTH, marginBottom: '15%' }}>
								<MenuItemCard
									cart={cart}
									storeOpen={storeOpen}
									key={token.id + index}
									id={token.id}
									name={token.name}
									desc={token.desc}
									price={token.price}
									imageUri={token.image}
									promoPrice={token.promoPrice}
									width={CARD_WIDTH}
									variationMinPrice={token.variationMinPrice}
								/>
							</View>
						</TouchableHighlight>
					)}
				</View>
			))}
		</View>
	)

	const buildCategoryList = useMemo(() => {
		const categoryList = []
		Object.entries(ctgTokens).forEach(([name, tokens]) => {
			categoryList.push({
				name: name,
				items: addExtraComponents(tokens),
			})
		})
		return categoryList
	}, [ctgTokens])

	return (
		<>
			<View
				style={{
					flexDirection: 'row',
					flex: 1,
				}}
			>
				<ExitIcon
					onPress={() => {
						navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
					}}
				/>

				<View style={{ flex: 2 }}>
					{ctgTokens && Object.entries(ctgTokens).length !== 0 ? (
						<FlatList
							data={buildCategoryList}
							initialNumToRender={3}
							windowSize={41}
							renderItem={({ item }) => (
								<View key={item.name + item.items[0].id} style={{ marginHorizontal: '5%' }}>
									{item.name && (
										<Text
											style={[GlobalStyle.titleText, { color: global.orgColor2, paddingTop: 20 }]}
										>
											{item.name.toUpperCase()}
										</Text>
									)}
									{isCategory && global.kiosk && !global.pos && (
										<Text style={styles.subHeaderText}>
											{`WHAT ${item.name.toUpperCase()} WOULD YOU LIKE TODAY?`}
										</Text>
									)}
									{renderRowItems(item.name, item.items)}
								</View>
							)}
							keyExtractor={(item, index) => item.items[0].id + '_' + index}
							ListHeaderComponent={() => (
								<MenuHeader
									HeroImageURI={heroImageURI}
									LogoImageURI={isCategory ? null : logoImageURI}
									navigation={navigation}
									fill={Colors.custom.appBackground}
								/>
							)}
							ListHeaderComponentStyle={{
								marginBottom:
									Platform.OS === 'web'
										? -Dimensions.get('window').height * 0.05
										: -responsiveWidth(10),
							}}
							style={{
								marginBottom:
									Platform.OS === 'web'
										? Dimensions.get('window').height * 0.1
										: responsiveWidth(15),
							}}
						/>
					) : (
						<View style={{ alignItems: 'center', justifyContent: 'center' }}>
							<Image
								style={{
									width: 100,
									height: 100,
								}}
								source={require('../../../assets/images/loadImg.gif')}
							/>
						</View>
					)}
				</View>
			</View>
			<NavFooter navigation={navigation} isMenu={true} />
		</>
	)
}

export default CategoryMenu
