import React, { useState, useContext, useEffect, useRef } from 'react'
import { TouchableOpacity, Platform, Dimensions, FlatList, Pressable } from 'react-native'
import Button from '../Button'
import { Text, View } from '../Themed'
import { useDispatch, useSelector } from 'react-redux'
import GlobalStyle from '../../constants/GlobalStyle'
import RBSheet from 'react-native-raw-bottom-sheet'
import ScrollPicker from './ScrollViewPicker'
import {
	setPickupMessage,
	setPrepTimeString,
	setSchedCurrTime,
	setSchedDuration,
} from '../../state/actions'
import { format, utcToZonedTime } from 'date-fns-tz'
import { add, getMinutes, set, intervalToDuration, sub } from 'date-fns'
import Colors from '../../constants/Colors'
import { getOrgHours } from '../../screens/RestaurantSelect/Helpers/orgPrepHours'
import { convertHours, prepTimeDur } from '../../helpers/prepTimeFunctions'
import { DataContext } from '../../state/context'

export function combineHours(restaurants) {
	const combinedHours = getOrgHours(restaurants)

	const rHours = {}

	const indexedHours = {
		0: [],
		1: [],
		2: [],
		3: [],
		4: [],
		5: [],
		6: [],
	}

	const combinedStarts = JSON.parse(JSON.stringify(indexedHours))
	const combinedEnds = JSON.parse(JSON.stringify(indexedHours))

	for (let i = 0; i < combinedHours.length; i++) {
		const sched = Object.entries(combinedHours[i])
		sched.forEach((day, i) => {
			day[1].startTimes?.forEach(start => {
				combinedStarts[i].push(start)
			})
			day[1].endTimes?.forEach(end => {
				combinedEnds[i].push(end)
			})

			combinedStarts[i].sort(function (a, b) {
				return a - b
			})
			combinedEnds[i].sort(function (a, b) {
				return a - b
			})
		})
	}

	// console.log('combinedEnds', JSON.parse(JSON.stringify(combinedEnds)))
	// console.log('combinedStarts', JSON.parse(JSON.stringify(combinedStarts)))

	const intersectedStarts = JSON.parse(JSON.stringify(indexedHours))
	const intersectedEnds = JSON.parse(JSON.stringify(indexedHours))

	for (let i = 0; i < 7; i++) {
		let currentStart = combinedStarts[i][0]
		let currentEnd = combinedEnds[i][0]
		for (let j = 1; j < combinedEnds[i].length; j++) {
			// represents intersected time between current end and indexed start time
			if (currentEnd >= combinedStarts[i][j]) {
				// Update the current intersection, looking for max for a start time, looking for min for an end time
				currentStart = Math.max(currentStart, combinedStarts[i][j])
				currentEnd = Math.min(currentEnd, combinedEnds[i][j])
			} else {
				// Not an intersection, assign new start and end to be indexed start and end
				intersectedStarts[i].push(currentStart)
				intersectedEnds[i].push(currentEnd)
				currentStart = combinedStarts[i][j]
				currentEnd = combinedEnds[i][j]
			}
		}
		// Push last intersection in case that intersection is last index of array
		intersectedStarts[i].push(currentStart)
		intersectedEnds[i].push(currentEnd)

		rHours[i] = {
			startTimes: intersectedStarts[i],
			endTimes: intersectedEnds[i],
		}
	}
	return rHours
}

export function scheduleOrder(
	ref,
	loading,
	bPrep,
	bHours,
	bLocalTZ,
	bJump,
	filteredRestaurants,
	orgHours,
	allowOutsideOrgHours
) {
	const [timeSelected, setTimeSelected] = useState(0)
	const [dateSelected, setDateSelected] = useState(0)
	const [selectedTime, setSelectedTime] = useState('')
	const [reset, setReset] = useState([])

	const { setResetTime, isDelivery } = useContext(DataContext)

	const dispatch = useDispatch()

	let prepTime = useSelector(state => state.prepTime)

	let localTimezone = useSelector(state => state.localTimezone)

	let schedulingJump = useSelector(state => state.schedulingJump)

	prepTime = prepTime === -1 ? bPrep : prepTime

	if (orgHours && !allowOutsideOrgHours) {
		// if orgHours exists
		const convertedOrgHours = convertHours(orgHours, prepTime) // convert orgHours to index format
		filteredRestaurants.push({ hours: convertedOrgHours }) // combine org hours with rest of restaurants
	}

	//gets list of hours for scheduling
	const rHours = combineHours(filteredRestaurants)
	localTimezone = localTimezone === '' ? bLocalTZ : localTimezone
	schedulingJump = schedulingJump === 0 ? bJump : schedulingJump
	const hours = rHours

	function getCurrTimeForSched() {
		var current = utcToZonedTime(new Date(), localTimezone)

		var currPlusPrepTime = add(current, {
			minutes: prepTime,
		})

		var minutes = getMinutes(currPlusPrepTime)

		//rounds starting time to closest 15 minutes
		var addToPrepTime = Math.ceil(minutes / 15) * 15 - minutes

		//time to show as first prep time (if store open)
		var startingTime = add(currPlusPrepTime, {
			minutes: addToPrepTime,
		})
		return startingTime
	}

	function getTimeList(hours) {
		var list = []
		var currTime = getCurrTimeForSched()

		//make loop for how ever many days in advance to schedule. i.e. 14 days
		for (var i = 0; i < 14; i++) {
			var dateForSched = add(new Date(), { days: i })

			var daySched = hours[dateForSched.getDay()]
			var d = format(dateForSched, 'EEE MMM d')

			var dCurr = format(new Date(), 'EEE MMM d')

			for (var x = 0; x < daySched.startTimes.length; x++) {
				var startTime = daySched.startTimes[x]
				var startMins = Math.round((startTime % 1) * 60)
				var startHrs = Math.floor(startTime)

				var start = set(dateForSched, {
					hours: startHrs,
					minutes: startMins,
				})

				//if current day, account for time passed already
				if (d === dCurr && start < currTime) {
					start = currTime
				}

				var endTime = daySched.endTimes[x]
				var endMins = Math.round((endTime % 1) * 60)
				var endHours = Math.floor(endTime)

				var end = set(dateForSched, {
					hours: endHours,
					minutes: endMins,
				})

				end = sub(end, {
					minutes: Number(prepTime) + schedulingJump,
				})

				var time = start
				var times = []
				while (time < end) {
					time = add(time, {
						minutes: schedulingJump,
					})

					times.push(time)
				}
				if (times.length > 0) {
					if (Platform.OS !== 'web') {
						times.push(' ')
					}

					list.push({
						date: d,
						times: times,
					})
				}
			}
		}
		if (Platform.OS !== 'web') {
			list.push({
				date: ' ',
				times: [],
			})
		}

		return list
	}

	function scheduleScrollRender(hours) {
		var timeList = getTimeList(hours)
		if (Platform.OS !== 'web' && timeList[dateSelected] !== undefined) {
			// if (selectedTime === '' && timeList[0].times[0] !== undefined) {
			// 	setSelectedTime(timeList[0].times[0])
			// }
			return (
				<>
					<ScrollPicker
						style={{ flex: 1, paddingRight: 100 }}
						dataSource={timeList}
						selectedIndex={0}
						itemHeight={50}
						wrapperHeight={170}
						wrapperColor={'#ffffff'}
						highlightColor={'#d8d8d8'}
						renderItem={data => {
							return (
								<View>
									<Text>{data.date}</Text>
								</View>
							)
						}}
						onValueChange={(data, selectedIndex) => {
							setSelectedTime(data.times[0])
							setDateSelected(selectedIndex)
						}}
					/>
					<ScrollPicker
						style={{ flex: 1 }}
						dataSource={timeList[dateSelected].times}
						selectedIndex={0}
						itemHeight={50}
						wrapperHeight={170}
						wrapperColor={'#ffffff'}
						highlightColor={'#d8d8d8'}
						renderItem={data => {
							if (data !== ' ') {
								return (
									<View>
										<Text>{format(data, 'h:mm aaa')}</Text>
									</View>
								)
							}
						}}
						onValueChange={(data, index) => {
							setSelectedTime(data)
						}}
					/>
				</>
			)
		} else if (timeList[dateSelected] !== undefined) {
			//WEB SCHEDULE PICKER
			// if (selectedTime === '' && timeList[0].times[0] !== undefined) {
			// 	setSelectedTime(timeList[0].times[0])
			// }
			var width = Dimensions.get('window').width

			return (
				<>
					<FlatList
						scrollEnabled={true}
						showsVerticalScrollIndicator={true}
						data={timeList}
						renderItem={({ item, index }) => (
							<TouchableOpacity
								style={{
									paddingHorizontal: 15,
								}}
								onPress={() => {
									setSelectedTime(item.times[0])
									setDateSelected(index)
								}}
							>
								<View
									style={{
										backgroundColor: dateSelected === index ? Colors.custom.lightGrey : null,
										borderRadius: 15,
										height: 50,
										width: 175,
									}}
								>
									<Text
										style={{
											textAlign: 'center',
											alignItems: 'center',
											marginTop: 15,
										}}
									>
										{item.date}
									</Text>
								</View>
							</TouchableOpacity>
						)}
						keyExtractor={(item, index) => index.toString()}
						contentContainerStyle={{
							flexGrow: 1,
							height: 250,
							marginTop: 20,
						}}
					/>
					<View style={{ width: width < 460 ? 15 : 50 }}></View>
					<FlatList
						scrollEnabled={true}
						showsVerticalScrollIndicator={true}
						data={timeList[dateSelected].times}
						renderItem={({ item, index }) => (
							<TouchableOpacity
								style={{
									paddingHorizontal: 15,
								}}
								onPress={() => {
									setSelectedTime(item)
									setTimeSelected(index)
								}}
							>
								<View
									style={{
										backgroundColor: timeSelected === index ? Colors.custom.lightGrey : null,
										borderRadius: 15,
										height: 50,
										width: width < 460 ? width / 3 : 175,
									}}
								>
									<Text
										style={{
											textAlign: 'center',
											alignItems: 'center',
											marginTop: 15,
										}}
									>
										{format(item, 'h:mm aaa')}
									</Text>
								</View>
							</TouchableOpacity>
						)}
						keyExtractor={(item, index) => index.toString()}
						contentContainerStyle={{
							flexGrow: 1,
							height: 250,
							marginTop: 20,
						}}
					/>
				</>
			)
		}
	}

	function setSched(time = null) {
		var result = intervalToDuration({
			start: utcToZonedTime(new Date(), localTimezone),
			end: new Date(selectedTime === '' ? time : selectedTime),
		})
		dispatch(setSchedCurrTime(utcToZonedTime(new Date(), localTimezone).toString()))
		dispatch(
			setSchedDuration(
				'P' + result.days + 'DT' + result.hours + 'H' + result.minutes + 'M' + result.seconds + 'S'
			)
		)
	}
	if (hours[0] !== undefined && loading === false) {
		return (
			<View>
				<RBSheet
					ref={ref}
					closeOnDragDown={false}
					closeOnPressMask={false}
					height={Platform.OS === 'web' ? 400 : 300}
					customStyles={{
						container: {
							borderTopLeftRadius: 12,
							borderTopRightRadius: 12,
							maxWidth: 830,
							alignSelf: 'center',
						},
					}}
				>
					<>
						<View
							style={[
								GlobalStyle.pickupBtn,
								{
									flexDirection: 'row',
									justifyContent: 'space-between',
									alignItems: 'center',
									elevation: 0,
									zIndex: 10,
									height: 65,
									marginTop: 0,
									borderRadius: 0,
								},
							]}
						>
							<Text style={[GlobalStyle.headerText, { textAlign: 'center', flex: 1 }]}>
								{isDelivery ? 'Schedule your delivery time' : 'Schedule your pickup time'}
							</Text>
							<Pressable
								onPress={() => [
									dispatch(setSchedCurrTime('')),
									dispatch(setSchedDuration('')),
									dispatch(setPrepTimeString(prepTimeDur(prepTime))),
									setDateSelected(0),
									dispatch(setPickupMessage('')),
									setTimeSelected(0),
									setSelectedTime(''),
									setReset([]),
									setResetTime([]),
									ref.current.close(),
								]}
								style={{
									paddingLeft: 25,
									paddingTop: 25,
									paddingBottom: 20,
									paddingRight: 15,
									position: 'absolute',
									right: 0,
									top: 0,
								}}
							>
								<Text style={{ color: '#007AFF', fontSize: 16 }}>Reset</Text>
							</Pressable>
						</View>

						<View
							style={{
								flex: 1,
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<View style={{ flexDirection: 'row' }}>{scheduleScrollRender(hours)}</View>
						</View>

						<Button
							onPress={() => {
								const timeList = getTimeList(hours)
								let timeSel = ''
								if (selectedTime === '' && timeList[0].times[0] !== undefined) {
									setSelectedTime(timeList[0].times[0])
									timeSel = timeList[0].times[0]
								}

								setDateSelected(0)
								setSched(timeSel)
								dispatch(setPickupMessage(selectedTime === '' ? timeSel : selectedTime.toString()))
								setTimeSelected(0)
								setSelectedTime('')
								setReset([])
								ref.current.close()
							}}
							title="Done"
							buttonStyle={[
								GlobalStyle.pickupBtn,
								{
									marginTop: 10,
									elevation: 0,
									height: 75,
									borderRadius: 0,
								},
							]}
							textStyle={[
								GlobalStyle.headerText,
								{ paddingBottom: Platform.OS === 'ios' ? 50 : 10 },
							]}
							isLoading={false}
							icon={undefined}
						/>
					</>
				</RBSheet>
			</View>
		)
	} else {
		return <></>
	}
}
