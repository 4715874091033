import React, { useState, useRef } from 'react'
import { TouchableOpacity, StyleSheet } from 'react-native'
import { alertResponse } from '../Alerts/Alerts'
import { auth } from '../../firebase/config'
import { useDispatch } from 'react-redux'
import { setPaymentMethodId, setStripeId, setUserId } from '../../state/actions'
import RNRestart from 'react-native-restart'

// Allow user to logout of kiosk/pos account by tapping top-right corner 5 times in quick succession
const TapToLogout = ({ navigation }) => {
	const [tapCount, setTapCount] = useState(0)
	const timeoutRef = useRef<any>()

	const dispatch = useDispatch()

	const handleTap = () => {
		// Increment the tap count
		setTapCount(prevCount => prevCount + 1)

		// If 5 taps detected in quick succession
		if (tapCount + 1 === 5) {
			alertResponse(
				'Logout?',
				global.pos ? 'Logout of your POS account?' : 'Logout of your kiosk account?',
				'Logout',
				'Cancel',
				'default',
				'cancel',
				() => {
					auth.signOut()
					dispatch(setUserId(''))
					dispatch(setStripeId(''))
					dispatch(setPaymentMethodId(''))
					RNRestart.Restart()
				},
				null
			)
			setTapCount(0) // Reset tap count after showing the alert
			clearTimeout(timeoutRef.current) // Clear the timeout
		} else {
			// If user doesn't tap 5 times within a span of 2 seconds, reset tap count
			clearTimeout(timeoutRef.current)
			timeoutRef.current = setTimeout(() => {
				setTapCount(0)
			}, 1000) // You can adjust this delay as needed
		}
	}

	return <TouchableOpacity style={styles.hiddenArea} onPress={handleTap} activeOpacity={1} />
}

const styles = StyleSheet.create({
	hiddenArea: {
		zIndex: 99999,
		position: 'absolute',
		top: 0,
		right: 0,
		width: 150,
		height: 120,
		backgroundColor: 'transparent',
	},
})

export default TapToLogout
