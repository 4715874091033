import * as React from 'react'
import {
	TouchableOpacity,
	FlatList,
	RefreshControl,
	Image,
	Platform,
	Dimensions,
	SectionList,
} from 'react-native'
import Colors from '../../constants/Colors'
import { Text, View } from '../../components/Themed'
import { RootStackParamList, RootTabScreenProps } from '../../navigation/types'
import { useCallback, useContext, useEffect, useState } from 'react'
import { auth, db } from '../../firebase/config'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { Spacer } from '../../components/Spacer'
import Collapsible from 'react-native-collapsible'
import GlobalStyle, { largeTextSize, titleTextSize } from '../../constants/GlobalStyle'
import { OrderData } from './types'
import { RouteProp, useRoute } from '@react-navigation/native'
import { ReduxStoreState } from '../../state/reducer'
import { useSelector } from 'react-redux'
import { setPickupTime } from '../../helpers/prepTimeFunctions'
import { formatInTimeZone } from 'date-fns-tz'
import { DisplayOrder } from '../../components/OrderCart/DisplayOrder'
import { TriangleHeader } from '../../components/Headers/TriangleHeader'
import { DataContext } from '../../state/context'
import EpsonPrintReceipt from '../../components/Kiosk/Receipts/EpsonPrintReceipt.native'
import StarPrintReceipt from '../../components/Kiosk/Receipts/StarPrintReceipt.native'
import PrinterStatus from '../../components/Kiosk/Receipts/PrinterStatus.native'
import CopyrightFooter from '../../components/Footers/CopyrightFooter'
import POSNavBar from '../../navigation/POSNavBar'
import { convertDoordashStatus } from '../../components/Delivery/OrderBanner'
import {
	collection,
	doc,
	getDocs,
	limit,
	onSnapshot,
	orderBy,
	query,
	startAfter,
	where,
} from 'firebase/firestore'
import { DATABASE_NAME } from '@env'
import DoorDashTracking from '../../components/Delivery/DoorDashTracking'
import { ScrollView } from 'react-native'

export default function Orders({ navigation }) {
	const prepTimeDefault = useSelector<ReduxStoreState, ReduxStoreState['prepTime']>(
		state => state.prepTime
	)

	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)

	const organizationLogo = useSelector<ReduxStoreState, ReduxStoreState['organizationLogo']>(
		state => state.organizationLogo
	)

	const localTimezone = useSelector<ReduxStoreState, ReduxStoreState['localTimezone']>(
		state => state.localTimezone
	)

	const { receiptPrinter, printerType, hasInternet } = useContext(DataContext)

	const [pages, setPages] = useState(1)
	const [reset, setReset] = useState([])
	const [ordersView, setOrdersView] = useState([])
	const [loading, setLoading] = useState(true)

	const functions = getFunctions()

	const ACTIVE_WINDOW_MS = 3600000 // 1 hour

	let page = 1
	if (useRoute<RouteProp<RootStackParamList, 'TabOrders'>>().params != undefined) {
		page = useRoute<RouteProp<RootStackParamList, 'TabOrders'>>().params.page
	}

	const unsubscribeRef = React.useRef(null) // Ref to hold the unsubscribe function

	useEffect(() => {
		const unsubscribeNavigation = navigation.addListener('focus', () => {
			setLoading(true)
			//setCollapsibles({})

			if (auth.currentUser && hasInternet) {
				// Clear existing orders and fetch them again when focused
				setOrdersView([])
				setPages(1)
				subscribeToOrders()
			} else {
				setOrdersView([])
				setReset([])
				setLoading(false)
			}
		})

		return () => {
			unsubscribeNavigation()
			if (unsubscribeRef.current) {
				unsubscribeRef.current()
			}
		}
	}, [navigation])

	const subscribeToOrders = () => {
		if (auth.currentUser !== null) {
			const orderRef = collection(doc(db, DATABASE_NAME, global.org), 'Orders')
			const userId = auth.currentUser.uid

			const orderQuery = query(
				orderRef,
				where('userId', '==', userId),
				orderBy('orderDate', 'desc')
			)

			// Set up the Firestore onSnapshot listener
			unsubscribeRef.current = onSnapshot(
				orderQuery,
				snapshot => {
					if (snapshot.empty) {
						setOrdersView([]) // No orders found
						setLoading(false)
						return
					}

					const newOrders = []
					let pos = 0

					snapshot.forEach(doc => {
						const data = doc.data()

						if (data.status !== 'Pending' && data.status !== 'Failed') {
							const items = data.orderItems.map(orderItem => {
								const mods = orderItem.modifiers.map(itemMod => ({
									name: itemMod?.name,
									price: itemMod.priceMoney.amount / 100,
								}))

								return {
									name: orderItem?.name,
									qty: orderItem.quantity,
									mods: mods,
									note: orderItem.note,
									total: orderItem.priceMoney.amount,
									id: orderItem.id,
									rId: orderItem.restaurantId,
									noPromo: orderItem.priceMoney.amount,
								}
							})

							const orderObj = {
								pos: pos,
								orderDate: data.orderDate,
								pickupDate: data.pickupDate,
								orderID: data.id,
								orderNote: data.orderNote,
								orderItems: items,
								prepTime: data.prepTime,
								restaurants: items.map(item => item.rId),
								prices: data.priceData,
								pickupStatus: data.status,
								orderNumber: data.orderNumber,
								rawObject: data,
								deliveryStatus: data.channelData?.doordash?.deliveryStatus || '',
								deliveryTrackingURL: data.channelData?.doordash?.trackingUrl || '',
								scheduled: data.scheduled ?? false,
							}

							newOrders.push(orderObj)
							pos++
						}
					})

					setOrdersView(newOrders)
					setLoading(false)
				},
				error => {
					console.error('Error listening to orders: ', error)
					setLoading(false)
				}
			)
		}
	}

	function orderQuantity(singleOrder) {
		var count = 0
		singleOrder.orderItems.forEach(item => {
			count += parseInt(item.qty)
		})
		return count
	}

	function itemOrItems(len) {
		if (len === 1) {
			return 'item'
		} else {
			return 'items'
		}
	}

	function PrinterView({ singleOrder }) {
		if (global.pos) {
			if (printerType === 'star') {
				return <StarPrintReceipt item={singleOrder.rawObject} printer={receiptPrinter} />
			} else {
				return <EpsonPrintReceipt item={singleOrder.rawObject} printer={receiptPrinter} />
			}
		}
		return null
	}

	function SetView({ singleOrder, sectionKey }) {
		//expand details for first order if active
		const deliveryStatus = singleOrder.deliveryStatus
			? convertDoordashStatus(singleOrder.deliveryStatus)
			: ''

		const [isCollapsed, setIsCollapsed] = useState(
			singleOrder.pos === 0 &&
				singleOrder.pickupStatus !== 'Completed' &&
				deliveryStatus !== 'Delivered' &&
				deliveryStatus !== 'Cancelled' &&
				singleOrder.pickupStatus !== 'Cancelled' &&
				sectionKey === 'Active Orders'
				? false
				: true
		)

		const handlePress = useCallback(() => {
			setIsCollapsed(prevState => !prevState)
		}, [])

		const readyTime = setPickupTime(
			singleOrder.prepTime,
			new Date(singleOrder.orderDate.seconds * 1000),
			prepTimeDefault,
			localTimezone
		)
		const rList = [...new Set(singleOrder.restaurants)]
		let rReturn = ''
		const quantity = orderQuantity(singleOrder)

		rList.forEach((rId: string, i) => {
			if (i == rList.length - 1 && i !== 0) {
				rReturn = rReturn.slice(0, rReturn.length - 2)
				rReturn += ' & ' + loadedData[rId]?.name
			} else if (i == rList.length - 1) {
				rReturn += loadedData[rId]?.name
			} else {
				rReturn += loadedData[rId]?.name + ', '
			}
		})

		return (
			<View key={singleOrder.orderID + '_view'}>
				<TouchableOpacity onPress={handlePress}>
					<View
						style={[
							GlobalStyle.collapsibleHeading,
							{ padding: 20, paddingBottom: 0, flexDirection: 'row' },
						]}
					>
						<View style={GlobalStyle.ordersHeading}>
							<Text style={GlobalStyle.responsiveTextSize}>{rReturn}</Text>
						</View>

						<View style={[GlobalStyle.ordersHeading, { flex: 1.25, alignItems: 'flex-end' }]}>
							<Text style={[GlobalStyle.responsiveTextSize, { textAlign: 'center' }]}>
								<Text style={{ fontWeight: '300' }}>
									{formatInTimeZone(singleOrder.orderDate.seconds * 1000, localTimezone, 'MMM d')}
								</Text>
							</Text>
						</View>
					</View>

					<View
						style={[
							GlobalStyle.collapsibleHeading,
							{ padding: 20, paddingTop: 7, flexDirection: 'row' },
						]}
					>
						<View style={[GlobalStyle.ordersHeading, { flex: 0.75, alignItems: 'flex-start' }]}>
							<Text style={[GlobalStyle.responsiveTextSize, { fontWeight: '300' }]}>
								{quantity} {itemOrItems(quantity)}
								{' ·'} ${(singleOrder.prices.total / 100).toFixed(2)}
							</Text>
						</View>

						<View style={[GlobalStyle.ordersHeading, { alignItems: 'flex-end' }]}>
							<Text style={[GlobalStyle.responsiveTextSize, { fontWeight: '300' }]}>
								{singleOrder.deliveryStatus !== ''
									? convertDoordashStatus(singleOrder.deliveryStatus)
									: singleOrder.pickupStatus}
							</Text>
						</View>
					</View>
				</TouchableOpacity>
				<PrinterView singleOrder={singleOrder} />
				<Collapsible collapsed={isCollapsed}>
					<View
						style={{
							borderBottomWidth: 2,
							borderLeftWidth: 2,
							borderRightWidth: 2,
							borderColor: Colors.custom.lightGrey,
							padding: 15,
						}}
					>
						<View
							style={{
								flexDirection: 'row',
								paddingHorizontal: 15,
								display: singleOrder.deliveryTrackingURL ? 'none' : 'flex',
							}}
						>
							<View style={[{ flex: 1.25 }]}>
								<Text style={[GlobalStyle.responsiveTextSize]}>{'Placed on  '}</Text>
								<Text style={[GlobalStyle.responsiveTextSize, { fontWeight: '300' }]}>
									{formatInTimeZone(
										singleOrder.orderDate.seconds * 1000,
										localTimezone,
										"EEE MMM d 'at' h:mm a"
									)}
								</Text>
							</View>

							<View style={[{ flex: 1.25, alignItems: 'flex-end' }]}>
								<Text style={[GlobalStyle.responsiveTextSize]}>{'Pickup on'}</Text>
								<Text style={[GlobalStyle.responsiveTextSize, { fontWeight: '300' }]}>
									{readyTime}
								</Text>
							</View>
						</View>
						<View
							style={{
								paddingTop: 7,
								paddingBottom: 15,
								paddingHorizontal: 15,
								display: singleOrder.deliveryTrackingURL ? 'none' : 'flex',
							}}
						>
							<Text style={[GlobalStyle.responsiveTextSize]}>
								{'Order no. '}
								{singleOrder.orderNumber}
							</Text>
						</View>
						{singleOrder.deliveryTrackingURL !== null &&
							singleOrder.deliveryTrackingURL !== '' &&
							!isCollapsed && (
								<>
									<DoorDashTracking url={singleOrder?.deliveryTrackingURL} />
								</>
							)}
						<DisplayOrder
							items={singleOrder.orderItems}
							prices={singleOrder.prices}
							isCheckout={false}
							isConfirmed={false}
							navigation={navigation}
							deletePopup={null}
							loadedData={loadedData}
							trackingURL={singleOrder.deliveryTrackingURL}
						/>
					</View>
				</Collapsible>
				<Spacer size={15} />
			</View>
		)
	}

	const noOrderText = global.pos
		? 'Past orders from this POS will appear here once an order is placed.'
		: 'Your past orders will appear here once you place your first order.'

	const [expandedSections, setExpandedSections] = useState({
		'Active Orders': true,
		'Past Orders': true,
		'Scheduled Orders': true,
	})

	const toggleSection = section => {
		setExpandedSections(prev => ({
			...prev,
			[section]: !prev[section],
		}))
	}

	const today = new Date()
	const todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime()
	const todayEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1).getTime()

	// Scheduled orders
	const scheduledOrders = ordersView.filter(singleOrder => {
		const orderPickupTime = singleOrder.pickupDate?.seconds * 1000
		return (
			singleOrder.scheduled === true &&
			(orderPickupTime < todayStart || orderPickupTime >= todayEnd)
		)
	})

	// Active Orders
	const activeOrders = ordersView.filter(singleOrder => {
		const orderPickupTime = singleOrder.pickupDate?.seconds * 1000
		const now = new Date().getTime()

		return (
			// Orders that still within the active window
			(singleOrder.pickupStatus !== 'Completed' &&
				convertDoordashStatus(singleOrder.deliveryStatus) !== 'Delivered' &&
				convertDoordashStatus(singleOrder.deliveryStatus) !== 'Cancelled' &&
				singleOrder.pickupStatus !== 'Cancelled' &&
				orderPickupTime + ACTIVE_WINDOW_MS > now &&
				!singleOrder.scheduled) ||
			// Scheduled orders that are for today
			(singleOrder.scheduled === true &&
				orderPickupTime >= todayStart &&
				orderPickupTime < todayEnd)
		)
	})

	// Past Orders
	const pastOrders = ordersView.filter(
		order => !activeOrders.includes(order) && !scheduledOrders.includes(order)
	)

	const sections = [
		{ data: activeOrders, sectionKey: 'Active Orders' },
		{ data: scheduledOrders, sectionKey: 'Scheduled Orders' },
		{ data: pastOrders, sectionKey: 'Past Orders' },
	]

	const renderSectionHeader = ({ section: { sectionKey } }) => {
		const section = sections.find(section => section.sectionKey === sectionKey)
		if (section.data.length === 0) {
			return null
		}
		return (
			<TouchableOpacity
				style={{
					flexDirection: 'row',
					justifyContent: 'space-between',
					padding: '2%',
					backgroundColor: 'white',
				}}
				onPress={() => toggleSection(sectionKey)}
			>
				<Text
					style={[
						GlobalStyle.titleText,
						{ color: global.orgColor2, fontSize: largeTextSize, paddingBottom: 0 },
					]}
				>
					{sectionKey}
				</Text>
				<Text style={{ fontSize: largeTextSize, color: global.orgColor2 }}>
					{expandedSections[sectionKey] ? '▲' : '▼'}
				</Text>
			</TouchableOpacity>
		)
	}

	const MemoizedSetView = React.memo(SetView)

	const renderItem = ({ item, section: { sectionKey } }) => {
		return expandedSections[sectionKey] ? (
			<View style={{ paddingHorizontal: '2%' }}>
				<MemoizedSetView singleOrder={item} sectionKey={sectionKey} />
			</View>
		) : null
	}

	function LoggedInView() {
		if (auth.currentUser) {
			if (ordersView.length === 0 && !loading) {
				return (
					<View
						key={'no_orders'}
						style={[
							GlobalStyle.container,
							{
								alignItems: 'center',
								paddingTop: 30,
								backgroundColor: Colors.custom.appBackground,
							},
						]}
					>
						<Text style={GlobalStyle.medBoldText}>{noOrderText}</Text>
					</View>
				)
			} else if (loading) {
				return (
					<View
						key={'loading'}
						style={[
							GlobalStyle.container,
							{
								alignItems: 'center',
								paddingTop: 30,
								backgroundColor: Colors.custom.appBackground,
							},
						]}
					>
						<Image
							style={{
								width: 100,
								height: 100,
								alignSelf: 'center',
								marginTop: -20,
							}}
							source={require('../../assets/images/loadImg.gif')}
						/>
					</View>
				)
			} else {
				return (
					<View
						style={{
							maxWidth: 1250,
							width: '100%',
							alignSelf: 'center',
							backgroundColor: Colors.custom.white,
						}}
					>
						<View
							style={{
								marginTop: Platform.OS === 'web' ? 0 : -40,
								paddingHorizontal: 0,
								paddingTop: 0,
								paddingBottom: 0,
							}}
						>
							{global.pos ? (
								<PrinterStatus receiptPrinter={receiptPrinter} printerType={printerType} />
							) : (
								Platform.OS === 'web' && (
									<Text
										style={[
											{
												color: global.orgColor2,
												fontSize: titleTextSize,
												paddingVertical: '2%',
												fontWeight: '700',
												paddingHorizontal: '2%',
											},
										]}
									>
										{'ORDERS'}
									</Text>
								)
							)}

							<SectionList
								sections={sections}
								keyExtractor={(item, index) => item.orderID + '_' + (index + 1).toString()}
								renderSectionHeader={({ section }) => renderSectionHeader({ section })}
								renderItem={renderItem}
								initialNumToRender={10}
								maxToRenderPerBatch={10}
								viewabilityConfig={{ itemVisiblePercentThreshold: 50 }}
							/>
						</View>
					</View>
				)
			}
		} else {
			return (
				<View
					key={'signed_out'}
					style={[
						GlobalStyle.container,
						{
							justifyContent: 'center',
							alignItems: 'center',
							backgroundColor: Colors.custom.appBackground,
						},
					]}
				>
					<Text style={[GlobalStyle.medBoldText, { marginTop: -150 }]}>
						Please sign in to view orders
					</Text>
				</View>
			)
		}
	}

	return (
		<>
			<TriangleHeader
				logo={organizationLogo}
				background={
					ordersView.length > 0 && !loading && auth.currentUser
						? 'white'
						: Colors.custom.appBackground
				}
				navigation={navigation}
				title={'ORDERS'}
				backFunction={
					Platform.OS === 'web'
						? () => {
								if (navigation.canGoBack()) {
									navigation.goBack()
								} else {
									navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
								}
						  }
						: null
				}
			/>
			<View style={{ backgroundColor: Colors.custom.white, flexGrow: 1 }}>
				<LoggedInView />
			</View>
			{global.pos ? <POSNavBar navigation={navigation} screenName={'Orders'} /> : null}
			{Platform.OS === 'web' && <CopyrightFooter />}
		</>
	)
}
