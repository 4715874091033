import React, { useContext, useState } from 'react'
import { View, TouchableOpacity, StyleSheet } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import Colors from '../../../constants/Colors'
import { largeTextSize, smallTextSize } from '../../../constants/GlobalStyle'
import { countQtySelected, countSelected } from '../Helpers/functions'
import { responsiveWidth } from 'react-native-responsive-dimensions'
import { ItemScreenContext } from '../../../state/context'
import { Text } from '../../../components/Themed'

/**
 * Selection overlay on modifier card that has minus and plus buttons for selection quantity
 */
const ModifierSelection = ({
	isSelected,
	selectionType,
	onPress,
	modList,
	mod,
	showImage,
	cardWidth,
	quantity,
	setQuantity,
}) => {
	const { modListsQuantity } = useContext(ItemScreenContext)
	if (!isSelected || selectionType !== 'MULTIPLE') {
		return null
	}

	if (selectionType === 'MULTIPLE' && quantity > 0) {
		const canSelect =
			modList.max > countQtySelected(modList, modListsQuantity[modList.id]) || modList.max < 0

		return (
			<TouchableOpacity
				activeOpacity={1}
				style={[
					showImage ? styles.quantityContainer : styles.quantityContainerNoImage,
					{
						width: responsiveWidth(12) > cardWidth * 0.5 ? cardWidth * 0.5 : responsiveWidth(15), //was 35
					},
				]}
			>
				<TouchableOpacity
					onPress={() => {
						onPress(modList.id, mod.id, modList, mod, false, quantity, setQuantity)
						//onPress(modList, mod, false)
						setQuantity(quantity > 0 ? quantity - 1 : 0)
					}}
					style={[styles.button, { paddingRight: '5%' }]}
				>
					<Ionicons name="remove" size={largeTextSize} color="white" />
				</TouchableOpacity>
				<View style={styles.numberContainer}>
					<Text style={styles.number}>{quantity}</Text>
				</View>
				<TouchableOpacity
					onPress={() => {
						if (canSelect) {
							onPress(modList.id, mod.id, modList, mod, true, quantity, setQuantity)
							//onPress(modList, mod, true)
							setQuantity(quantity + 1)
						}
					}}
					style={[{ paddingLeft: '5%' }, styles.button, !canSelect && styles.disabledButton]}
					disabled={!canSelect}
				>
					<Ionicons name="add" size={largeTextSize} color={'white'} />
				</TouchableOpacity>
			</TouchableOpacity>
		)
	}
}

const styles = StyleSheet.create({
	quantityContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: Colors.greyscale[5],
		borderRadius: 30,
		overflow: 'hidden',
		position: 'absolute',
		top: '3%',
		right: '3%',
		zIndex: 1,
	},
	quantityContainerNoImage: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: Colors.greyscale[5],
		borderRadius: 30,
		overflow: 'hidden',
		position: 'absolute',
		bottom: '10%',
		right: '3%',
		zIndex: 1,
	},
	numberContainer: {
		backgroundColor: '#FFF',
		alignItems: 'center',
		paddingVertical: '4%',
		width: '28%',
		height: '100%',
	},
	number: {
		fontSize: smallTextSize,
		fontWeight: 'bold',
		marginTop: -1,
		color: Colors.greyscale[7],
	},
	button: {},
	disabledButton: {
		opacity: 0.3,
	},
})

export default ModifierSelection
