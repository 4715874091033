import * as React from 'react'
import Orders from './Orders'
import POSOrders from './Orders.pos'
import { RootTabScreenProps } from '../../navigation/types'
export default function OrdersScreen({ navigation }: RootTabScreenProps<'TabOrders'>) {
	if (global.kiosk && global.pos) {
		return <POSOrders navigation={navigation} />
	}
	return <Orders navigation={navigation} />
}
