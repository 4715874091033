import {
	SQIPApplePay,
	SQIPCardEntry,
	SQIPCore,
	SQIPGooglePay,
} from 'react-native-square-in-app-payments'
import {
	SQUARE_APPLICATION_ID,
	SQUARE_SANDBOX_APPLICATION_ID,
	SQUARE_LOCATION_ID,
	SQUARE_SANDBOX_LOCATION_ID,
	APPLE_PAY_MERCHANT_ID,
} from '@env'
import { Alert, Platform } from 'react-native'
import { useContext, useEffect, useState } from 'react'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { CheckoutScreenContext, DataContext } from '../../../state/context'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../state/reducer'
import { auth } from '../../../firebase/config'
import { placeOrder } from '../../../screens/Checkout/Helpers/placeOrder'
import { alertResponseSingle } from '../../Alerts/Alerts'
import { useSquare } from './useSquare'
import { useSchedInteval } from '../../../screens/Checkout/Hooks/useSchedInterval'
import { useToggleConfirmModal } from '../../../screens/Checkout/Hooks/useToggleConfirmModal'
import crashlytics from '../../Crashlytics/crashlyticsLog'

const functions = getFunctions()

interface CardDetails {
	nonce: string
	card: {
		prepaidType: string
		expirationYear: number
		brand: string
		postalCode: string
		expirationMonth: number
		type: string
		lastFourDigits: string
	}
}

export const useSquareCheckout = () => {
	const orgFee = useSelector<ReduxStoreState, ReduxStoreState['orgFee']>(state => state.orgFee)

	const prepTime = useSelector<ReduxStoreState, ReduxStoreState['prepTime']>(
		state => state.prepTime
	)

	const serviceFee = useSelector<ReduxStoreState, ReduxStoreState['serviceFee']>(
		state => state.serviceFee
	)

	const prepTimeString = useSelector<ReduxStoreState, ReduxStoreState['prepTimeString']>(
		state => state.prepTimeString
	)

	const prepTimeList = useSelector<ReduxStoreState, ReduxStoreState['prepTimeList']>(
		state => state.prepTimeList
	)

	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)

	const schedDuration = useSelector<ReduxStoreState, ReduxStoreState['schedDuration']>(
		state => state.schedDuration
	)

	const orgName = loadedData[global.org].organizationName
		? loadedData[global.org].organizationName
		: 'pickup'

	const { getSchedInterval } = useSchedInteval()
	const { toggleConfirmModal } = useToggleConfirmModal()
	const uid = auth.currentUser ? auth.currentUser.uid : ''

	const {
		items,
		orderNote,
		couponCode,
		useNewPaymentMethod,
		phoneNumber,
		showPhoneNumber,
		couponCodeBackup,
		restaurantDiscountAmount,
		setOrderCreated,
		setOrderId,
		setOrderNumber,
		tableNumber,
		takeOutName,
		prices,
		setSpinner,
		isGuestCheckout,
		cardId,
		validCouponCodes,
		loading,
		tipCount,
		selectedMethods,
		posExtraData,
		savedPhone,
		setOrderPlaced,
		setIsDigitalWalletEnabled,
		saveCard,
		setCardElementVisible,
		setShowSquarePayment,
		setProcessedOrder,
		setConfirmModalVisible,
		setShowAddTip,
		modalTimestamps,
	} = useContext(CheckoutScreenContext)

	const { isDineIn, paymentProvider } = useContext(DataContext)

	const data = {
		prepTime: prepTime,
		serviceFee: serviceFee,
		userId: uid,
		prepTimeString: prepTimeString,
		prepTimeList: prepTimeList,
		loadedData: loadedData,
		items: items,
		orderNote: orderNote,
		couponCode: couponCode,
		useNewPaymentMethod: useNewPaymentMethod,
		phoneNumber: phoneNumber,
		showPhoneNumber: showPhoneNumber,
		couponCodeBackup: couponCodeBackup,
		restaurantDiscountAmount: restaurantDiscountAmount,
		setOrderCreated: setOrderCreated,
		setOrderId: setOrderId,
		setOrderNumber: setOrderNumber,
		isDineIn: isDineIn,
		tableNumber: tableNumber,
		takeOutName: takeOutName,
		prices: prices,
		setSpinner: setSpinner,
		isGuestCheckout: isGuestCheckout,
		cardId: cardId,
		validCouponCodes: validCouponCodes,
		loading: loading,
		tipCount: tipCount,
		selectedMethods: selectedMethods,
		posExtraData: posExtraData,
		timestampData: modalTimestamps,
		schedDuration: schedDuration,
		getSchedInterval,
		toggleConfirmModal,
	}

	const { createCustomerCard } = useSquare()

	let showOrderPlaced = false

	useEffect(() => {
		if (paymentProvider === 'square') {
			initState()
		}
	}, [])

	const initState = async () => {
		await SQIPCore.setSquareApplicationId(SQUARE_APPLICATION_ID)
		let digitalWalletEnabled = false
		if (Platform.OS === 'ios') {
			try {
				await SQIPApplePay.initializeApplePay(`${APPLE_PAY_MERCHANT_ID}.square`)
				digitalWalletEnabled = await SQIPApplePay.canUseApplePay()
			} catch (e) {
				console.log(e)
				crashlytics().log(e)
			}
		} else if (Platform.OS === 'android') {
			await SQIPGooglePay.initializeGooglePay(SQUARE_LOCATION_ID, 1)
			try {
				digitalWalletEnabled = await SQIPGooglePay.canUseGooglePay()
			} catch (e) {
				console.log(e)
				crashlytics().log(e)
			}
		}
		setIsDigitalWalletEnabled(digitalWalletEnabled)
	}

	/**
	 * Callback when the card entry is closed after call 'SQIPCardEntry.completeCardEntry'
	 */

	const onCardEntryComplete = () => {}

	/**
	 * Callback when successfully get the card nonce details for processing
	 * card entry is still open and waiting for processing card nonce details
	 * @param {*} cardDetails
	 */
	const onCardNonceRequestSuccess = async (cardDetails: CardDetails) => {
		try {
			data.useNewPaymentMethod = true // note to self: please refactor this
			const response: any = await chargeCardNonce(cardDetails.nonce)

			if (response.data.errorMessage) {
				throw new Error(response.data.errorMessage)
			}

			SQIPCardEntry.completeCardEntry(() => {
				crashlytics().log('SETTING ORDER PLACED: TRUE')
				setOrderPlaced(true)
				if (saveCard) {
					createCustomerCard(cardDetails.nonce, response.data.id, true)
				}
			})
		} catch (error: any) {
			SQIPCardEntry.showCardNonceProcessingError(error.message)
		}
	}

	const getSquareSandboxUser = async () => {
		const getSquareSandboxUser = httpsCallable(functions, 'getSquareSandboxUser')
		return getSquareSandboxUser({ uid })
	}

	const createSandboxSquareCustomer = async () => {
		const createSquareSandboxUser = httpsCallable(functions, 'createSquareSandboxUser')
		const user = auth.currentUser
		const name = user.displayName
		const space = name.indexOf(' ')

		await createSquareSandboxUser({
			firstName: name.substring(0, space),
			lastName: name.substring(space + 1),
			email: user.email,
			phone: savedPhone,
			uid: user.uid,
			orgId: global.org,
		})
	}

	function showSaveCardConfirmation(
		cardDetails: CardDetails,
		paymentId: string,
		hasPayed: boolean
	) {
		Alert.alert(
			'Congratulations, your order was successful!',
			`Save your ${cardDetails.card.brand} ${cardDetails.card.lastFourDigits} card?`,
			[
				{
					text: 'Cancel',
					style: 'cancel',
				},
				{
					text: 'Save',
					onPress: () => createCustomerCard(cardDetails.nonce, paymentId, hasPayed),
				},
			]
		)
	}

	const hideSquareCard = () => {
		setCardElementVisible(false)
		toggleConfirmModal()
		setShowSquarePayment(false)
	}

	const chargeCardNonce = async (nonce: string) => {
		const processedOrder = await placeOrder(data, 'square')()
		setProcessedOrder(processedOrder)
		const fbOrderId = processedOrder.data[0].orderId
		setSpinner(true)
		const squareCharge = httpsCallable(functions, 'chargeSquareOrder')
		return await squareCharge({
			nonce,
			amount: parseFloat((prices.total - prices.serviceCharge).toFixed(2)) * 100,
			orgFee: orgFee * 100,
			tipAmount: prices.tip.toFixed(2) * 100,
			serviceCharge: prices.serviceCharge.toFixed(2) * 100,
			firebaseOrderId: fbOrderId,
			orgId: global.org,
			orderData: processedOrder.data,
		})
	}

	const chargeWebCardNonce = async (nonce: string) => {
		try {
			const response: any = await chargeCardNonce(nonce)
			if (response.data.errorMessage) {
				throw new Error(response.data.errorMessage)
			}
			if (saveCard) {
				createCustomerCard(nonce, response.data.id, true)
			}
			crashlytics().log('SETTING ORDER PLACED: TRUE')
			setOrderPlaced(true)
		} catch (error: any) {
			alertResponseSingle('Error charging card', error.message, 'OK', null, null)
		}
	}

	/**
	 * Callback when card entry is cancelled and UI is closed
	 */
	const onCardEntryCancel = () => {
		// Handle the cancel callback
		setSpinner(false)
	}

	/**
	 * An event listener to start card entry flow
	 */
	const onStartCardEntry = async () => {
		const cardEntryConfig = {
			collectPostalCode: true,
		}
		await SQIPCardEntry.startCardEntryFlow(
			cardEntryConfig,
			onCardNonceRequestSuccess,
			onCardEntryCancel
		)
	}

	const chargeCustomerCard = async () => {
		const processedOrder = await placeOrder(data, 'square')()
		const fbOrderId = processedOrder.data[0].orderId
		setProcessedOrder(processedOrder)
		try {
			const customerCardCharge = httpsCallable(functions, 'chargeSquareCustomerCard')
			const response: any = await customerCardCharge({
				amount: parseFloat((prices.total - prices.serviceCharge).toFixed(2)) * 100,
				orgFee: orgFee * 100,
				tipAmount: prices.tip.toFixed(2) * 100,
				serviceCharge: prices.serviceCharge.toFixed(2) * 100,
				cardId,
				uid,
				firebaseOrderId: fbOrderId,
				orgId: global.org,
				orderData: processedOrder.data,
			})

			if (response.data.errorMessage) {
				throw new Error(response.data.errorMessage)
			}

			return { status: 'Accepted', processedOrder }
		} catch (e) {
			console.log(e)
			crashlytics().log(e)
			alertResponseSingle('Error charging card on file', e.message, 'OK', null, null)
			return { status: 'Failed', processedOrder }
		}
	}

	/**
	 * Callback when successfully get the card nonce details for processig
	 * apple pay sheet is still open and waiting for processing card nonce details
	 * @param cardDetails
	 */
	const onApplePayNonceRequestSuccess = async cardDetails => {
		try {
			// take payment with the card nonce details
			// you can take a charge
			data.useNewPaymentMethod = true // note to self: please refactor this
			try {
				const response: any = await chargeCardNonce(cardDetails.nonce)
				if (response.data.errorMessage) {
					throw new Error(response.data.errorMessage)
				}
				//hideSquareCard()
				showOrderPlaced = true
				//setOrderPlaced(true)
			} catch (error: any) {
				alertResponseSingle('Error charging card', error.message, 'OK', null, null)
			}

			// you must call completeApplePayAuthorization to close apple pay sheet
			await SQIPApplePay.completeApplePayAuthorization(true)
		} catch (ex) {
			// handle card nonce processing failure

			// you must call completeApplePayAuthorization to close apple pay sheet
			await SQIPApplePay.completeApplePayAuthorization(false, ex.message)
		}
	}

	/**
	 * Callback when failed to get the card nonce
	 * apple pay sheet is still open and waiting for processing error information
	 */
	const onApplePayNonceRequestFailure = async errorInfo => {
		// handle this error before close the apple pay sheet
		console.log(errorInfo)
		crashlytics().log(errorInfo)
		// you must call completeApplePayAuthorization to close apple pay sheet
		await SQIPApplePay.completeApplePayAuthorization(false, errorInfo.message)
		setSpinner(false)
	}

	/**
	 * Callback when the apple pay sheet is closed after
	 * call completeApplePayAuthorization or user tap to close apple pay sheet manually
	 */
	const onApplePayEntryComplete = () => {
		if (showOrderPlaced) {
			hideSquareCard()
			crashlytics().log('SETTING ORDER PLACED: TRUE')
			setOrderPlaced(true)
			setShowAddTip(false)
		} else {
			setSpinner(false)
		}
		setConfirmModalVisible(true)
	}

	/**
	 * Callback when successfully get the card nonce details for processig
	 * google pay sheet has been closed when this callback is invoked
	 */
	const onGooglePayNonceRequestSuccess = async cardDetails => {
		data.useNewPaymentMethod = true // note to self: please refactor this
		try {
			const response: any = await chargeCardNonce(cardDetails.nonce)
			if (response.data.errorMessage) {
				throw new Error(response.data.errorMessage)
			}
			crashlytics().log('SETTING ORDER PLACED: TRUE')
			setOrderPlaced(true)
		} catch (error: any) {
			alertResponseSingle('Error charging card', error.message, 'OK', null, null)
		}
	}

	/**
	 * Callback when google pay is canceled
	 * google pay sheet has been closed when this callback is invoked
	 */
	const onGooglePayCancel = () => {
		// handle google pay canceled
		setSpinner(false)
	}

	/**
	 * Callback when failed to get the card nonce
	 * google pay sheet has been closed when this callback is invoked
	 */
	const onGooglePayNonceRequestFailure = errorInfo => {
		// handle google pay failure
		console.error(errorInfo)
		setSpinner(false)
	}

	const onStartDigitalWallet = async () => {
		setSpinner(true)

		if (Platform.OS === 'ios') {
			const applePayConfig = {
				price: prices.total.toString(),
				summaryLabel: `Order for ${orgName}`,
				countryCode: 'CA',
				currencyCode: 'CAD',
				paymentType: 2,
			}
			try {
				setConfirmModalVisible(false)
				setTimeout(async () => {
					await SQIPApplePay.requestApplePayNonce(
						applePayConfig,
						onApplePayNonceRequestSuccess,
						onApplePayNonceRequestFailure,
						onApplePayEntryComplete
					)
				}, 150)
			} catch (e) {
				// Handle InAppPaymentsException
				console.error(e)
			}
		} else if (Platform.OS === 'android') {
			const googlePayConfig = {
				price: prices.total.toString(),
				currencyCode: 'CAD',
				priceStatus: 3,
			}
			try {
				await SQIPGooglePay.requestGooglePayNonce(
					googlePayConfig,
					onGooglePayNonceRequestSuccess,
					onGooglePayNonceRequestFailure,
					onGooglePayCancel
				)
			} catch (e) {
				// Handle InAppPaymentsException
				console.error(e)
			}
		}
	}

	return {
		hideSquareCard,
		onStartCardEntry,
		chargeCustomerCard,
		chargeWebCardNonce,
		onStartDigitalWallet,
	}
}
