import { StatusBar } from 'expo-status-bar'
import React, { useCallback, useContext, useState } from 'react'
import {
	View,
	TouchableOpacity,
	ScrollView,
	KeyboardAvoidingView,
	Dimensions,
	TextInput,
	Image,
} from 'react-native'
import { Text } from '../../components/Themed'
import Modal from 'react-native-modal'
import LoginForm from '../../components/Login'
import GlobalStyle, { formStyle, xlTextSize } from '../../constants/GlobalStyle'
import { styles } from './style'
import { OrganizationLogoAccountPage } from '../../components/OrganizationLogoHeader'
import { Spacer } from '../../components/Spacer'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../state/reducer'
import Separator from '../../components/Separator'
import { getFunctions, httpsCallable } from 'firebase/functions'
import ExitIcon from '../../components/Headers/UIButtons'
import Colors from '../../constants/Colors'
import { DataContext } from '../../state/context'

type LoginScreenProps = {
	onStateChange: any
	onCheckout: boolean
	navigation: any
	returnTo?: string
	loyaltyId?: string
}
/**
 * Login screen for mobile / web app (not kiosk)
 * Login entry is handled in LoginForm component
 */
export default function LoginScreen(props: LoginScreenProps) {
	const { onStateChange, onCheckout, navigation, returnTo = null, loyaltyId = null } = props

	const changeParent = useCallback(() => {
		onStateChange(true)
	}, [onStateChange])

	const organizationLogo = useSelector<ReduxStoreState, ReduxStoreState['organizationLogo']>(
		state => state.organizationLogo
	)
	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)

	const orgLogoDark = loadedData[global.org].organizationLogoDark
		? loadedData[global.org].organizationLogoDark
		: ''

	const [isConfirmModalVisible, setConfirmModalVisible] = useState(false)
	const [email, setEmail] = useState('')
	const [errorText, setErrorText] = useState('')
	const [isLoading, setIsLoading] = useState(false)

	const toggleConfirmModal = () => {
		if (isConfirmModalVisible) {
			setErrorText('')
			setEmail('')
		}
		setConfirmModalVisible(!isConfirmModalVisible)
	}

	const { hasInternet } = useContext(DataContext)

	function passwordPopup() {
		return (
			<>
				<View>
					<Modal
						style={styles.modal}
						isVisible={isConfirmModalVisible}
						onBackButtonPress={toggleConfirmModal}
						onBackdropPress={toggleConfirmModal}
						avoidKeyboard={false}
					>
						<View style={styles.modalView}>
							{/* <MaterialCommunityIcons
								name="close-circle-outline"
								size={35}
								style={styles.closeIcon}
								onPress={toggleConfirmModal}
							/> */}

							<ExitIcon onPress={toggleConfirmModal} style={{ marginTop: -30 }} />

							<Text
								style={[
									GlobalStyle.titleText,
									{ marginTop: 35, fontSize: xlTextSize, textAlign: 'center' },
								]}
							>
								Please enter your email
							</Text>
							<View style={styles.inputContainer}>
								<TextInput
									returnKeyType={'done'}
									style={styles.textInput}
									placeholder="Email"
									onChangeText={text => {
										setEmail(text)
										setErrorText('')
									}}
									value={email}
								/>

								<TouchableOpacity
									style={[
										formStyle.button,
										{
											backgroundColor: !hasInternet ? Colors.greyscale[6] : global.orgColor,
											width: 'auto',
											paddingHorizontal: 10,
											marginLeft: 10,
										},
									]}
									onPress={isLoading ? null : sendLink}
									disabled={!hasInternet}
								>
									{isLoading ? (
										<Image
											style={{
												width: 90,
												height: 90,
												marginTop: -34,
												marginBottom: -34,
											}}
											source={require('../../assets/images/loadImg.gif')}
										/>
									) : (
										<Text style={formStyle.btnText}>{'CONFIRM'}</Text>
									)}
								</TouchableOpacity>
							</View>
							<Text style={styles.errorText}>{errorText}</Text>
						</View>
					</Modal>
				</View>
			</>
		)
	}

	function sendLink() {
		resetPassword()
	}

	async function resetPassword() {
		const functions = getFunctions()
		setIsLoading(true)
		const sendEmailPasswordReset = httpsCallable(functions, 'sendEmailPasswordReset')
		const reset: any = await sendEmailPasswordReset({
			userEmail: email.toLowerCase(),
			orgId: global.org,
			orgName: loadedData[global.org].organizationName,
			webURL: loadedData[global.org].webURL,
		})
		setIsLoading(false)
		if (reset && reset.data && reset.data?.success) {
			setErrorText(
				`If this email exists, a link to reset your password has been sent to this email address. Please check your spam folder if you do not see an email from ${
					loadedData[global.org].organizationName
				}.`
			)
		} else {
			setErrorText('There was an error sending your reset password email. Please try again.')
		}
	}
	return (
		<KeyboardAvoidingView style={styles.container} behavior="height">
			<ScrollView
				style={{ height: Dimensions.get('window').height, width: '100%' }}
				bounces={false}
			>
				<View style={{ width: '90%', marginHorizontal: '5%' }}>
					<Spacer size={25} />

					<StatusBar style="auto" />

					<OrganizationLogoAccountPage imageUri={orgLogoDark ? orgLogoDark : organizationLogo} />

					<Spacer size={10} />
					<View style={{ marginHorizontal: 10 }}>
						<LoginForm
							onCheckout={onCheckout}
							navigation={navigation}
							returnTo={returnTo}
							loyaltyId={loyaltyId}
						/>
					</View>

					<Spacer size={30} />

					<View style={{ alignItems: 'center' }}>
						<Text style={GlobalStyle.text}>{"Don't have an account?"}</Text>
						<TouchableOpacity onPress={changeParent}>
							<Text style={styles.signupBtn}>Sign up</Text>
						</TouchableOpacity>
						<Separator />
						<TouchableOpacity onPress={toggleConfirmModal}>
							<Text style={[styles.signupBtn, { paddingBottom: 75 }]}>Forgot Password?</Text>
						</TouchableOpacity>
					</View>
				</View>
			</ScrollView>
			<View style={{ height: 50 }}></View>
			{passwordPopup()}
		</KeyboardAvoidingView>
	)
}
