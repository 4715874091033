import React, { useContext, useState } from 'react'
import { Dimensions, Platform, TextInput, TouchableOpacity, StyleSheet, Button } from 'react-native'
import { Text, View } from '../../../../components/Themed'
import GlobalStyle, { formStyle, mediumTextSize } from '../../../../constants/GlobalStyle'
import { auth } from '../../../../firebase/config'
import { addFreeItem, userCanRedeem } from '../../Helpers/checkCouponCode'
import { CheckoutScreenContext, DataContext } from '../../../../state/context'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../state/reducer'
import ExitIcon from '../../../../components/Headers/UIButtons'
import Colors from '../../../../constants/Colors'

export function AddPromo({ promoFuncData, navigation, staffMeal = true }) {
	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)
	const {
		items,
		couponCodeDisplay,
		setCouponCodeDisplay,
		userRedeemedCodes,
		userAvailableCodes,
		setIsFreeItemDiscount,
		prices,
		validCouponCodes,
	} = useContext(CheckoutScreenContext)
	const { hasInternet } = useContext(DataContext)

	const freeItems = loadedData['freeItem']

	var promoReturn = []
	if (freeItems && freeItems.length > 0) {
		freeItems.forEach(promo => {
			if (!promo.codeOnly) {
				promoReturn.push(
					<TouchableOpacity
						onPress={() => {
							if (promo.minSpend <= prices.subTotal) {
								const freeItemIndex = items.findIndex(item => item.isFree === true)

								//only 1 free item per cart
								if (freeItemIndex === -1) {
									promoFuncData.closeModal()
									addFreeItem(promo.freeItemIds[0], navigation)
									setIsFreeItemDiscount(true)
								} else {
									setCouponCodeDisplay(
										<Text style={{ color: 'red' }}>Your cart already contains a free item!</Text>
									)
								}
							} else {
								setCouponCodeDisplay(
									<Text style={{ color: 'red' }}>
										Must spend ${promo.minSpend} to receive free item
									</Text>
								)
							}
						}}
						style={{
							borderWidth: 2,
							borderColor: global.orgColor,
							borderRadius: 30,
							marginHorizontal: 30,
							flexDirection: 'row',
							paddingHorizontal: 30,
							marginVertical: 5,
							paddingVertical: 0,
						}}
					>
						<Text
							style={[
								GlobalStyle.ctgTitle,
								{
									flex: 1,
									textAlign: 'center',
									textAlignVertical: 'center',
									fontSize: 16,
									paddingHorizontal: 0,
								},
							]}
						>
							{promo.name}
						</Text>
					</TouchableOpacity>
				)
			}
		})
	}
	if (validCouponCodes && validCouponCodes.length > 0) {
		validCouponCodes.forEach(promo => {
			if (!promo.codeOnly && promo.type !== 'staffMeal') {
				if (!promo.userSpecific) {
					promoReturn.push(
						<TouchableOpacity
							onPress={() => {
								promoFuncData.setPromo(promo.couponCode)
							}}
							style={{
								justifyContent: 'center',
								alignItems: 'center',
								borderWidth: 4,
								borderColor: global.orgColor,
								borderRadius: 15,
								marginHorizontal: 5,
								marginVertical: 5,
								height: Dimensions.get('window').height / 10,
								width:
									Dimensions.get('window').width >= 700
										? Dimensions.get('window').width / 4
										: Dimensions.get('window').width / 2.75,
							}}
						>
							<Text
								style={[
									GlobalStyle.ctgTitle,
									{
										textAlign: 'center',
										textAlignVertical: 'center',
										fontSize: Dimensions.get('window').width >= 700 ? 20 : 14,
										paddingHorizontal: 5,
									},
								]}
							>
								{promo.name}
							</Text>
						</TouchableOpacity>
					)
				} else if (auth.currentUser && userCanRedeem(promo, userRedeemedCodes)) {
					if (userAvailableCodes.length > 0) {
						userAvailableCodes.forEach(code => {
							if (promo.id == code) {
								promoReturn.push(
									<TouchableOpacity
										onPress={() => {
											promoFuncData.setPromo(promo.couponCode)
										}}
										style={{
											borderWidth: 4,
											borderColor: '#eaeaea',
											borderRadius: 15,
											marginHorizontal: 5,
											marginVertical: 5,
											height: Dimensions.get('window').height / 10,
											width:
												Dimensions.get('window').width >= 700
													? Dimensions.get('window').width / 4
													: Dimensions.get('window').width / 2.75,
										}}
									>
										<Text
											style={[
												GlobalStyle.ctgTitle,
												{
													textAlign: 'center',
													textAlignVertical: 'center',
													fontSize: Dimensions.get('window').width >= 700 ? 20 : 14,
													paddingHorizontal: 5,
													marginTop: Platform.OS === 'ios' ? 5 : 0,
												},
											]}
										>
											{promo.name}
										</Text>
									</TouchableOpacity>
								)
							}
						})
					}
				}
			}
		})
	}

	const [promoCode, setPromoCode] = useState('')

	return (
		<>
			{!staffMeal && (
				<ExitIcon
					onPress={() => {
						promoFuncData.closeModal()
					}}
					style={{ top: -10 }}
				/>
			)}

			<View style={{ height: promoReturn.length > 0 ? 0 : 25 }}></View>
			{promoReturn.length > 0 ? (
				<Text
					style={[
						GlobalStyle.headerText,
						{
							textAlign: 'center',
							marginVertical: staffMeal ? 0 : 20,
							paddingTop: staffMeal ? 0 : '5%',
						},
					]}
				>
					SELECT A {staffMeal ? 'STAFF MEAL' : 'PROMOTION'}
				</Text>
			) : null}
			<View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
				{promoReturn}
			</View>

			<Text
				style={[
					GlobalStyle.headerText,
					{
						textAlign: 'center',
						marginVertical: 20,
					},
				]}
			>
				ENTER PROMO CODE
			</Text>

			<View
				style={{
					flexDirection: 'row',
					alignSelf: 'center',
					marginVertical: 10,
					width: staffMeal ? '100%' : '90%',
				}}
			>
				<TextInput
					returnKeyType={'done'}
					style={[
						formStyle.textInput,
						{
							flex: 1,
							fontSize: mediumTextSize,
							marginRight: 10,
						},
					]}
					placeholder={'Promo code'}
					value={promoCode}
					onChangeText={setPromoCode}
					onSubmitEditing={() => {
						promoFuncData.setPromo(promoCode)
					}}
				/>
				<TouchableOpacity
					style={[formStyle.textInput, !hasInternet && { backgroundColor: Colors.greyscale[6] }]}
					onPress={() => {
						promoFuncData.setPromo(promoCode)
					}}
					disabled={!hasInternet}
				>
					<Text style={{ fontSize: mediumTextSize }}>ADD</Text>
				</TouchableOpacity>
			</View>

			{couponCodeDisplay ? (
				couponCodeDisplay
			) : (
				<View style={{ height: mediumTextSize * 2.7 }}></View>
			)}
		</>
	)
}
