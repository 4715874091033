/* eslint-disable react/display-name */
import React from 'react'
import { View, TouchableOpacity, Text, Linking, Platform, StyleSheet } from 'react-native'
import { WebView } from 'react-native-webview'

const DoorDashTracking = React.memo(({ url }: { url: string }) => {
	return (
		<View style={styles.container}>
			{Platform.OS === 'web' ? (
				<>
					<iframe src={url} style={styles.webview} frameBorder="0" allowFullScreen scrolling="no" />
					<TouchableOpacity
						onPress={() => {
							Linking.openURL(url)
						}}
						style={styles.trackButton}
					>
						<Text style={styles.buttonText}>Track Your Order</Text>
					</TouchableOpacity>
				</>
			) : (
				<>
					<WebView
						source={{
							uri: url,
						}}
						style={styles.webview}
						javaScriptEnabled={true}
						domStorageEnabled={true}
						startInLoadingState={true}
						showsHorizontalScrollIndicator={false}
						showsVerticalScrollIndicator={false}
						scrollEnabled={false}
						cacheEnabled={false}
						cacheMode="LOAD_NO_CACHE"
					/>
					<TouchableOpacity
						onPress={() => {
							Linking.openURL(url)
						}}
						style={styles.trackButton}
					>
						<Text style={styles.buttonText}>Track Your Order</Text>
					</TouchableOpacity>
				</>
			)}
		</View>
	)
})

const styles = StyleSheet.create({
	container: {
		height: Platform.OS === 'web' ? 750 : 780, //690
		width: '100%',
		marginBottom: 30,
	},
	webview: {
		flex: 1,
	},
	trackButton: {
		backgroundColor: '#d3d3d3',
		paddingVertical: 15,
		alignItems: 'center',
		justifyContent: 'center',
	},

	buttonText: {
		color: '#000',
		fontSize: 16,
		fontWeight: 'bold',
	},
})

export default DoorDashTracking
