import { AntDesign, EvilIcons, Ionicons } from '@expo/vector-icons'
import React, { useContext, useEffect, useRef } from 'react'
import { View, StyleSheet, TouchableOpacity, Dimensions } from 'react-native'
import Colors from '../../../../constants/Colors'
import { CheckoutScreenContext, DataContext } from '../../../../state/context'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../state/reducer'
import { pickupText } from '../../../../components/ScheduleHours/DisplayPickupText'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { largeTextSize, titleTextSize } from '../../../../constants/GlobalStyle'
import { logCompleteCheckoutAnalytics } from '../../../../firebase/analytics'
import { auth } from '../../../../firebase/config'
import { add } from 'date-fns'
import { parsePrepTime } from '../../../../helpers/prepTimeFunctions'
import { Text } from '../../../../components/Themed'
import crashlytics from '../../../../components/Crashlytics/crashlyticsLog'

/**
 * Screen to display after order is successfully placed
 */
const OrderConfirmed = ({ goHome }) => {
	const prepTime = useSelector<ReduxStoreState, ReduxStoreState['prepTime']>(
		state => state.prepTime
	)
	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)

	const schedCurrTime = useSelector<ReduxStoreState, ReduxStoreState['schedCurrTime']>(
		state => state.schedCurrTime
	)

	const schedDuration = useSelector<ReduxStoreState, ReduxStoreState['schedDuration']>(
		state => state.schedDuration
	)

	const storeOpen = useSelector<ReduxStoreState, ReduxStoreState['storeOpen']>(
		state => state.storeOpen
	)

	const cart = useSelector<ReduxStoreState, ReduxStoreState['items']>(state => state.items)

	const {
		selectedMethods,
		setSelectedMethods,
		orderId,
		analyticsItems,
		prices,
		posLoyaltyPoints,
		posLoyaltyPointsAccrued,
		orderData,
		orderNumber,
		modalTimestamps,
	} = useContext(CheckoutScreenContext)

	const {
		webAppURL,
		isDelivery,
		userLocation,
		defPrep,
		deliveryEstimate,
		contactlessDropoff,
		deliveryActionIfUndeliverable,
		deliveryPickupInstructions,
		deliveryIsSandbox,
		selectedPickupPoint,
		deliveryEnabled,
	} = useContext(DataContext)

	const deliveryTime =
		deliveryEstimate && deliveryEstimate.estimate ? deliveryEstimate.estimate.timeToDeliver : 60

	const descriptor = global.pos ? 'THE' : 'YOUR'
	const pronoun = global.pos ? 'THEY' : 'YOU'

	const showLoyalty =
		posLoyaltyPoints > 0 &&
		loadedData[global.org].posLoyaltyEnabled &&
		selectedMethods.phoneString !== ''

	let orderText = ''

	if (selectedMethods.textMessage && selectedMethods.email && selectedMethods.print) {
		orderText = `${descriptor} RECEIPT WILL BE SENT TO ${descriptor} PHONE AND EMAIL ADDRESS AND A PRINTED VERSION WILL BE ATTACHED TO ${descriptor} ORDER.`
	} else if (selectedMethods.textMessage && selectedMethods.email) {
		orderText = `${descriptor} RECEIPT WILL BE SENT TO ${descriptor} PHONE AND EMAIL ADDRESS.`
	} else if (selectedMethods.email && selectedMethods.print) {
		orderText = `${descriptor} RECEIPT WILL BE SENT TO ${descriptor} EMAIL ADDRESS AND A PRINTED VERSION WILL BE ATTACHED TO ${descriptor} ORDER.`
	} else if (selectedMethods.textMessage && !selectedMethods.print) {
		orderText = `${descriptor} RECEIPT WILL BE SENT TO ${descriptor} PHONE.`
	} else if (selectedMethods.textMessage && selectedMethods.print) {
		orderText = `${descriptor} RECEIPT WILL BE SENT TO ${descriptor} PHONE AND A PRINTED VERSION WILL BE ATTACHED TO ${descriptor} ORDER.`
	} else if (selectedMethods.email && !selectedMethods.print) {
		orderText = `${descriptor} RECEIPT WILL BE SENT TO ${descriptor} EMAIL ADDRESS.`
	} else if (selectedMethods.print) {
		orderText = `A PRINTED VERSION OF ${descriptor} RECEIPT WILL BE ATTACHED TO ${descriptor} ORDER.`
	}

	const pickupTextData = splitString(
		pickupText(
			schedCurrTime,
			schedDuration,
			storeOpen,
			isDelivery ? 'Delivery in ' + deliveryTime + ' minutes' : 'Pickup in ' + defPrep + ' minutes',
			prepTime,
			deliveryTime
		)
	)

	const functions = getFunctions()

	const hasRun = useRef(false)

	useEffect(() => {
		const sendEmailReceipt = async () => {
			try {
				const sendEmailReceipt = httpsCallable(functions, 'sendEmailReceipt')
				console.log('SENDING EMAIL RECEIPT')
				crashlytics().log('SENDING EMAIL RECEIPT')
				const response = await sendEmailReceipt({
					userEmail: selectedMethods.emailString,
					receiptLink: webAppURL + '/receipt?receiptId=' + orderId,
					orgLogo: loadedData[global.org].organizationLogo,
					orgName: loadedData[global.org].organizationName,
				})
				console.log('EMAIL RECEIPT SENT.')
				crashlytics().log('EMAIL RECEIPT SENT.')

				console.log(response.data)
			} catch (error) {
				console.error('Error calling cloud function', error)
				crashlytics().log('Error calling cloud function' + error)
			}
		}
		const sendTextReceipt = async () => {
			try {
				const sendTextReceipt = httpsCallable(functions, 'sendTextReceipt')
				console.log('SENDING TEXT RECEIPT')
				crashlytics().log('SENDING TEXT RECEIPT')
				const response = await sendTextReceipt({
					userPhone: selectedMethods.phoneString,
					receiptLink: webAppURL + '/receipt?receiptId=' + orderId,
					organizationName: loadedData[global.org].organizationName,
				})
				console.log('TEXT RECEIPT SENT.')
				crashlytics().log('TEXT RECEIPT SENT.')

				console.log(response.data)
			} catch (error) {
				console.error('Error calling cloud function', error)
				crashlytics().log('Error calling cloud function' + error)
			}
		}
		const addLoyaltyPointsToUser = async () => {
			try {
				const updateUserPoints = httpsCallable(functions, 'updateUserPoints')
				console.log('UPDATING USER POINTS')
				crashlytics().log('UPDATING USER POINTS')
				const response = await updateUserPoints({
					orgId: global.org,
					uid: orderData.userId,
					pointsEarned: orderData.pointsEarned,
					redeemedRewards: orderData.redeemedRewards,
				})
				console.log('POINTS UPDATED.')
				crashlytics().log('POINTS UPDATED.')
				crashlytics().log(JSON.stringify(response.data))
				console.log(response.data)
			} catch (error) {
				console.log('Error attaching loyalty points: ' + error)
				crashlytics().log('Error attaching loyalty points: ' + error)
			}
		}
		const addRedeemedRewards = async () => {
			try {
				const addRedeemedRewards = httpsCallable(functions, 'addRedeemedRewards')
				console.log('UPDATING REDEEMED REWARDS')
				crashlytics().log('UPDATING REDEEMED REWARDS')
				const response = await addRedeemedRewards({
					orgId: global.org,
					uid: orderData.userId,
					orderId: orderId,
					redeemedRewards: orderData.redeemedRewards,
				})
				console.log('REWARDS UPDATED.')
				crashlytics().log('REWARDS UPDATED.')
				crashlytics().log(JSON.stringify(response.data))

				console.log(response.data)
			} catch (error) {
				console.log('Error attaching loyalty points: ' + error)
				crashlytics().log('Error attaching loyalty points: ' + error)
			}
		}
		const sendDeliveryOrder = async () => {
			try {
				const sendOrderToDoorDash = httpsCallable(functions, 'createDoorDashDeliveryOrder')
				console.log('CREATING DOORDASH ORDER')
				crashlytics().log(
					'CREATING DOORDASH ORDER FOR ' + orderId + ' AND ORDER NUM ' + orderNumber
				)
				console.log('USER ID ' + orderData.userId)
				const mappedItems = orderData.items.map(item => ({
					name: item.name,
					quantity: item.qty,
					external_id: item.id,
					price: item.ppu,
				}))
				const prepReturn = orderData.getSchedInterval(false).toString()
				//if username or userphone are '', look up userId in database and get the name and phone number
				const doorDashData = {
					pickupTime: add(new Date(), parsePrepTime(prepReturn)),
					orgId: global.org,
					restaurantId: cart.length > 0 ? cart[0].rId : null,
					userName: orderData.takeOutName,
					userPhone: orderData.selectedMethods.phoneString,
					userId: orderData.userId,
					tipAmount: Math.round(orderData.prices.tip * 100),
					orderTotal: Math.round(orderData.prices.subTotal * 100),
					dropoffAddress: userLocation ? userLocation.longAddress : '',
					items: mappedItems,
					orderNumber: orderNumber,
					orderId: orderId,
					ageVerificationRequired: orderData.ageVerificationRequired,
					isContactless: contactlessDropoff,
					dropoffInstructions:
						userLocation && userLocation.instructions ? userLocation.instructions : '',
					ifUndeliverable: deliveryActionIfUndeliverable,
					pickupInstructions: deliveryPickupInstructions,
					textUpdates: selectedMethods.textUpdates,
					organizationName: loadedData[global.org].organizationName || '',
					isSandbox: deliveryIsSandbox,
				}
				crashlytics().log(JSON.stringify(doorDashData))
				const response = await sendOrderToDoorDash(doorDashData)
				console.log('DOORDASH ORDER SENT.')
				crashlytics().log('DOORDASH ORDER SENT')

				console.log(response.data)
			} catch (error) {
				console.log('Error sending doordash order: ' + error)
				crashlytics().log('Error sending doordash order: ' + error)
				//TO DO: update status to failed
			}
		}
		const updateModalTimestamps = async (timestamps, orderId) => {
			try {
				const confirmedScreenTime = new Date().toISOString()
				const updateModalTimestamps = httpsCallable(functions, 'updateModalTimestamps')
				console.log('UPDATING MODAL TIMESTAMPS')
				crashlytics().log('UPDATING MODAL TIMESTAMPS')

				await updateModalTimestamps({
					orgId: global.org,
					orderId: orderId,
					timestamps: timestamps,
					confirmedScreenTimestamp: confirmedScreenTime,
				})
				console.log('MODAL TIMESTAMPS UPDATED.')
				crashlytics().log('MODAL TIMESTAMPS UPDATED.')
			} catch (error) {
				console.error('Error calling cloud function', error)
				crashlytics().log('Error sending calling cloud function: ' + error)
			}
		}

		if (hasRun.current) {
			return
		} else {
			hasRun.current = true
			if (isDelivery) {
				sendDeliveryOrder()
			}
			if (orderData.pointsEarned > 0) {
				addLoyaltyPointsToUser()
			}

			if (orderData.redeemedRewards.length > 0) {
				addRedeemedRewards()
			}

			if (selectedMethods.email) {
				sendEmailReceipt()
			}
			if (selectedMethods.textMessage) {
				sendTextReceipt()
			}
			crashlytics().log('CONFIRM ORDER TEXT: ' + orderText)
			updateModalTimestamps(modalTimestamps, orderId)
			logCompleteCheckoutAnalytics(prices.total / 100, orderId, analyticsItems, prices.taxes / 100)
		}
	}, [])

	const headerText = global.pos ? 'ORDER PLACED!' : 'THANK YOU!'
	const width = Dimensions.get('window').width
	return (
		<View style={styles.container}>
			<Text style={[styles.mainHeader, { color: global.orgColor }]}>{headerText}</Text>
			<View style={styles.contentContainer}>
				<View style={styles.rowContainer}>
					<Ionicons name="md-checkbox-outline" size={titleTextSize * 1.5} color="#818181" />
					<Text style={[styles.orderConfirmationText, { marginLeft: 35 }]}>
						{`${descriptor} ORDER HAS BEEN PLACED. ${pickupTextData.pickupText} `}
						<Text style={{ color: global.orgColor }}>{pickupTextData.timeString}</Text>
						{selectedPickupPoint?.id === 'store_pickup' ? ' FROM THE STORE' : ''}
						{selectedPickupPoint === null || selectedPickupPoint?.id === 'store_pickup'
							? '.'
							: ` AT ${selectedPickupPoint.name.toUpperCase()}.`}
					</Text>
				</View>
				{selectedMethods.textMessage &&
					selectedMethods.textUpdates &&
					selectedMethods.phoneString !== '' && (
						<View style={[styles.rowContainer, { marginLeft: '-2.5%' }]}>
							<EvilIcons name="bell" size={titleTextSize * 1.95} color="#818181" />
							<Text style={[styles.orderConfirmationText, { marginLeft: 20 }]}>
								{deliveryEnabled && isDelivery
									? `YOU WILL RECEIVE TEXT UPDATES WITH YOUR DELIVERY PROGRESS`
									: `${pronoun} WILL RECEIVE A TEXT WHEN ${descriptor} ORDER IS READY FOR PICKUP.`}
							</Text>
						</View>
					)}

				<View
					style={[
						styles.rowContainer,
						{ marginLeft: -3, display: orderText !== '' ? 'flex' : 'none' },
					]}
				>
					<Ionicons name="md-receipt-outline" size={titleTextSize * 1.5} color="#818181" />
					<Text style={[styles.orderConfirmationText, { marginLeft: 30 }]}>{orderText}</Text>
				</View>
				{(showLoyalty || posLoyaltyPointsAccrued > 0) && (
					<View style={styles.rowContainer}>
						<AntDesign name="staro" size={titleTextSize * 1.5} color="#818181" />
						<Text style={[styles.orderConfirmationText, { marginLeft: 35 }]}>
							{`YOU HAVE EARNED${
								posLoyaltyPointsAccrued > 0 ? ' ' + posLoyaltyPointsAccrued : ''
							} POINT${posLoyaltyPointsAccrued === 1 ? '' : 'S'}`}
						</Text>
					</View>
				)}
			</View>
			<View style={styles.buttonContainer}>
				{/* {global.kiosk && (
					<>
						<Text style={[styles.orderConfirmationText]}>
							{'SCAN TO EARN POINTS ON THIS ORDER'}
						</Text>
						<QRCode
							value={'https://order.tryfoodrepublic.ca/rewards?id=' + orderId}
							size={width * 0.1 > 100 ? width * 0.1 : 100}
						/>
					</>
				)} */}
				{global.kiosk && (
					<TouchableOpacity
						style={[styles.doneButton, { marginBottom: 30 }]}
						onPress={() => {
							goHome(true)
							setSelectedMethods({
								textMessage: false,
								email: false,
								print: false,
								textUpdates: false,
							})
						}}
					>
						<Text style={styles.buttonText}>{'DONE'}</Text>
					</TouchableOpacity>
				)}
				<TouchableOpacity
					style={[styles.doneButton, { backgroundColor: global.orgColor, marginBottom: 20 }]}
					onPress={() => {
						goHome(true)
						setSelectedMethods({
							textMessage: false,
							email: false,
							print: false,
							textUpdates: false,
						})
					}}
				>
					<Text style={styles.buttonText}>
						{global.kiosk ? 'START NEW ORDER' : 'RETURN TO HOME'}
					</Text>
				</TouchableOpacity>
			</View>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'center',
		marginHorizontal: '10%',
		marginVertical: '10%',
		backgroundColor: '#fff',
		marginBottom: 30,
		justifyContent: 'space-between',
		maxHeight: Dimensions.get('window').height * 0.8,
	},
	contentContainer: {
		alignItems: 'flex-start',
		justifyContent: 'center',
		flex: 1,
	},
	rowContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
		marginVertical: '8%',
	},
	orderConfirmationText: {
		marginLeft: '5%',
		fontSize: largeTextSize,
		fontWeight: '600',
		color: '#4E4E4E',
	},
	mainHeader: {
		fontSize: titleTextSize,
		textAlign: 'center',
		fontWeight: '600',
	},
	buttonContainer: {
		alignItems: 'center',
		width: '105%',
	},
	doneButton: {
		backgroundColor: 'grey',
		borderRadius: 12,
		paddingVertical: 20,
		width: '100%',
		//marginBottom: 30,
		alignItems: 'center',
		justifyContent: 'center',
	},
	buttonText: {
		color: Colors.custom.restaurantMainText,
		fontSize: largeTextSize,
		fontWeight: 'bold',
	},
})

function splitString(inputStr) {
	// Split the input string into an array of words
	const words = inputStr.split(' ')

	// Check if the input string has at least two words
	if (words.length < 2) {
		return { pickupText: inputStr, timeString: '' }
	}

	// Join the first two words into one string
	const pickupText = words.slice(0, 2).join(' ').toUpperCase()

	// Join the remaining words (if any) into another string
	const timeString = words.slice(2).join(' ').toUpperCase()

	return { pickupText, timeString }
}

export default OrderConfirmed
