import { auth } from '../../../firebase/config'
import { alertResponse, alertResponseSingle } from '../../../components/Alerts/Alerts'
import { handleNextButtonClick, addCouponForReferrer } from './functions'

/**
 *
 * @param data
 * @param paymentProvider
 * Places order by returning async function
 */
export function placeOrder(data, paymentProvider) {
	var prepReturn = data.getSchedInterval(false).toString()
	return async () => {
		var processedOrder

		if (!auth.currentUser && !data.isGuestCheckout) {
			// not signed in
			data.toggleConfirmModal()
			alertResponse(
				'Not signed in',
				'You must sign in before placing your order',
				'Cancel',
				'Sign in',
				'cancel',
				'default',
				() => {
					return
				},
				() => {
					data.navigation.navigate('AccountStack', { screen: 'Account', params: { signup: false } })
				}
			)
			return null
		}

		data.setSpinner(true)
		let couponVals = []
		if (data.validCouponCodes !== undefined) {
			couponVals = Object.values(data.validCouponCodes)
		}

		const i = couponVals
			.map(function (e) {
				return e.couponCode.trim().toLowerCase()
			})
			.indexOf(data.couponCode.trim().toLowerCase())

		if ((data.cardId === '' || data.loading) && !data.useNewPaymentMethod && !global.kiosk) {
			data.toggleConfirmModal()
			data.setSpinner(false)
			return null
		}

		try {
			processedOrder = await handleNextButtonClick(
				prepReturn,
				paymentProvider,
				paymentProvider === 'square' ? null : data.navigation,
				data.prices,
				data.items,
				data.orderNote,
				couponVals[i],
				data.tipCount,
				data.prepTime,
				data.prepTimeString,
				{
					address: '',
				},
				data.userId,
				data.prepTimeList,
				data.serviceFee,
				data.loadedData['BOGO'],
				data.loadedData['freeItem'],
				data.phoneNumber,
				data.isGuestCheckout,
				data.showPhoneNumber,
				data.loadedData[global.org].restaurants,
				data.tableNumber,
				data.isDineIn,
				data.takeOutName,
				data.restaurantDiscountAmount,
				data.selectedMethods,
				data.posExtraData,
				data.redeemedRewards,
				data.pointsEarned,
				data.timestampData,
				data.pickupPointObject,
				data.schedDuration,
				data.loadedData[global.org].environment === 'sandbox'
			)
		} catch (error) {
			console.log(error)
			data.toggleConfirmModal()
			data.setSpinner(false)
			alertResponseSingle('Error placing order', 'Please try again.', 'OK', null, () => {
				null
			})
		}

		if (processedOrder !== 'PaymentError') {
			data.setOrderId(processedOrder.data[0].orderId)

			data.setOrderNumber(processedOrder.data[0].orderNumber)
			data.setOrderCreated(processedOrder.created)

			if (data.couponCodeBackup !== '') {
				await addCouponForReferrer(data.couponCodeBackup, data.userId)
			}
		}

		return processedOrder
	}
}
