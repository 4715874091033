import { DATABASE_NAME } from '@env'
import { doc, collection, onSnapshot } from 'firebase/firestore'
import { db } from '../../firebase/config'
import '../../global'
import Formatter from '../Formatter'
import { checkSnooze } from '../checkSnooze'
import { convertHours } from '../prepTimeFunctions'

export function formatMenuItems(data: any) {
	let hoursReturn = null
	if (data.hours && Object.keys(data.hours).length !== 0) {
		hoursReturn = convertHours(data.hours, 0)
	}
	return {
		id: data.id,
		posId: data.posId,
		name: data.name,
		description: data.description,
		restaurantId: data.restaurantId,
		price: data.retailPriceMoney ? data.retailPriceMoney.amount : -1,
		promo: data.promoPrice ? data.promoPrice.amount : -1,
		imageUri: data.itemImage && data.itemImage.url ? data.itemImage.url : '',
		thumbnailUri: data.itemImage && data.itemImage.thumbnailUrl ? data.itemImage.thumbnailUrl : '',
		taxIds: data.taxIds,
		modifierLists: data.modifierLists,
		isSnoozed: data.isSnoozed,
		snoozeUntil: data.snoozeUntil,
		ordinal: data.ordinal || Number.MAX_SAFE_INTEGER,
		hours: hoursReturn,
		variationIds: data.variationIds || [],
		variationMinPrice: data.variationMinPrice || -1,
		variationMaxPrice: data.variationMaxPrice || -1,
		plu: data?.channelData?.plu ?? '',
		ageVerificationRequired: data?.ageVerificationRequired || false,
	}
}

//check if mod list in item data is in the mod list collection
const checkModLists = (data, modifierListObject, modifierItemObject) => {
	const modLists = data.modifierLists
	const modReturn = []
	if (modLists) {
		modLists.forEach(modList => {
			if (modifierListObject[modList.modifierListId]) {
				//add to mod list
				modReturn.push(modList)
				//check if variation, set variationMinPrice & variationMaxPrice
				if (modifierListObject[modList.modifierListId].variation) {
					data.variationMinPrice = Number.MAX_SAFE_INTEGER
					data.variationMaxPrice = 0
					modifierItemObject['items_for_' + modList.modifierListId].forEach(mod => {
						if (mod.amount < data.variationMinPrice) {
							data.variationMinPrice = mod.amount
						}
						if (mod.amount > data.variationMaxPrice) {
							data.variationMaxPrice = mod.amount
						}
					})
				}
			}
		})
	}
	data.modifierLists = modReturn
	return data
}
export const getMenuItemData = (
	menuItemCategories,
	modifierListObject,
	modifierItemObject,
	restaurantObject
) => {
	return new Promise((resolve, reject) => {
		const dataImport = {}
		const menuItemRef = collection(doc(db, DATABASE_NAME, global.org), 'MenuItems')

		onSnapshot(
			menuItemRef,
			snapshot => {
				snapshot.docs.forEach(doc => {
					let data = doc.data()
					if (!data.isDeleted) {
						data = checkModLists(data, modifierListObject, modifierItemObject)
						const itemReturn = formatMenuItems(data)

						if (itemReturn.thumbnailUri === '' && itemReturn.imageUri !== '') {
							itemReturn.thumbnailUri = itemReturn.imageUri
						}

						if (
							data.menuItemCategoryIds &&
							!checkSnooze(itemReturn.isSnoozed, itemReturn.snoozeUntil)
						) {
							data.menuItemCategoryIds.forEach((catId: string) => {
								const category = menuItemCategories.menuItemCategories.find(cat => cat.id === catId)
								const restaurantId = restaurantObject[itemReturn.restaurantId]
								if (category && restaurantId) {
									category.items.push({
										id: itemReturn.id,
										desc: itemReturn.description,
										category: category.name,
										name: itemReturn.name, //toUpperCase
										menuId: '',
										promoPrice: itemReturn.promo,
										rId: itemReturn.restaurantId,
										image: itemReturn.imageUri,
										thumbnail: itemReturn.thumbnailUri,
										ordinal: itemReturn.ordinal,
										hours: itemReturn.hours,
										price:
											itemReturn.price === -1
												? '$0.00'
												: Formatter.currencyFormat(itemReturn.price / 100),
									}) // using push for array
									category.restaurantIds.add(data.restaurantId)
									if (!category.image || category.image.url === '' || !category.image.url) {
										category.image = {
											url: itemReturn.imageUri,
											thumbnailUrl: itemReturn.thumbnailUri,
										}
									}
								}
							})
						}

						dataImport[data.id] = itemReturn
					}
				})

				// Sort items in each category by ordinal
				menuItemCategories.menuItemCategories.forEach(category => {
					category.items.sort((a, b) => a.ordinal - b.ordinal)
				})
				dataImport['menuItemCategories'] = menuItemCategories.menuItemCategories
				resolve(dataImport)
			},
			reject
		)
	})
}
