import React, { useContext, useEffect, useState } from 'react'
import {
	Modal,
	View,
	TouchableOpacity,
	StyleSheet,
	ScrollView,
	Dimensions,
	Platform,
} from 'react-native'
import { DataContext } from '../state/context'
import Colors from '../constants/Colors'
import GlobalStyle, {
	formStyle,
	largeTextSize,
	mediumTextSize,
	mlTextSize,
	smallTextSize,
	xlTextSize,
} from '../constants/GlobalStyle'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../state/reducer'
import { Text } from './Themed'
import { alertResponseText } from './Alerts/Alerts'
import Banner from './Banner'
import { convertHours } from '../helpers/prepTimeFunctions'
import { utcToZonedTime } from 'date-fns-tz'

export function PickupPointModal({ visible, setVisible, onChange = false }) {
	const {
		selectedPickupPoint,
		setSelectedPickupPoint,
		allowOrdersOutsideOrgHours,
		deliveryEnabled,
		isDelivery,
	} = useContext(DataContext)

	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)

	const localTimezone = useSelector<ReduxStoreState, ReduxStoreState['localTimezone']>(
		state => state.localTimezone
	)

	const [showError, setShowError] = useState(false)

	const orgHours = loadedData[global.org].hours ?? null

	const currentTime = utcToZonedTime(new Date(), localTimezone)
	const currentDay = currentTime.getDay()
	const currentHour = currentTime.getHours()
	const currentMinute = currentTime.getMinutes()

	const fractionalToMinutes = fractionalHour => {
		const hours = Math.floor(fractionalHour)
		const minutes = Math.round((fractionalHour - hours) * 60)
		return hours * 60 + minutes
	}

	const currentMinutes = currentHour * 60 + currentMinute

	const orgHoursToday = orgHours ? convertHours(orgHours, 0)[currentDay] : null

	const isWithinOrgHours = orgHoursToday
		? orgHoursToday.startTimes.some((start, i) => {
				const startMinutes = fractionalToMinutes(start)
				const endMinutes = fractionalToMinutes(orgHoursToday.endTimes[i])
				return currentMinutes >= startMinutes && currentMinutes < endMinutes
		  })
		: true

	const shouldRestrictToStorePickup = allowOrdersOutsideOrgHours && !isWithinOrgHours

	const handleConfirm = () => {
		if (selectedPickupPoint !== null) {
			setVisible(false)
		} else {
			setShowError(true)
		}
	}

	const handleCancel = () => {
		if (selectedPickupPoint !== null) {
			setVisible(false)
		} else {
			setShowError(true)
		}
	}

	const PickupPointCard = ({ location }) => {
		let addressSpace = ''
		const address = location.address
		let formattedAddressLine = ''
		let formattedCityLine = ''
		if (address) {
			if (address.addressLine2 && address.addressLine2 !== '') {
				addressSpace = ' '
			}
			formattedAddressLine = `${address.addressLine1}${
				address.addressLine2 ? ' ' + address.addressLine2 : ''
			}`
			formattedCityLine = `${address.city}, ${address.provinceState} ${address.postCode}`
		}
		return (
			<View style={styles.container}>
				<TouchableOpacity
					onPress={() => {
						setShowError(false)
						setSelectedPickupPoint(location)
					}}
					style={styles.row}
					activeOpacity={0.7}
				>
					<MaterialCommunityIcons
						name={
							selectedPickupPoint !== null && selectedPickupPoint.id === location.id
								? 'checkbox-marked-circle-outline'
								: 'checkbox-blank-circle-outline'
						}
						size={mediumTextSize * 1.25}
						color={global.orgColor}
						style={styles.icon}
					/>

					<View
						style={[
							styles.textContainer,
							{
								marginVertical: 5,
								height:
									formattedAddressLine && formattedCityLine
										? mediumTextSize * 1.25 * 2
										: mediumTextSize * 1.25,
							},
						]}
					>
						<Text style={styles.boldText}>{location.name}</Text>
						<Text style={styles.normalText}>
							{formattedAddressLine && formattedCityLine
								? formattedAddressLine + ', ' + formattedCityLine
								: ''}
						</Text>
					</View>
				</TouchableOpacity>
			</View>
		)
	}

	useEffect(() => {
		if (loadedData['pickupPoints'].length === 0 || shouldRestrictToStorePickup) {
			setSelectedPickupPoint({ id: 'store_pickup', name: 'Pickup From Store', location: null })
		}
	}, [])

	return (
		<Modal
			animationType="fade"
			transparent={true}
			visible={
				onChange === false &&
				(loadedData['pickupPoints'].length === 0 ||
					shouldRestrictToStorePickup ||
					(deliveryEnabled && isDelivery))
					? false
					: visible
			}
			onRequestClose={() => {
				setVisible(false)
			}}
		>
			<View style={styles.centeredView}>
				<View style={styles.modalView}>
					<Text style={[formStyle.headerText, { marginVertical: 20, fontSize: largeTextSize }]}>
						SELECT YOUR PICKUP POINT
					</Text>

					{showError && (
						<View style={{ width: '93%', marginTop: -20, marginBottom: 20 }}>
							<Banner text="Please select a pickup point to continue." />
						</View>
					)}

					<View style={styles.separator} />

					<ScrollView
						style={{
							maxHeight: Dimensions.get('window').height * 0.5,
							width: '100%',
							alignSelf: 'center',
						}}
					>
						{!shouldRestrictToStorePickup &&
							loadedData['pickupPoints'].map((location, i) => {
								return (
									<View key={'location_' + i}>
										<PickupPointCard key={i} location={location} />
										<View style={styles.separator} />
									</View>
								)
							})}

						<View key={'location_store_pickup'}>
							<PickupPointCard
								key={'store_pickup'}
								location={{ id: 'store_pickup', name: 'Pickup From Store', location: null }}
							/>
						</View>
					</ScrollView>
					<View style={styles.separator} />

					<View style={styles.buttonRow}>
						<TouchableOpacity
							style={{ ...styles.button, backgroundColor: Colors.greyscale[5] }}
							onPress={handleCancel}
						>
							<Text style={styles.buttonText}>Cancel</Text>
						</TouchableOpacity>
						<TouchableOpacity
							style={{ ...styles.button, backgroundColor: global.orgColor }}
							onPress={handleConfirm}
						>
							<Text style={styles.buttonText}>Confirm</Text>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		</Modal>
	)
}

const styles = StyleSheet.create({
	container: {
		backgroundColor: 'white',
	},
	row: {
		flexDirection: 'row',
		alignItems: 'center',
		padding: 15,
		justifyContent: 'center',
	},
	icon: {
		width: '7.5%',
	},
	textContainer: {
		width: '90%',
		height: mediumTextSize * 1.25,
	},
	boldText: {
		fontSize: mediumTextSize,
		fontWeight: 'bold',
		color: 'black',
	},
	normalText: {
		fontSize: mediumTextSize,
		color: 'grey',
		marginTop: 5,
	},
	openUntilText: {
		marginLeft: 5,
		paddingRight: 5,
		alignSelf: 'flex-start',
		fontSize: smallTextSize,
		width: '40%',
		textAlign: 'right',
	},
	separator: {
		height: 1,
		backgroundColor: Colors.greyscale[4],
		width: '100%',
	},

	centeredView: {
		flex: 1,
		justifyContent: 'center',
		paddingTop: 22,
		paddingHorizontal: '5%',
		backgroundColor: 'rgba(0,0,0,0.5)',
		alignItems: 'center',
	},
	modalView: {
		backgroundColor: 'white',
		borderRadius: 20,
		alignItems: 'center',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
		maxWidth: 600,
		width: '100%',
	},
	button: {
		borderRadius: 10,
		padding: 10,
		paddingHorizontal: 25,
	},
	buttonText: {
		fontSize: xlTextSize,
		color: 'white',
		fontWeight: '500',
		textAlign: 'center',
	},
	buttonRow: {
		paddingHorizontal: 20,
		paddingVertical: 15,
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
	},
})
