import React, { useEffect, useState } from 'react'
import { Platform } from 'react-native'
import { RootTabScreenProps } from '../../navigation/types'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxStoreState } from '../../state/reducer'
import { useContext } from 'react'
import { auth } from '../../firebase/config'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { priceSetter } from './Helpers/priceFunctions'
import { setPickupMessage, setPrepTimeString } from '../../state/actions'
import { Elements } from '@stripe/react-stripe-js'
import { parsePrepTime } from '../../helpers/prepTimeFunctions'
import { add, intervalToDuration } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { restaurantDiscountPercentages } from './Helpers/checkCouponCode'
import { CheckoutScreenContext, DataContext, IdleContext } from '../../state/context'
import useCheckout from './Hooks/useCheckout'
import { CheckoutView } from './Components/UI'
import useStatusUpdate from './Hooks/useStatusUpdate'
import useIdleReset from './Hooks/useIdleReset'
import useDataRefresh from './Hooks/useDataRefresh'
import usePickupTimeCheck from './Hooks/usePickupTimeCheck'
import { logBeginCheckoutAnalytics } from '../../firebase/analytics'
import useFetchCards from './Hooks/useFetchCards'
import { useSquareCheckout } from '../../components/Square/Hooks/useSquareCheckout'
import {
	CheckDeliveryEligibilityParams,
	calculateAdditionalAmountForDelivery,
} from '../../helpers/getDeliveryMinimum'
import Formatter from '../../helpers/Formatter'

export default function CheckoutScreen({ navigation }: RootTabScreenProps<'TabCheckout'>) {
	const prepTimeRedux = useSelector<ReduxStoreState, ReduxStoreState['prepTime']>(
		state => state.prepTime
	)
	const serviceFee = useSelector<ReduxStoreState, ReduxStoreState['serviceFee']>(
		state => state.serviceFee
	)
	const serviceFeeTax = useSelector<ReduxStoreState, ReduxStoreState['serviceFeeTax']>(
		state => state.serviceFeeTax
	)
	const localTimezone = useSelector<ReduxStoreState, ReduxStoreState['localTimezone']>(
		state => state.localTimezone
	)
	const cart = useSelector<ReduxStoreState, ReduxStoreState['items']>(state => state.items)
	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)

	const schedCurrTime = useSelector<ReduxStoreState, ReduxStoreState['schedCurrTime']>(
		state => state.schedCurrTime
	)
	const schedDuration = useSelector<ReduxStoreState, ReduxStoreState['schedDuration']>(
		state => state.schedDuration
	)

	const {
		items,
		setItems,
		setOrderNote,
		setAllowOrder,
		couponCode,
		setCouponCode,
		setCouponCodeDisplay,
		setCouponCodePricing,
		userRedeemedCodes,
		setUserRedeemedCodes,
		userAvailableCodes,
		setUserAvailableCodes,
		setPhoneNumber,
		setPhoneNumberInput,
		setCouponCodeBackup,
		setRestaurantDiscountAmount,
		setOrderCreated,
		isFreeItemDiscount,
		setShowDineInOrTakeout,
		setTableNumber,
		setTakeOutName,
		setCouponRefresh,
		prices,
		setPrices,
		setTipCount,
		isConfirmModalVisible,
		setConfirmModalVisible,
		isPromoModalVisible,
		setPromoModalVisible,
		setIsGuestCheckout,
		setShowNotes,
		validCouponCodes,
		setIsTaxesExpanded,
		setShowPOSMethods,
		setTypePOS,
		bagFee,
		bagFeeSelected,
		setSelectedMethods,
		setCouponName,
		isPaymentMethodModalVisible,
		setPaymentMethodModalVisible,
		setIsAddingCard,
		setSelectedCard,
		setShowAddTip,
		setUseNewPaymentMethod,
		setSelectedTip,
		analyticsItems,
		posLoyaltyPoints,
		setPOSLoyaltyPoints,
		setShowKioskLoyaltyEntry,
		savedPhone,
		setDeliveryAmountToSpend,
	} = useContext(CheckoutScreenContext)

	const {
		defPrep,
		deliveryEstimate,
		isDelivery,
		deliveryMinSpend,
		freeDeliveryMultiplier,
		posLoyaltyEnabled,
		setPointsEarned,
		setRedeemedRewards,
		setShowKioskStartScreen,
		setIsCardValid,
		deliveryEnabled,
		deliveryEstimateList,
	} = useContext(DataContext)

	let deliveryEstimateForRestaurant: any = deliveryEstimate

	if (
		deliveryEstimateList.length > 0 &&
		cart.length > 0 &&
		!loadedData[global.org].singleBrandEnabled
	) {
		const estimate = deliveryEstimateList.find(est => est.restaurantId === cart[0].rId)
		if (estimate) {
			deliveryEstimateForRestaurant = estimate
		}
	}

	const [prepTime, setPrepTime] = useState(prepTimeRedux)

	let { tipCount } = useContext(CheckoutScreenContext)

	const { setDisableIdleTimer } = useContext(IdleContext)

	useCheckout({ navigation }) //load checkout items & data

	useFetchCards()

	useStatusUpdate() //update order status for kiosk

	useSquareCheckout() // initialize square sdk

	useIdleReset({ goHome }) //checks for return to home after order confirmed modal

	useDataRefresh({ navigation, priceSet })

	const dispatch = useDispatch()

	var tipFuncData = {
		tip0: () => [tipCalc(0), priceSet(couponCode), setSelectedTip(0)],
		tip5: () => [tipCalc(5), priceSet(couponCode), setSelectedTip(5)],
		tip10: () => [tipCalc(10), priceSet(couponCode), setSelectedTip(10)],
		tip15: () => [tipCalc(15), priceSet(couponCode), setSelectedTip(15)],
		tip18: () => [tipCalc(18), priceSet(couponCode), setSelectedTip(18)],
		tip20: () => [tipCalc(20), priceSet(couponCode), setSelectedTip(20)],
		setCustomTip: amount => [tipAmount(amount), priceSet(couponCode), setSelectedTip(9999)],
		closeModal: () => [toggleConfirmModal(), setAllowOrder(true)],
	}
	var promoFuncData = {
		closeModal: () => [togglePromoModal(), setAllowOrder(true), setCouponCodeDisplay('')],
		setPromo: value => [priceSet(value)],
		toggleModal: () => togglePromoModal(),
	}

	const toggleConfirmModal = () => {
		const email = auth.currentUser ? Formatter.decodedEmail(auth.currentUser.email) : ''
		let phone = savedPhone
		if (savedPhone.length === 11 && savedPhone.startsWith('1')) {
			phone = savedPhone.substring(1)
		}
		if (!isConfirmModalVisible) {
			logBeginCheckoutAnalytics(prices.total, analyticsItems)
		}
		setSelectedMethods({
			textMessage: false,
			email: false,
			print: false,
			textUpdates: true,
			showNumPad: false,
			phoneString: global.kiosk ? '' : phone,
			emailString: global.kiosk ? '' : email,
		})
		//setIsCardValid(false)
		setDisableIdleTimer(!isConfirmModalVisible)
		setShowPOSMethods(global.pos)
		setTypePOS('')
		setShowDineInOrTakeout(true)
		//setIsDineIn(false)
		//setIsTakeOut(true)
		setTableNumber(null)
		// setTakeOutName(null) // to save the name for guest checkout
		setPhoneNumberInput(false)
		setShowAddTip(!global.kiosk)
		setUseNewPaymentMethod(false)
		setShowKioskLoyaltyEntry(posLoyaltyEnabled)
		if (auth.currentUser || (Platform.OS === 'web' && (!deliveryEnabled || !isDelivery))) {
			setConfirmModalVisible(!isConfirmModalVisible)
		} else {
			navigation.navigate('AccountStack', { screen: 'Account', params: { onCheckout: true } })
		}
		setIsGuestCheckout(false)
	}

	const togglePromoModal = () => {
		setCouponCodeDisplay('')
		if (!isPromoModalVisible) {
			setCouponRefresh([])
		}
		setPromoModalVisible(!isPromoModalVisible)
	}

	const togglePaymentMethodModal = () => {
		//setIsCardValid(false)
		setSelectedCard(null)
		setIsAddingCard(false)
		setPaymentMethodModalVisible(!isPaymentMethodModalVisible)
	}

	usePickupTimeCheck({ prepTime, setPrepTime, navigation })

	function priceSet(code) {
		if (auth.currentUser) {
			const getUserCoupons = httpsCallable(functions, 'getUserCoupons')

			getUserCoupons({
				orgId: global.org,
				userId: auth.currentUser.uid,
			}).then((res: { data: any }) => {
				setUserAvailableCodes(res.data.available)
				setUserRedeemedCodes(res.data.redeemed)
			})
		}

		let couponVals = []
		if (validCouponCodes !== undefined) {
			couponVals = Object.values(validCouponCodes)
		}
		const promo = couponVals.filter(
			e => e.couponCode.toLowerCase().trim() === code.toLowerCase().trim()
		)
		if (promo[0] && promo[0].type === 'BOGO') {
			var token = promo[0].itemIds[0]
			setCouponCode('')
			setCouponCodeDisplay('')

			const itemInCart = cart.filter(c => c.id === token && c.qty > 1)

			if (itemInCart.length <= 0) {
				togglePromoModal()
				navigation.navigate('Item', {
					token: token,
					editing: null,
					isOpen: true,
					menuId: global.menuId,
					menuItemCategory: 'BOGO',
					appliedCoupon: promo[0],
					category: 'BOGO',
				})
			} else {
				itemInCart[0].appliedCoupon = promo[0]
				togglePromoModal()
				navigation.navigate('restaurant')
				setTimeout(() => {
					navigation.navigate('TabCheckout')
				}, 100)
			}
		} else if (promo[0] && promo[0].type === 'freeItem') {
			if (promo[0].minSpend <= prices.subTotal) {
				var token = promo[0].freeItemIds[0]
				setCouponCode('')
				setCouponCodeDisplay('')
				const itemInCart = cart.filter(c => c.id === token)
				if (itemInCart.length <= 0) {
					togglePromoModal()
					navigation.navigate('Item', {
						token: token,
						editing: null,
						isOpen: true,
						menuId: global.menuId,
						menuItemCategory: 'Free Item',
						appliedCoupon: promo[0],
						category: 'Free Item',
					})
				} else {
					itemInCart[0].appliedCoupon = promo[0]
					togglePromoModal()
					navigation.navigate('restaurant')
					setTimeout(() => {
						navigation.navigate('TabCheckout')
					}, 100)
				}
			} else {
				setCouponCodeDisplay('Must spend $' + promo[0].minSpend + ' to get free item.')
			}
		} else {
			setCouponCodeBackup(code.trim().toLowerCase())
			setCouponCode(code.trim().toLowerCase())
			setCouponName(promo[0] ? promo[0].name : '')
			const i = couponVals
				.map(function (e) {
					return e.couponCode.trim().toLowerCase()
				})
				.indexOf(code.trim().toLowerCase())

			setRestaurantDiscountAmount(restaurantDiscountPercentages(couponVals[i], items))

			const freeDeliveryPrice =
				deliveryEstimateForRestaurant && deliveryEstimateForRestaurant.estimate
					? Math.round(
							deliveryEstimateForRestaurant.estimate.fee * freeDeliveryMultiplier + deliveryMinSpend
					  ) / 100
					: -1

			return priceSetter({
				code: code.trim().toLowerCase(),
				couponVals: couponVals,
				userAvailableCodes: userAvailableCodes,
				userRedeemedCodes: userRedeemedCodes,
				prices: prices,
				items: items,
				tipCount: tipCount,
				serviceFee: serviceFee,
				serviceFeeTax: serviceFeeTax,
				setCouponCodePricing: setCouponCodePricing,
				setCouponCodeDisplay: setCouponCodeDisplay,
				setPrices: setPrices,
				couponCode: couponCode,
				loadedData: loadedData,
				cart: cart,
				isFreeItemDiscount: isFreeItemDiscount,
				couponInfo: promo,
				bagFee: bagFeeSelected ? bagFee : null,
				isBankersRounding: loadedData[global.org].taxRoundingMethod === 'bankers' || false,
				deliveryFee:
					isDelivery && deliveryEstimateForRestaurant && deliveryEstimateForRestaurant.estimate
						? deliveryEstimateForRestaurant.estimate.fee
						: 0,
				deliveryFeeOrgCoverage:
					isDelivery && deliveryEstimateForRestaurant && deliveryEstimateForRestaurant.estimate
						? deliveryEstimateForRestaurant.estimate.totalFee -
						  deliveryEstimateForRestaurant.estimate.fee
						: 0,
				freeDeliveryPrice: freeDeliveryPrice,
			})
		}
	}

	const tipCalc = x => {
		tipCount = 0.01 * x * (prices.subTotal + prices.discount)
		setTipCount(tipCount)
	}

	const tipAmount = x => {
		tipCount = x
		setTipCount(tipCount)
	}

	function getSchedInterval(isCheck) {
		var prepReturn = ''
		var valid = true
		if (schedDuration !== '' && schedDuration !== undefined) {
			var parsed = parsePrepTime(schedDuration)
			var pickupTime = add(new Date(schedCurrTime), parsed)

			if (utcToZonedTime(new Date(), localTimezone) >= pickupTime) {
				valid = false
			} else {
				var adjustedPickupDuration = intervalToDuration({
					start: utcToZonedTime(new Date(), localTimezone),
					end: pickupTime,
				})

				prepReturn =
					'P' +
					adjustedPickupDuration.days +
					'DT' +
					adjustedPickupDuration.hours +
					'H' +
					adjustedPickupDuration.minutes +
					'M' +
					adjustedPickupDuration.seconds +
					'S'
			}
		} else {
			prepReturn = 'P0DT0H' + defPrep + 'M0S'
		}

		return isCheck ? valid : prepReturn
	}
	function goHome(isAfterCheckout) {
		//goHome is used when both the last item of the cart is deleted,
		//and after a user gets their order confirmation
		//only run these after checkout:
		if (isAfterCheckout) {
			toggleConfirmModal()
			dispatch(setPickupMessage(''))
			dispatch(setPrepTimeString(''))
		}
		setCouponCode('')
		setOrderNote('')
		setTipCount(0)
		setShowNotes(false)
		setIsTaxesExpanded(false)
		setPhoneNumber('')
		setIsGuestCheckout(false)
		setOrderCreated(false)
		setCouponCode('')
		setCouponCodeBackup('')
		setCouponCodeDisplay('')
		setRedeemedRewards([])
		setPointsEarned(0)
		setShowKioskStartScreen(true)
		setShowKioskLoyaltyEntry(posLoyaltyEnabled)
		setIsCardValid(false)
		while (cart.length > 0) {
			cart.pop()
		}
		setItems([])
		if (Platform.OS === 'web') {
			navigation.replace('HomeStack', { screen: 'RestaurantSelect' })
		} else {
			navigation.reset({
				index: 0,
				routes: [{ name: 'HomeStack' }],
			})
		}
	}
	const functions = getFunctions()

	const retrieveSquareLoyaltyPointEstimate = httpsCallable(
		functions,
		'retrieveSquareLoyaltyPointEstimate'
	)
	useEffect(() => {
		async function fetchData() {
			const pointTotal = await retrieveSquareLoyaltyPointEstimate({
				orgId: global.org,
				restaurantId: cart.length > 0 ? cart[0].rId : '',
				orderTotal: prices.subTotal,
			})

			setPOSLoyaltyPoints(pointTotal.data)
		}
		if (prices.total > 0 && loadedData[global.org].posLoyaltyEnabled) {
			setShowKioskLoyaltyEntry(true)
			fetchData()
		}
		const params: CheckDeliveryEligibilityParams = {
			totalOrderPrice: Math.round(prices.total * 100),
			subtotalOrderPrice: Math.round(prices.subTotal * 100),
			totalDeliveryFee: prices.deliveryFee + prices.deliveryFeeOrgCoverage,
			minimumDeliveryOrderAmount: deliveryMinSpend,
		}

		const additionalAmountNeeded = calculateAdditionalAmountForDelivery(params)
		setDeliveryAmountToSpend(additionalAmountNeeded)

		//console.log(`Additional amount needed for delivery: $${additionalAmountNeeded}`)
	}, [prices])

	return Platform.OS === 'web' ? (
		<Elements stripe={global.stripePromise}>
			{
				<CheckoutView
					navigation={navigation}
					priceSet={priceSet}
					goHome={goHome}
					getSchedInterval={getSchedInterval}
					tipFuncData={tipFuncData}
					promoFuncData={promoFuncData}
					togglePromoModal={togglePromoModal}
					toggleConfirmModal={toggleConfirmModal}
					togglePaymentMethodModal={togglePaymentMethodModal}
				/>
			}
		</Elements>
	) : (
		<CheckoutView
			navigation={navigation}
			priceSet={priceSet}
			goHome={goHome}
			getSchedInterval={getSchedInterval}
			tipFuncData={tipFuncData}
			promoFuncData={promoFuncData}
			togglePromoModal={togglePromoModal}
			toggleConfirmModal={toggleConfirmModal}
			togglePaymentMethodModal={togglePaymentMethodModal}
		/>
	)
}
