import React, { Component } from 'react'
import { View, Platform } from 'react-native'
import Accordion from 'react-native-collapsible/Accordion'
import PropTypes from 'prop-types'
import GlobalStyle from '../../constants/GlobalStyle'
import { utcToZonedTime, formatInTimeZone } from 'date-fns-tz'
import { Feather } from '@expo/vector-icons'
import { styles } from '../../screens/Menu/Helpers/style'
import { Text } from '../Themed'
import { convertHours } from '../../helpers/prepTimeFunctions'

function decToTime(num) {
	let decimal = ''
	if (num >= 10) {
		decimal = (num + '').slice(2)
	} else {
		decimal = (num + '').slice(1)
	}
	let val = Math.round(parseFloat(decimal) * 60)
	let decReturn = decimal ? val : '00'
	if (decReturn === 60) {
		decReturn = 0
		if (num === 12) {
			num = 1
		} else {
			num++
		}
	}
	return Math.trunc(num) + ':' + ('0' + decReturn).slice(-2)
}

export function hoursLayout(i, openHours, closeHours) {
	let open = openHours[i]

	let close = closeHours[i]

	let openZone = ''
	let closeZone = ''
	let isClosed = false

	if (open === 0 && close === 0) {
		open = ''
		openZone = ''
		close = ''
		closeZone = 'Closed'
		isClosed = true
	} else {
		if (open % 12 < 0.5) {
			if (Math.trunc(open) == 0) {
				openZone = ' AM'
			} else {
				openZone = ' PM'
			}
			open = 12 + (open % 12)
		} else if (open < 12) {
			open = open % 12
			openZone = ' AM'
		} else {
			open = open % 12
			openZone = ' PM'
		}

		if (close % 12 < 0.5) {
			if (Math.trunc(close) == 0 || close === 24) {
				closeZone = ' AM'
			} else {
				closeZone = ' PM'
			}
			close = 12 + (close % 12)
		} else if (close < 12) {
			close = close % 12
			closeZone = ' AM'
		} else {
			close = close % 12
			closeZone = ' PM'
		}
	}

	let closeTime = decToTime(close)

	let openTime = decToTime(open)
	return {
		open: open,
		close: close,
		openFormatted: openTime,
		closeFormatted: closeTime,
		openZone: openZone,
		closeZone: closeZone,
		isClosed: isClosed,
	}
}

function getCurrentDay(day, prepDays) {
	var currDay = day + prepDays
	if (currDay > 6) {
		currDay -= 7
	}
	return currDay
}

export function getDBHours(
	orgHours,
	hours,
	timezone,
	prepTime,
	isScheduled,
	isSnoozed,
	item = false,
	allowOutsideOrgHours
) {
	let parsedPrepTime = prepTime
	let prepHours = parsedPrepTime === null ? 0 : !global.kiosk ? parseInt(parsedPrepTime.hours) : 0
	let prepMinutes =
		parsedPrepTime === null ? 0 : !global.kiosk ? parseInt(parsedPrepTime.minutes) : 0
	let prepDays = parsedPrepTime === null ? 0 : parseInt(parsedPrepTime.days)
	const d = utcToZonedTime(new Date(), timezone)

	//add prep time to current time and convert to decimal (for comparison)
	let curr_prep_hours = d.getHours()
	let curr_prep_mins = d.getMinutes()

	if (prepMinutes > 0) {
		curr_prep_mins += prepMinutes
	}

	if (curr_prep_mins >= 60) {
		let x = Math.floor(curr_prep_mins / 60)
		curr_prep_mins -= x * 60
		curr_prep_hours += x
	}

	if (prepHours > 0) {
		curr_prep_hours += prepHours
	}

	if (curr_prep_hours >= 24) {
		let y = Math.floor(curr_prep_hours / 24)
		curr_prep_hours -= y * 24
		prepDays += y
	}

	//current time:
	//open (doesn't add for prep time) vs closing (adds for prep time)

	var closingMins = curr_prep_mins /*+ prepMinutes*/ //enable if you want restaurant to close at closingHr - prepTime
	var openMins = curr_prep_mins

	var openHours = curr_prep_hours
	var closingHours = curr_prep_hours

	var openTotalMins = 0
	var closingTotalMins = 0

	if (openMins > 60) {
		openHours += Math.trunc(openMins / 60)

		openTotalMins = openMins % 60
	} else {
		openTotalMins = openMins
	}

	if (closingMins > 60) {
		closingHours += Math.trunc(closingMins / 60)

		closingTotalMins = closingMins % 60
	} else {
		closingTotalMins = closingMins
	}

	var isOpen = true
	const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

	let hoursFormatted = []
	const date = new Date()
	//'EEEE, MMMM do, hh:mm a zzz'

	let curr_hour_no_tz = new Date().getHours()
	let show_timezone = ''

	timezone = formatInTimeZone(new Date(), timezone, ' zzz')

	let curr_hour = curr_prep_hours
	let curr_min = curr_prep_mins
	let decString = (curr_min / 60 + '').slice(2)
	let curr_time = curr_hour + '.' + decString

	let text = ''
	let curr_day = d.getDay()
	let formattedHours = {}

	//if user in different timezone, show timezone abbreviation (i.e. "EST")
	if (curr_hour_no_tz !== curr_hour) {
		show_timezone = timezone
	}

	//convert current time to decimal i.e. 2:30 = 14.5
	let currentDay = getCurrentDay(curr_day, prepDays)

	let storeHours = Object.values(hours) //all hours where storeHours[0] = sunday start/end times

	var keepLooping = true

	// Check for 24/7 open case
	const isEmptyHoursObj = Object.values(hours).every(
		day =>
			Object.keys(day).length === 0 || (day.startTimes?.length === 0 && day.endTimes?.length === 0)
	)

	const is24_7 = storeHours.every(
		day =>
			day.startTimes?.length === 1 &&
			day.endTimes?.length === 1 &&
			day.startTimes[0] === 0 &&
			day.endTimes[0] === 24
	)

	if (isEmptyHoursObj || is24_7) {
		if (orgHours === null || allowOutsideOrgHours) {
			return {
				allHours: days.map(day => ({
					dayName: day,
					open: '12:00AM',
					close: '12:00AM',
					isClosed: false,
				})),
				todayHours: 'Open 24/7',
				isOpen: true,
			}
		} else {
			hours = convertHours(orgHours, 0)
		}
	}

	const capToOrgHours = (key, startTimes, endTimes) => {
		if (orgHours !== null && !allowOutsideOrgHours) {
			const orgHoursObj = convertHours(orgHours, 0)

			let orgStart = orgHoursObj[key].startTimes[0]
			let orgEnd = orgHoursObj[key].endTimes[0]
			if (orgStart === undefined) {
				orgStart = startTimes
			}
			if (orgEnd === undefined) {
				orgEnd = endTimes
			}
			// If the restaurant hours fall entirely outside the org hours, set to 0, 0
			const cappedStartTimes = startTimes.map(start => Math.max(start, orgStart))
			const cappedEndTimes = endTimes.map(end => Math.min(end, orgEnd))

			const cappedHours = cappedStartTimes.map((start, i) => ({
				start,
				end: cappedEndTimes[i],
			}))

			const isOutsideOrgHours = cappedHours.every(({ start, end }) => start >= end)

			return {
				startTimes: isOutsideOrgHours ? [0] : cappedStartTimes,
				endTimes: isOutsideOrgHours ? [0] : cappedEndTimes,
			}
		} else {
			return { startTimes, endTimes }
		}
	}

	//object with key for each day of week
	//value of each key contains an object with startTimes / endTimes
	for (const [key, value] of Object.entries(hours)) {
		//key = 0 -> 6 (day of week)
		//value = {startTimes:[], endTimes: []}
		//times are in number format i.e. 3:45 = 15.75
		let { startTimes, endTimes } = capToOrgHours(key, value.startTimes ?? [], value.endTimes ?? [])
		startTimes.forEach((time, i) => {
			// if (startTimes[i] <= currentTime && endTimes[i] >= currentTime) {
			let allHours = hoursLayout(i, startTimes, endTimes)
			if (allHours.isClosed) {
				formattedHours = {
					dayName: days[parseInt(key)],
					isClosed: allHours.isClosed,
				}
			} else {
				formattedHours = {
					dayName: days[parseInt(key)],
					open: allHours.openFormatted + allHours.openZone,
					close: allHours.closeFormatted + allHours.closeZone,
					isClosed: allHours.isClosed,
				}
			}
			hoursFormatted.push(formattedHours)

			if (curr_time >= endTimes[i] && parseInt(key) === currentDay) {
				//store already closed for the day
				isOpen = false
				var iStart = currentDay + 1
				if (currentDay === 6) {
					iStart = 0
				}
				for (var j = iStart; j < storeHours.length; ++j) {
					//find next open day
					if (
						//storeHours[i].startTimes[0] != 0 &&
						storeHours[j].endTimes &&
						storeHours[j].endTimes[0] != 0
					) {
						//store not closed that day
						var hourDisplay = hoursLayout(0, storeHours[j].startTimes, storeHours[j].endTimes)
						if (j === currentDay + 1) {
							text =
								'Currently closed. Opens tomorrow at ' +
								hourDisplay.openFormatted +
								hourDisplay.openZone +
								show_timezone
						} else {
							text =
								'Currently closed. Opens ' +
								days[j] +
								' at ' +
								hourDisplay.openFormatted +
								hourDisplay.openZone +
								show_timezone
						}
						break
					} else if (j === 6) {
						break
					}
				}
			} else if (parseInt(key) === currentDay) {
				//curr day
				const subtractPrepTime = !isScheduled ? prepMinutes / 60 : 0

				if (curr_time < endTimes[i] && parseFloat(curr_time) - subtractPrepTime >= startTimes[i]) {
					const startText = isSnoozed ? 'Open until ' : 'Open now until '
					keepLooping = false
					isOpen = true
					//currently open
					text =
						startText +
						hoursLayout(i, startTimes, endTimes).closeFormatted +
						hoursLayout(i, startTimes, endTimes).closeZone +
						show_timezone
				} else if (parseFloat(curr_time) - subtractPrepTime < startTimes[i] && keepLooping) {
					//opening later today
					keepLooping = false
					isOpen = false

					text =
						'Currently closed. Opens today at ' +
						hoursLayout(i, startTimes, endTimes).openFormatted +
						hoursLayout(i, startTimes, endTimes).openZone +
						show_timezone
				}
			}
		})

		//isOpen = false;
	}
	return {
		allHours: hoursFormatted,
		todayHours: text,
		isOpen: item ? isOpen : isSnoozed ? false : global.kiosk ? true : isOpen,
	}
}

function displayAccordion(allContent) {
	var returnText = []
	allContent.forEach((content, i) => {
		if (content != undefined) {
			if (content.isClosed) {
				returnText.push(
					<View
						style={{ flexDirection: 'row', flex: 1, paddingVertical: 4 }}
						key={`${content}${i}`}
					>
						<View style={{ width: 100 }}>
							<Text style={{ fontWeight: 'bold', flex: 2 }}>{content.dayName}:</Text>
						</View>
						<Text style={[GlobalStyle.text, { fontWeight: '400', paddingRight: 20 }]}>Closed</Text>
					</View>
				)
			} else {
				returnText.push(
					<View
						style={{ flexDirection: 'row', flex: 1, paddingVertical: 4 }}
						key={`${content}${i}`}
					>
						<View style={{ width: 100 }}>
							<Text style={{ fontWeight: 'bold', flex: 2 }}>{content.dayName}:</Text>
						</View>
						<Text style={[GlobalStyle.text, { fontWeight: '400', paddingRight: 20 }]}>
							{content.open} - {content.close}
						</Text>
					</View>
				)
			}
		}
	})
	return returnText
}

var count = 0
export default class AccordionView extends Component {
	constructor(props) {
		super(props)
	}
	static propTypes = {
		hours: PropTypes.any,
		timezone: PropTypes.any,
		isSnoozed: PropTypes.any,
		extendable: PropTypes.any,
		prepTime: PropTypes.any,
		isScheduled: PropTypes.bool,
		allowOutsideOrgHours: PropTypes.bool,
	}
	state = {
		SECTIONS: [
			{
				title: getDBHours(
					this.props.orgHours,
					this.props.hours,
					this.props.timezone,
					this.props.prepTime,
					this.props.isScheduled,
					this.props.isSnoozed,
					false,
					this.props.allowOutsideOrgHours
				).todayHours,
				content: getDBHours(
					this.props.orgHours,
					this.props.hours,
					this.props.timezone,
					this.props.prepTime,
					this.props.isScheduled,
					this.props.isSnoozed,
					false,
					this.props.allowOutsideOrgHours
				).allHours,
			},
		],
		activeSections: [],
	}

	_renderHeader = (section, isActive) => {
		if (section.title !== '') {
			if (global.kiosk) {
				return (
					<View style={styles.kioskStoreInfoItem}>
						<View style={{ marginRight: 10 }}>
							<Feather name="clock" size={26} color="black" />
						</View>

						<Text
							style={{
								paddingTop: 3,
								fontWeight: global.kiosk ? '400' : 'bold',
								backgroundColor: isActive ? 'rgba(239,133,91,1)' : 'rgba(255,255,255,1)',
							}}
						>
							{this.props.isSnoozed ? 'Not accepting new orders' : section.title}
						</Text>
					</View>
				)
			} else {
				return (
					<View style={[styles.storeInfoItem, { display: global.hideHours ? 'none' : null }]}>
						<Feather name="clock" size={26} color={global.orgColor} style={styles.storeInfoIcon} />
						<View style={[styles.storeInfoText, { marginTop: Platform.OS === 'web' ? -2 : 0 }]}>
							<Text
								style={{
									fontWeight: 'bold',
									backgroundColor: isActive ? 'rgba(239,133,91,1)' : 'rgba(255,255,255,1)',
								}}
							>
								{section.title}
							</Text>
						</View>
					</View>
				)
			}
		} else {
			return <></>
		}
	}

	_renderContent = section => {
		return (
			<View
				style={{
					marginTop: 5,
				}}
			>
				{displayAccordion(section.content)}
			</View>
		)
	}

	_updateSections = activeSections => {
		//hours list is extended
		if (this.props.extendable) {
			if (count === 0) {
				global.clockDown = true
				count = 1
			} else {
				global.clockDown = false
				count = 0
			}

			this.setState({ activeSections })
		}
	}

	render() {
		return (
			<Accordion
				sections={this.state.SECTIONS}
				activeSections={this.state.activeSections}
				renderHeader={this._renderHeader}
				renderContent={this._renderContent}
				onChange={this._updateSections}
				underlayColor={'white'}
			/>
		)
	}
}
