import React, { useContext } from 'react'
import { View, TouchableOpacity, StyleSheet, Dimensions } from 'react-native'
import Colors from '../../../constants/Colors'
import {
	MAX_CHECKOUT_WIDTH,
	mediumTextSize,
	smallTextSize,
	titleTextSize,
	xlTextSize,
	IS_LANDSCAPE,
} from '../../../constants/GlobalStyle'
import { AntDesign } from '@expo/vector-icons'
import BagFeeButton from './BagFeeButton'
import { responsiveWidth } from 'react-native-responsive-dimensions'
import { Text } from '../../../components/Themed'
import Banner from '../../../components/Banner'
import { DataContext } from '../../../state/context'

// Mobile & web -> show cart prices (not sticky)
const CartFooter = ({ prices, style = {}, showBanner = false }) => {
	const { deliveryMinSpend } = useContext(DataContext)
	function Taxes() {
		var taxes = []

		prices.taxesList.forEach(tax => {
			var index = taxes.findIndex(t => t.name === tax.name)
			if (index >= 0) {
				taxes[index] = {
					id: tax.id,
					name: tax.name,
					amount: taxes[index].amount + tax.amount,
				}
			} else {
				//doesnt exist
				taxes.push({
					id: tax.id,
					name: tax.name,
					amount: tax.amount,
				})
			}
		})
		const taxReturn = []
		taxes.forEach((tax, i) => {
			taxReturn.push(generateRow(tax.name, tax.amount / 100, true))
		})
		return (
			<>
				{taxReturn.map((tax, i) => (
					<View key={tax.name + tax.amount + '_' + i}>{tax}</View>
				))}
			</>
		)
	}

	return (
		<View
			style={[
				style,
				{
					paddingTop: '2%',
					backgroundColor: Colors.greyscale[9],
					paddingVertical: '5%',
				},
			]}
		>
			<View
				style={[
					styles.summaryContainer,
					{
						alignSelf: 'center',
						width: responsiveWidth(90),
						maxWidth: MAX_CHECKOUT_WIDTH,
						marginHorizontal: '5%',
					},
				]}
			>
				{generateRow('Subtotal', prices.subTotal + prices.noCodeDiscount)}
				{generateRow(
					'Taxes & Fees',
					prices.taxes +
						prices.serviceCharge +
						prices.bagFee +
						prices.bagFeeTax +
						prices.deliveryFee / 100
				)}
				<View style={{ marginLeft: 15, marginRight: 80, marginBottom: 5 }}>
					{prices.taxes !== 0 ? <Taxes /> : <View></View>}
					{prices.serviceCharge !== 0 &&
						generateRow(
							`Service Fee (${Math.round(
								(prices.serviceCharge / (prices.subTotal + prices.discount)) * 100
							)}%)`,
							prices.serviceCharge,
							true
						)}
					{prices.bagFee !== 0 && generateRow('Bag Fee', prices.bagFee + prices.bagFeeTax, true)}
					{prices.deliveryFee > 0 && generateRow('Delivery Fee', prices.deliveryFee / 100, true)}
				</View>
				{generateRow('Discounts', prices.discount)}
				{prices.tip > 0 && generateRow('Tip', prices.tip)}

				<View style={[styles.totalContainer, { paddingTop: '2%' }]}>
					<Text style={styles.totalText}>TOTAL DUE</Text>
					<Text style={[styles.totalText, { color: '#57B042' }]}>${prices.total.toFixed(2)}</Text>
				</View>
				{showBanner ? (
					<View style={{ marginBottom: 20 }}>
						<View style={{ marginTop: 20, backgroundColor: Colors.greyscale[7], borderRadius: 5 }}>
							<Banner
								text={`Minimum $${(deliveryMinSpend / 100).toFixed(
									2
								)} required for delivery orders.`}
								textStyle={{ color: global.orgColor }}
								style={{ backgroundColor: 'rgba(255, 255, 0, 0.25)', marginTop: 0 }}
							/>
						</View>
					</View>
				) : (
					<View style={{ marginBottom: 20 }}></View>
				)}
			</View>
		</View>
	)
}

// Kiosk / pos -> show cart prices (sticky)
// Also includes add promo code, add order notes buttons
export const KioskCartFooter = ({
	prices,
	priceSet,
	cost,
	couponCode,
	bagFeeEnabled,
	openPromo,
	setShowOrderNotes,
	checkoutFunc,
	style = {},
}) => {
	function Taxes() {
		var taxes = []

		prices.taxesList.forEach(tax => {
			var index = taxes.findIndex(t => t.name === tax.name)
			if (index >= 0) {
				taxes[index] = {
					id: tax.id,
					name: tax.name,
					amount: taxes[index].amount + tax.amount,
				}
			} else {
				//doesnt exist
				taxes.push({
					id: tax.id,
					name: tax.name,
					amount: tax.amount,
				})
			}
		})
		const taxReturn = []
		taxes.forEach((tax, i) => {
			taxReturn.push(generateRow(tax.name, tax.amount / 100, true))
		})
		return <>{taxReturn}</>
	}

	const { hasInternet } = useContext(DataContext)
	return (
		<View style={[styles.container, style]}>
			<View style={styles.summaryContainer}>
				{generateRow('Subtotal', prices.subTotal + prices.noCodeDiscount)}
				{generateRow(
					'Taxes & Fees',
					prices.taxes +
						prices.serviceCharge +
						prices.bagFee +
						prices.bagFeeTax +
						prices.deliveryFee / 100
				)}
				<View style={{ marginLeft: 15, marginRight: 80, marginBottom: 5 }}>
					{prices.taxes !== 0 ? <Taxes /> : <View></View>}
					{prices.serviceCharge !== 0 &&
						generateRow(
							`Service Fee (${Math.round(
								(prices.serviceCharge / (prices.subTotal + prices.discount)) * 100
							)}%)`,
							prices.serviceCharge,
							true
						)}
					{prices.bagFee !== 0 && generateRow('Bag Fee', prices.bagFee + prices.bagFeeTax, true)}
					{prices.deliveryFee > 0 && generateRow('Delivery Fee', prices.deliveryFee / 100, true)}
				</View>
				{generateRow('Discounts', prices.discount)}
				<View style={styles.totalContainer}>
					<Text style={styles.totalText}>TOTAL DUE</Text>
					<Text style={[styles.totalText, { color: '#57B042' }]}>${prices.total.toFixed(2)}</Text>
				</View>
			</View>

			<View style={styles.buttonContainer}>
				<TouchableOpacity style={styles.button} onPress={openPromo}>
					<Text style={styles.buttonText}>ADD PROMO CODE</Text>
				</TouchableOpacity>
				<TouchableOpacity
					style={styles.button}
					onPress={() => {
						setShowOrderNotes(true)
					}}
				>
					<Text style={styles.buttonText}>ADD ORDER NOTES</Text>
				</TouchableOpacity>
				{bagFeeEnabled && <BagFeeButton cost={cost} priceSet={priceSet} couponCode={couponCode} />}
				<TouchableOpacity
					style={[styles.checkoutButton, !hasInternet && { backgroundColor: Colors.greyscale[6] }]}
					onPress={() => {
						checkoutFunc()
					}}
					disabled={!hasInternet}
				>
					<Text style={styles.checkoutButtonText}>CHECKOUT NOW</Text>
					<AntDesign name="right" size={xlTextSize} color="black" />
				</TouchableOpacity>
			</View>
		</View>
	)
}

const generateRow = (title, amount, small = false) => {
	return <Row title={title} small={small} amount={`$${amount.toFixed(2)}`} />
}

const Row = ({ title, amount, small }) => (
	<View style={small ? styles.smallRow : styles.row}>
		<Text style={small ? styles.smallTitleText : styles.titleText}>{title}</Text>
		<Text style={small ? styles.smallAmountText : styles.amountText}>{amount}</Text>
	</View>
)

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		paddingHorizontal:
			Dimensions.get('window').width > 500 ? Dimensions.get('window').width * 0.05 : '3%',
		paddingTop: IS_LANDSCAPE ? '1.5%' : '5%',
		paddingBottom: IS_LANDSCAPE ? '1.5%' : '5%',
		backgroundColor: Colors.greyscale[9],
	},
	summaryContainer: {
		flex: 11,
		justifyContent: 'space-between',
	},
	row: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginVertical: 8,
	},
	smallRow: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginVertical: 2,
	},
	titleText: {
		color: '#fff',
		fontSize: mediumTextSize,
	},
	amountText: {
		color: Colors.greyscale[2],
		fontSize: mediumTextSize,
	},
	smallTitleText: {
		color: '#fff',
		fontSize: smallTextSize,
	},
	smallAmountText: {
		color: Colors.greyscale[2],
		fontSize: smallTextSize,
	},
	totalContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginTop: 20,
		borderTopWidth: 1,
		borderTopColor: '#fff',
		paddingTop: IS_LANDSCAPE ? '3%' : '5%',
	},
	totalText: {
		textAlign: 'center',
		color: 'white',
		fontWeight: '500',
		fontSize: titleTextSize,
		letterSpacing: 1,
	},
	buttonContainer: {
		flex: Dimensions.get('window').width > 500 ? 9 : 12,
		justifyContent: 'space-between',
		marginLeft: '5%',
	},
	button: {
		borderRadius: 10,
		alignItems: 'center',
		justifyContent: 'center',
		paddingVertical: IS_LANDSCAPE ? '4%' : '7%',
		borderWidth: 1,
		borderColor: 'white',
		marginLeft: '25%',
		marginBottom: IS_LANDSCAPE ? '5%' : '10%',
	},
	buttonText: {
		color: 'white',
		fontSize: mediumTextSize,
	},
	bagFeeButton: {
		borderRadius: 10,
		alignItems: 'center',
		justifyContent: 'center',
		paddingVertical: 10,
		borderWidth: 1,
		borderColor: 'white',
		marginBottom: 10,
	},
	checkoutButton: {
		flexDirection: 'row',
		borderRadius: smallTextSize,
		alignItems: 'center',
		justifyContent: 'center',
		paddingVertical: IS_LANDSCAPE ? '5%' : '8%',
		backgroundColor: 'white',
		paddingHorizontal: 5,
	},
	checkoutButtonText: {
		marginHorizontal: 10,
		textAlign: 'center',
		color: 'black',
		fontWeight: '400',
		fontSize: xlTextSize,
	},
})

export default CartFooter
