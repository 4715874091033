import React, { useState, useEffect, useContext } from 'react'
import InternetConnectionBanner from './InternetConnectionBanner'
import { useNetInfo } from '@react-native-community/netinfo'
import { DataContext } from '../state/context'

const InternetConnection = ({ children }) => {
	const { setHasInternet, hasInternet } = useContext(DataContext)
	const { isConnected } = useNetInfo()

	useEffect(() => {
		setHasInternet(isConnected)
	}, [isConnected])

	return (
		<>
			<InternetConnectionBanner hide={hasInternet} />
			{children}
		</>
	)
}

export default InternetConnection
