import { useContext, useEffect } from 'react'
import { CheckoutScreenContext, DataContext } from '../../../state/context'
import { add } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { getDBHours } from '../../../components/ScheduleHours/RestaurantHours'
import { combineHours } from '../../../components/ScheduleHours/ScheduleOrderModal'
import {
	parsePrepTime,
	prepTimeDur,
	getLatestPrepTimeCheckout,
} from '../../../helpers/prepTimeFunctions'
import {
	setSchedCurrTime,
	setSchedDuration,
	setPickupMessage,
	setPrepTimeString,
	setPrepTimeMs,
} from '../../../state/actions'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../state/reducer'

const usePickupTimeCheck = ({ prepTime, setPrepTime, navigation }) => {
	const { isOpen, setIsOpen } = useContext(CheckoutScreenContext)
	const { resetTime, allowOrdersOutsideOrgHours } = useContext(DataContext)

	const cart = useSelector<ReduxStoreState, ReduxStoreState['items']>(state => state.items)

	const schedDuration = useSelector<ReduxStoreState, ReduxStoreState['schedDuration']>(
		state => state.schedDuration
	)

	const restaurants = useSelector<ReduxStoreState, ReduxStoreState['restaurants']>(
		state => state.restaurants
	)

	const localTimezone = useSelector<ReduxStoreState, ReduxStoreState['localTimezone']>(
		state => state.localTimezone
	)
	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)

	const schedCurrTime = useSelector<ReduxStoreState, ReduxStoreState['schedCurrTime']>(
		state => state.schedCurrTime
	)

	const dispatch = useDispatch()

	function handleSelection() {
		dispatch(setSchedCurrTime(''))
		dispatch(setSchedDuration(''))
		dispatch(setPickupMessage('')) //resets to pickup in x minutes
		dispatch(setPrepTimeString(''))
		const date = new Date()
		dispatch(setPrepTimeMs(date.setTime(date.getTime() + prepTime * 60000).toString()))
	}

	function hasScheduledTimePassed() {
		let passed = false
		if (schedDuration !== '' && schedDuration !== undefined) {
			const parsed = parsePrepTime(schedDuration)
			const pickupTime = add(new Date(schedCurrTime), parsed)
			if (utcToZonedTime(new Date(), localTimezone) >= pickupTime) {
				console.log('Clearing schedule time')
				passed = true
			}
		}
		return passed
	}

	function isStoreOpen(hours, timezone) {
		const prepTimeFormatted =
			schedDuration === '' || schedDuration === undefined ? prepTimeDur(prepTime) : schedDuration

		const parsedPrep = parsePrepTime(prepTimeFormatted)

		const restoHours = getDBHours(
			loadedData[global.org].hours ?? null,
			hours,
			timezone,
			parsedPrep,
			schedDuration !== '',
			false,
			false,
			allowOrdersOutsideOrgHours
		)
		return restoHours.isOpen
	}

	useEffect(() => {
		setPrepTime(getLatestPrepTimeCheckout(cart, loadedData))

		//check hours against restaurants in cart
		const filteredRestaurants = restaurants.filter(restaurant =>
			cart.some(item => item.rId === restaurant.id)
		)
		const hours = combineHours(filteredRestaurants)
		setIsOpen(isStoreOpen(hours, localTimezone))
		const isPassed = hasScheduledTimePassed()
		if (isPassed) {
			handleSelection()
		}
	}, [navigation, resetTime])
}

export default usePickupTimeCheck
