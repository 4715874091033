import { StatusBar } from 'expo-status-bar'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
	View,
	TextInput,
	TouchableOpacity,
	ScrollView,
	Image,
	Modal,
	TouchableWithoutFeedback,
	Platform,
} from 'react-native'
import DropDownPicker from 'react-native-dropdown-picker'
import { Formik } from 'formik'
import * as yup from 'yup'
import { OrganizationLogoAddressPage } from '../../components/OrganizationLogoHeader'
import { Spacer } from '../../components/Spacer'
import { formStyle, smallTextSize } from '../../constants/GlobalStyle'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../state/reducer'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { provinceList } from './validProvinces'
import { DataContext } from '../../state/context'
import Collapsible from 'react-native-collapsible'
import { auth } from '../../firebase/config'
import { AddressCard } from './AddressCard'
import DeliveryPickupToggle from './DeliveryPickupToggle'
import { getDeliveryEstimate } from '../../helpers/fbDataRetrieval/RetrieveLocation'
import { add, set } from 'date-fns'
import { parsePrepTime } from '../../helpers/prepTimeFunctions'
import { Text } from '../Themed'
import { alertResponse } from '../Alerts/Alerts'
import Colors from '../../constants/Colors'
import Banner from '../Banner'
import { setDeliverySettings } from '../../state/actions'

// Address validation schema
const addressSchema = yup.object().shape({
	street: yup.string().required('Street is required'),
	street2: yup.string(),
	city: yup.string().required('City is required'),
	province: yup.string().required('Province is required'),
	postalCode: yup
		.string()
		.transform(value => value.replace(/\s+/g, '').toUpperCase())
		.required('A valid postal code is required')
		.matches(/^[0-9A-Z]+$/, 'Invalid postal code'),
	label: yup.string(),
	deliveryNote: yup.string(),
})

const functions = getFunctions()

const capitalizeFirstLetterOfEachWord = text => {
	return text.replace(/\b\w/g, char => char.toUpperCase())
}

export default function NewAddressModal(props) {
	const { modalVisible, setModalVisible } = props

	const organizationLogo = useSelector<ReduxStoreState, ReduxStoreState['organizationLogo']>(
		state => state.organizationLogo
	)

	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)

	const restaurants = useSelector<ReduxStoreState, ReduxStoreState['restaurants']>(
		state => state.restaurants
	)

	const orgLogoDark = loadedData[global.org]?.organizationLogoDark
		? loadedData[global.org].organizationLogoDark
		: ''

	const userId = auth.currentUser ? auth.currentUser.uid : ''
	const {
		setUserLocation,
		setUserDeliveryAddress,
		userData,
		activeRID,
		orgDeliveryCoverageType,
		orgDeliveryCoverage,
		setDeliveryEstimate,
		userLocation,
		contactlessDropoff,
		setContactlessDropoff,
		deliveryIsSandbox,
		isDelivery,
		deliveryEstimate,
		hasInternet,
		setIsDelivery,
		setDeliveryEstimateList,
		deliveryEstimateList,
	} = useContext(DataContext)

	const availableRestaurants = restaurants.filter(restaurant => restaurant.isSnoozed === false)

	const deliveryAddresses = userData?.user?.deliveryAddresses || []
	const [saveAddress, setSaveAddress] = useState(auth.currentUser ? true : false)
	const [isLoading, setIsLoading] = useState(false)
	const [isCollapsed, setIsCollapsed] = useState(true)
	const [errorWithAddress, setErrorWithAddress] = useState(false)
	const [showInvalidAddress, setShowInvalidAddress] = useState(false)
	const [isFocus, setIsFocus] = useState(false)
	const [provinces, setProvinces] = useState(provinceList)

	const prepTime = loadedData[activeRID]?.prepTime ?? 15

	async function handleDeliveryEstimate(longAddress, isForm = false) {
		const batchSize = 15
		const delayBetweenBatches = modalVisible ? 30000 : 15000 // 10 seconds in milliseconds
		// Split the restaurant IDs into batches of 10
		const batches = []
		for (let i = 0; i < availableRestaurants.length; i += batchSize) {
			batches.push(availableRestaurants.slice(i, i + batchSize))
		}

		// Function to process a batch of restaurant IDs and update the estimates array
		const processBatch = async batch => {
			// Extract restaurant IDs from the batch
			const restaurantIds = batch.map(restaurant => restaurant.id)

			try {
				// Make a single request for the entire batch of restaurant IDs
				const estimates: any = await getDeliveryEstimate(
					longAddress,
					add(new Date(), parsePrepTime('P0DT0H' + prepTime + 'M0S')),
					null, // Pass the array of 10 IDs
					deliveryIsSandbox,
					restaurantIds
				)
				// Only process the successful estimates
				const successfulEstimates = estimates.success // Access the success array

				const newEstimates = successfulEstimates.map((estimate, index) => {
					const restaurantId = restaurantIds[index] // Match restaurant ID by index

					return {
						restaurantId,
						estimate: {
							fee: splitFee(estimate?.fee) || -1,
							timeToDeliver: estimate?.timeToDeliver || -1,
							totalFee: estimate?.fee || -1,
						},
					}
				})

				//console.log(newEstimates)

				// Update the deliveryEstimates state by appending the new estimates
				setDeliveryEstimateList(prevEstimates => [...prevEstimates, ...newEstimates])

				// Check if all estimates in this batch failed
				const allEstimatesFail = newEstimates.every(
					estimate => estimate.estimate.fee === -1 && estimate.estimate.timeToDeliver === -1
				)

				console.log(allEstimatesFail)

				// Set error if all estimates in the batch fail
				if (allEstimatesFail) {
					setErrorWithAddress(true)
					if (isForm) {
						setIsCollapsed(deliveryAddresses.length > 0)
						setShowInvalidAddress(true)
						setUserLocation(null)
					}
				} else {
					setErrorWithAddress(false)
				}
			} catch (error) {
				console.error('Error processing batch of restaurant IDs:', error)
			}
			setIsLoading(false)

			setUserDeliveryAddress(longAddress)
		}

		if (batches.length > 0) {
			await processBatch(batches[0])

			// Dynamic delay: 30 seconds if modalVisible is true, 15 seconds if false
			// const delay = delayBetweenBatches

			// if (batches.length > 1) {
			// 	console.log(`Waiting for ${delay / 1000} seconds before processing the next batch...`)
			// 	await new Promise(resolve => setTimeout(resolve, delay))
			// }
		}

		// Process the remaining batches in the background
		// for (let i = 1; i < batches.length; i++) {
		// 	processBatch(batches[i]) // No need to await, it runs in the background
		// }
	}

	useEffect(() => {
		if (deliveryAddresses.length <= 0) {
			setIsCollapsed(false)
		} else if (userLocation && userLocation.retrievedFromCurrLocation && isDelivery) {
			const address = deliveryAddresses[0]
			handleAddressSelect(
				address?.longAddress,
				address?.formattedAddress,
				address?.label,
				address?.instructions,
				address?.updatedAt,
				address?.id,
				address?.addressObject
			)
		}
	}, [])

	useEffect(() => {
		if (userLocation) {
			setIsLoading(true)
			if (
				(userLocation && userLocation.error) ||
				!isDelivery ||
				deliveryEstimate !== null ||
				!hasInternet
			) {
				setIsLoading(false)
			}
		}
	}, [deliveryEstimate])

	function splitFee(fee) {
		if (orgDeliveryCoverageType === 'percent') {
			return fee * (orgDeliveryCoverage / 100)
		} else if (fee - orgDeliveryCoverage >= 0) {
			return fee - orgDeliveryCoverage
		} else {
			return 0
		}
	}

	const DropoffToggle = () => {
		return (
			<TouchableOpacity
				onPress={() => setContactlessDropoff(!contactlessDropoff)}
				style={{
					flexDirection: 'row',
					alignItems: 'center',
					marginHorizontal: isCollapsed ? '3.5%' : '5%',
					marginVertical: 10,
					paddingBottom: 20,
				}}
			>
				<MaterialCommunityIcons
					name={contactlessDropoff ? 'checkbox-marked-circle' : 'checkbox-blank-circle-outline'}
					size={26}
					color={contactlessDropoff ? global.orgColor : '#818181'}
					style={{
						marginLeft: -10,
						marginRight: 10,
					}}
				/>
				<Text style={{ flex: 1, fontSize: smallTextSize, fontWeight: '700' }}>
					{'Contactless dropoff?'}
				</Text>
			</TouchableOpacity>
		)
	}

	const handleAddressSelect = async (
		longAddress,
		formattedAddress,
		label,
		instructions,
		updatedAt,
		id,
		addressObj
	) => {
		const locationObj: any = {
			coords: null,
			formattedAddress: formattedAddress,
			longAddress: longAddress,
			label: label,
			instructions: instructions,
			updatedAt: updatedAt,
			id: id,
			addressObject: addressObj,
		}
		setUserLocation(locationObj)
		locationObj.deliverySelected = isDelivery
		setDeliverySettings(locationObj)
		setIsLoading(true)
		setShowInvalidAddress(false)

		if (availableRestaurants.length > 0 && !loadedData[global.org].singleBrandEnabled) {
			await handleDeliveryEstimate(longAddress)
		} else {
			const estimate: any = await getDeliveryEstimate(
				longAddress,
				add(new Date(), parsePrepTime('P0DT0H' + prepTime + 'M0S')),
				activeRID,
				deliveryIsSandbox
			)

			if (!estimate.fee && !estimate.timeToDeliver) {
				setErrorWithAddress(true)
			} else {
				setErrorWithAddress(false)
			}

			setDeliveryEstimate({
				restaurantId: activeRID,
				estimate: {
					fee: splitFee(estimate.fee) || -1,
					timeToDeliver: estimate.timeToDeliver || -1,
					totalFee: estimate.fee || -1,
				},
			})
			setIsLoading(false)

			setUserDeliveryAddress(longAddress)
			setModalVisible(false)
		}
	}

	return (
		<Modal
			visible={modalVisible}
			statusBarTranslucent={true}
			transparent={true}
			animationType="fade"
			onRequestClose={() => null}
		>
			<TouchableWithoutFeedback onPress={() => null}>
				<View
					style={{
						flex: 1,
						justifyContent: 'center',
						alignItems: 'center',
						paddingHorizontal: '2.5%',
						backgroundColor: 'rgba(0,0,0,0.5)',
					}}
				>
					<TouchableWithoutFeedback onPress={() => {}}>
						<View
							style={{
								backgroundColor: 'white',
								paddingHorizontal: 20,
								width: '95%',
								maxWidth: 600,
								borderRadius: 10,
								shadowOpacity: 0.25,
								shadowRadius: 3.84,
								elevation: 5,
								maxHeight: '90%',
							}}
						>
							<ScrollView
								//contentContainerStyle={{ alignItems: 'center' }}
								showsVerticalScrollIndicator={false}
								automaticallyAdjustKeyboardInsets={true}
							>
								{showInvalidAddress && (
									<Banner text="Invalid Address! Please use a different address to continue." />
								)}
								<OrganizationLogoAddressPage
									imageUri={orgLogoDark ? orgLogoDark : organizationLogo}
								/>
								<DeliveryPickupToggle
									showChange={false}
									onPressPickup={() => {
										setIsDelivery(false)
										setModalVisible(false)
									}}
								/>

								{deliveryAddresses.length > 0 && (
									<>
										<View style={formStyle.headerContainer}>
											<TouchableOpacity
												onPress={() => setIsCollapsed(!isCollapsed)}
												style={formStyle.touchable}
											>
												<Text style={formStyle.headerText}>Select a delivery address</Text>
											</TouchableOpacity>
										</View>

										<Collapsible collapsed={!isCollapsed}>
											{deliveryAddresses.map((address, index) => (
												<AddressCard
													key={index}
													longAddress={address?.longAddress}
													label={address?.label}
													instructions={address?.instructions}
													onPress={() =>
														handleAddressSelect(
															address?.longAddress,
															address?.formattedAddress,
															address?.label,
															address?.instructions,
															address?.updatedAt,
															address?.id,
															address?.addressObject
														)
													}
													onDelete={() => {
														alertResponse(
															'Delete saved address?',
															'',
															'Delete',
															'Cancel',
															'default',
															'cancel',
															() => {
																const setDeliveryAddressForUser = httpsCallable(
																	functions,
																	'setDeliveryAddressForUser'
																)

																setDeliveryAddressForUser({
																	userId: userId,
																	address: address,
																	orgId: global.org,
																	isEdit: false,
																	isDelete: true,
																})
															},
															null
														)
													}}
												/>
											))}
										</Collapsible>
									</>
								)}

								<View style={formStyle.headerContainer}>
									<TouchableOpacity
										onPress={() => {
											//if (deliveryAddresses.length > 0) {
											setIsCollapsed(!isCollapsed)
											//}
										}}
										style={formStyle.touchable}
									>
										<Text style={formStyle.headerText}>Enter a new delivery address</Text>
									</TouchableOpacity>
								</View>

								<Collapsible collapsed={isCollapsed}>
									<Formik
										key={userLocation?.addressObject?.postalCode}
										initialValues={{
											label: '',
											street:
												userLocation && userLocation.retrievedFromCurrLocation
													? userLocation?.addressObject?.streetAddress
													: '',
											street2: '',
											city:
												userLocation && userLocation.retrievedFromCurrLocation
													? userLocation?.addressObject?.city
													: '',
											province:
												userLocation && userLocation.retrievedFromCurrLocation
													? userLocation?.addressObject?.province
													: '',
											postalCode:
												userLocation && userLocation.retrievedFromCurrLocation
													? userLocation?.addressObject?.postalCode
													: '',
											country: '',
											deliveryNote: '',
										}}
										validationSchema={addressSchema}
										onSubmit={async (values, actions) => {
											const streetAddress = `${values.street}${
												values.street2 ? ', ' + values.street2 : ''
											}`
											const longAddress = `${values.street}${
												values.street2 ? ', ' + values.street2 : ''
											}, ${values.city}, ${values.province}, ${values.postalCode}`
											const id = Date.now()
											const locationObj: any = {
												coords: null,
												formattedAddress: streetAddress,
												longAddress: longAddress,
												label: values.label,
												instructions: values.deliveryNote,
												id: id,
												addressObject: {
													streetAddress: values.street,
													streetAddress2: values.street2,
													city: values.city,
													province: values.province,
													postalCode: values.postalCode,
												},
											}
											setUserLocation(locationObj)
											locationObj.deliverySelected = isDelivery
											setDeliverySettings(locationObj)

											const setDeliveryAddressForUser = httpsCallable(
												functions,
												'setDeliveryAddressForUser'
											)

											if (saveAddress) {
												const addAddress: any = setDeliveryAddressForUser({
													userId: userId,
													address: {
														id: id,
														longAddress: longAddress,
														formattedAddress: streetAddress,
														label: values.label,
														instructions: values.deliveryNote,
														updatedAt: new Date(),
														addressObject: {
															streetAddress: values.street,
															streetAddress2: values.street2,
															city: values.city,
															province: values.province,
															postalCode: values.postalCode,
														},
													},
													orgId: global.org,
													isEdit: false,
													isDelete: false,
												})
												console.log('ADDED: ' + addAddress)
											}
											setIsLoading(true)
											setShowInvalidAddress(false)
											setUserDeliveryAddress(longAddress)

											// const estimate: any = await getDeliveryEstimate(
											// 	longAddress,
											// 	add(new Date(), parsePrepTime('P0DT0H' + prepTime + 'M0S')),
											// 	activeRID,
											// 	deliveryIsSandbox
											// )

											// setDeliveryEstimate({
											// 	restaurantId: activeRID,
											// 	estimate: {
											// 		fee: splitFee(estimate.fee) || -1,
											// 		timeToDeliver: estimate.timeToDeliver || -1,
											// 		totalFee: estimate.fee || -1,
											// 	},
											// })
											await handleDeliveryEstimate(longAddress, true)
											//setIsLoading(false)

											if (
												//(!estimate.fee && !estimate.timeToDeliver) ||
												(userLocation && userLocation.error) ||
												!userLocation
											) {
												setIsCollapsed(deliveryAddresses.length > 0)
												setShowInvalidAddress(true)
												setUserLocation(null)
												// setDeliveryEstimate(null)
												// alertResponseSingle(
												// 	'Invalid Address',
												// 	'Please select a different address to continue.',
												// 	'Continue',
												// 	null,
												// 	null
												// )
											} else {
												setShowInvalidAddress(false)
												setModalVisible(false)
											}

											actions.resetForm()
										}}
									>
										{({
											handleChange,
											handleBlur,
											handleSubmit,
											setFieldValue,
											values,
											touched,
											errors,
										}) => (
											<View style={{ width: '95%', alignSelf: 'center' }}>
												<TextInput
													returnKeyType={'done'}
													style={formStyle.textInput}
													placeholder="Street address"
													placeholderTextColor="#000000"
													onChangeText={handleChange('street')}
													value={capitalizeFirstLetterOfEachWord(values.street)}
													onBlur={handleBlur('street')}
													autoComplete="address-line1"
												/>
												<Text style={formStyle.errorText}>
													<>{touched.street && errors.street}</>
												</Text>
												<Spacer size={10} />
												<TextInput
													returnKeyType={'done'}
													style={formStyle.textInput}
													placeholder="Apt / Suite"
													placeholderTextColor="#000000"
													onChangeText={handleChange('street2')}
													value={values.street2}
													onBlur={handleBlur('street2')}
													autoComplete="address-line2"
												/>
												<Text style={formStyle.errorText}>
													<>{touched.street2 && errors.street2}</>
												</Text>
												<Spacer size={10} />
												{Platform.OS === 'ios' ? (
													<TextInput
														returnKeyType={'done'}
														style={formStyle.textInput}
														placeholder="City"
														placeholderTextColor="#000000"
														onChangeText={handleChange('city')}
														value={capitalizeFirstLetterOfEachWord(values.city)}
														onBlur={handleBlur('city')}
														textContentType="addressCity"
													/>
												) : (
													<TextInput
														returnKeyType={'done'}
														style={formStyle.textInput}
														placeholder="City"
														placeholderTextColor="#000000"
														onChangeText={handleChange('city')}
														value={capitalizeFirstLetterOfEachWord(values.city)}
														onBlur={handleBlur('city')}
														autoComplete="postal-address-locality"
													/>
												)}
												<Text style={formStyle.errorText}>
													<>{touched.city && errors.city}</>
												</Text>
												<Spacer size={10} />
												<DropDownPicker
													listMode="SCROLLVIEW"
													maxHeight={186}
													open={isFocus}
													value={values.province}
													items={provinces}
													setItems={setProvinces}
													setOpen={setIsFocus}
													setValue={callback => {
														const selectedValue = callback(values.province)
														console.log(selectedValue)
														setFieldValue('province', selectedValue)
													}}
													placeholder={'Province'}
													placeholderStyle={{
														color: '#000000',
														fontFamily: Platform.OS === 'web' ? 'System' : 'DefaultFont',
													}}
													style={formStyle.textInput}
													textStyle={{
														marginLeft: 5,
														fontFamily: Platform.OS === 'web' ? 'System' : 'DefaultFont',
													}}
													dropDownContainerStyle={{ borderColor: Colors.custom.transparentGrey }}
												/>
												<Text style={formStyle.errorText}>
													<>{touched.province && errors.province}</>
												</Text>
												<Spacer size={10} />
												<TextInput
													returnKeyType={'done'}
													style={formStyle.textInput}
													placeholder="Postal code"
													placeholderTextColor="#000000"
													onChangeText={handleChange('postalCode')}
													value={values.postalCode.toUpperCase()}
													onBlur={handleBlur('postalCode')}
													autoComplete="postal-code"
												/>
												<Text style={formStyle.errorText}>
													<>{touched.postalCode && errors.postalCode}</>
												</Text>
												<Spacer size={10} />
												<TextInput
													returnKeyType={'done'}
													style={formStyle.textInput}
													placeholder="Delivery instructions (optional)"
													placeholderTextColor="#000000"
													onChangeText={handleChange('deliveryNote')}
													value={values.deliveryNote}
													onBlur={handleBlur('deliveryNote')}
												/>
												<Text style={formStyle.errorText}>
													<>{touched.deliveryNote && errors.deliveryNote}</>
												</Text>
												<Spacer size={10} />
												<TextInput
													returnKeyType={'done'}
													style={formStyle.textInput}
													placeholder="Label (optional)"
													placeholderTextColor="#000000"
													onChangeText={handleChange('label')}
													value={values.label}
													onBlur={handleBlur('label')}
												/>
												<Text style={formStyle.errorText}>{touched.label && errors.label}</Text>
												<Spacer size={10} />
												<TouchableOpacity
													onPress={() => setSaveAddress(!saveAddress)}
													style={{
														flexDirection: 'row',
														alignItems: 'center',
														display: auth.currentUser ? 'flex' : 'none',
													}}
												>
													<MaterialCommunityIcons
														name={
															saveAddress
																? 'checkbox-marked-circle'
																: 'checkbox-blank-circle-outline'
														}
														size={26}
														color={saveAddress ? global.orgColor : '#818181'}
													/>
													<Text
														style={{ marginLeft: 5, fontSize: smallTextSize, fontWeight: '700' }}
													>
														{'Save address for future orders.'}
													</Text>
												</TouchableOpacity>
												<Spacer size={10} />
												<View style={[formStyle.btnContainer]}>
													<TouchableOpacity
														style={[formStyle.button, { backgroundColor: global.orgColor }]}
														onPress={isLoading ? null : (handleSubmit as any)}
													>
														{isLoading ? (
															<Image
																style={{
																	width: 90,
																	height: 90,
																	marginTop: -34,
																	marginBottom: -34,
																}}
																source={require('../../assets/images/loadImg.gif')}
															/>
														) : (
															<Text style={formStyle.btnText}>
																{saveAddress ? 'SAVE ADDRESS' : 'SET ADDRESS'}
															</Text>
														)}
													</TouchableOpacity>
												</View>
											</View>
										)}
									</Formik>
								</Collapsible>
								<DropoffToggle />
							</ScrollView>

							{(isCollapsed ||
								(deliveryAddresses.length <= 0 && userLocation !== null && isCollapsed)) && (
								<TouchableOpacity
									style={[
										formStyle.button,
										{
											backgroundColor: !hasInternet ? Colors.greyscale[6] : global.orgColor,
											width: '100%',
											marginBottom: 20,
										},
									]}
									disabled={!hasInternet}
									onPress={() => {
										if (
											!isLoading &&
											deliveryEstimateList.length <= 0 &&
											((deliveryEstimate &&
												deliveryEstimate.estimate &&
												deliveryEstimate.estimate.totalFee === -1) ||
												errorWithAddress ||
												(userLocation && userLocation.error) ||
												!userLocation)
										) {
											setShowInvalidAddress(true)
											// alertResponseSingle(
											// 	'Invalid Address',
											// 	'Please select a different address to continue.',
											// 	'Continue',
											// 	null,
											// 	null
											// )
										} else if (!isLoading) {
											setShowInvalidAddress(false)
											setModalVisible(false)
										}
									}}
								>
									{isLoading ? (
										<Image
											style={{
												width: 90,
												height: 90,
												marginTop: -34,
												marginBottom: -34,
											}}
											source={require('../../assets/images/loadImg.gif')}
										/>
									) : (
										<Text style={formStyle.btnText}>{'CONTINUE'}</Text>
									)}
								</TouchableOpacity>
							)}
						</View>
					</TouchableWithoutFeedback>
				</View>
			</TouchableWithoutFeedback>
		</Modal>
	)
}
