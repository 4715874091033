import React, { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxStoreState } from '../../state/reducer'
import {
	addItem,
	removeItem,
	setDeliveryActive,
	setLoadedData,
	setLocalTimezone,
	setOrganizationHeader,
	setOrganizationLogo,
	setOrganizationLogoDark,
	setRestaurants,
	setServiceFee,
	setServiceFeeTax,
} from '../../state/actions'
import { getRestaurantData } from './getRestaurantData'
import { getMenuData } from './getMenuData'
import { getMenuItemData } from './getMenuItemData'
import { getModifierItemData } from './getModifierItemData'
import { getModifierListData } from './getModifierListData'
import '../../global'
import Spinner from '../../components/LoadingSpinner'
import { preLoadImage } from '../../components/Image/ImageObject'
import { Platform, Image } from 'react-native'
import { getOrgData } from './getOrgData'
import {
	alertResponse,
	alertResponseSingle,
	alertResponseText,
} from '../../components/Alerts/Alerts'
import RNRestart from 'react-native-restart'
import { auth, db } from '../../firebase/config'
import { StripeLoader } from '../../components/Stripe/StripeLoader'
import { DataContext } from '../../state/context'
import { getTaxData } from './getTaxData'
import { getMenuItemCategories } from './getMenuItemCategories'
import { getPromoImageData } from './getPromoImageData'
import { checkSnooze } from '../checkSnooze'
import { getThirdPartyPOSTypes } from './getThirdPartyPOSTypes'
import { getLoyaltyRewardData } from './getLoyaltyRewardData'
import { getUserData } from './getUserData'
import { Helmet } from 'react-helmet'
import {
	collection,
	doc,
	getDocs,
	limit,
	onSnapshot,
	orderBy,
	query,
	startAfter,
	where,
} from 'firebase/firestore'
import { DATABASE_NAME } from '@env'
import { getPickupPoints } from './getPickupPoints'
import { getCuisines } from './getCuisines'

// Function to add restaurantObject to arrayOfObjects
function arrayFromObjects(restaurantObject) {
	var arrayOfObjects = []
	for (const id in restaurantObject) {
		if (id !== 'defaultRestaurant') {
			const restaurant = restaurantObject[id]
			const newObject = { id, ...restaurant }
			arrayOfObjects.push(newObject)
		}
	}
	return arrayOfObjects
}

export default function RetrieveData({ children, referralCode, loadingTracker }) {
	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)

	const _ = require('lodash')

	const items = useSelector<ReduxStoreState, ReduxStoreState['items']>(state => state.items)

	const {
		allDataLoaded,
		setAllDataLoaded,
		setUserData,
		setRestaurantList,
		setPaymentProvider,
		setIsSingleBrand,
		setDineInEnabled,
		setUsesTableNumbers,
		setDeliveryEnabled,
		setDeliveryProvider,
		setWebAppURL,
		setPosProvider,
		setOrgDeliveryCoverage,
		setOrgDeliveryCoverageType,
		setActiveOrder,
		setDeliveryActionIfUndeliverable,
		setDeliveryPickupInstructions,
		setActiveRID,
		setDeliveryMinSpend,
		setDeliveryIsSandbox,
		setFreeDeliveryMultiplier,
		setIsDelivery,
		setTransparentLogo,
		setPosLoyaltyEnabled,
		setPickupPointsEnabled,
		setSelectedPickupPoint,
		setAllowOrdersOutsideOrgHours,
	} = useContext(DataContext)

	const [stripePublishableKey, setStripePublishableKey] = useState('')

	const dispatch = useDispatch()

	const [loading, setLoading] = useState(true)
	const [spinner, setSpinner] = useState(true)
	const [appleAppId, setAppleAppId] = useState('')

	const ref = useRef(null)
	global.payRef = ref
	//VALIDATE ITEMS/MODS IN CART FOR DATA COMPATIBILITY --> AUTO DELETE / FIX --> ALERT FOR PRICE CHANGE / MOD DELETE
	function validateItems(menuItemObject, modifierItemObject, restaurantObject, menuObject) {
		let alertUpdate: boolean
		let modDeleteAlert: boolean
		let removedUpdate: boolean
		const keys = Object.keys(menuItemObject)
		items.forEach(item => {
			if (!keys.includes(item.id)) {
				console.log('ITEM NOT IN OBJECT', item.id)
				//item in cart no longer in data, delete
				dispatch(removeItem(item.id, item.idempotencyKey))
			} else {
				const dataItem = menuItemObject[item.id]
				const restaurantItem = restaurantObject[item.rId]

				const itemCheck =
					dataItem &&
					dataItem.restaurantId === item.rId &&
					!checkSnooze(dataItem.isSnoozed, dataItem.snoozeUntil) &&
					dataItem.id === item.id &&
					!dataItem.isDeleted

				const restaurantCheck =
					restaurantItem && !checkSnooze(restaurantItem.isSnoozed, restaurantItem.snoozeUntil)

				//check that item matches info
				if (itemCheck && restaurantCheck) {
					let update = false
					if (dataItem.price !== item.amount) {
						alertUpdate = true
						update = true
						item.amount = dataItem.price
					}
					if (dataItem.promo !== item.promoPrice) {
						alertUpdate = true
						update = true
						item.promoPrice = dataItem.promo
					}
					if (dataItem.name !== item.name) {
						update = true
						item.name = dataItem.name
					}
					//check mods
					const modCheck = validateModItems(item.modList, modifierItemObject)
					item.modList = modCheck.list

					if (modCheck.isUpdate === true) {
						update = true
					}
					if (modCheck.isAlert === true) {
						alertUpdate = true
					}
					if (modCheck.isModAlert === true) {
						modDeleteAlert = true
					}

					if (update === true) {
						dispatch(removeItem(item.id, item.idempotencyKey))
						dispatch(addItem(item))
					}
				} else {
					removedUpdate = true
					console.log('DELETING DUE TO DATA MISMATCH')
					dispatch(removeItem(item.id, item.idempotencyKey))
				}
			}
		})
		if (removedUpdate === true) {
			alertResponseText(
				'Items in your cart have been removed',
				'These items are no longer available.'
			)
		} else if (alertUpdate === true && !modDeleteAlert) {
			alertResponseText(
				'Prices have changed for items in your cart',
				'Please review your cart before checking out.'
			)
		} else if (modDeleteAlert === true && !alertUpdate) {
			alertResponseText(
				'Modifier selections have changed for items in your cart',
				'Please review your cart before checking out.'
			)
		} else if (modDeleteAlert === true && alertUpdate === true) {
			alertResponseText(
				'Items in your cart have been changed',
				'Please review your cart before checking out.'
			)
		}
	}

	function validateModItems(modList, modifierItemObject) {
		let update = false
		let priceAlert = false
		let modAlert = false
		if (modList !== undefined && modList.length > 0) {
			modList.forEach(list => {
				list.options.forEach(mod => {
					const dataItem = modifierItemObject['mod_item_' + mod.id]
					if (
						dataItem &&
						dataItem.id === mod.id &&
						!checkSnooze(dataItem.isSnoozed, dataItem.snoozeUntil)
					) {
						if (mod.price !== modifierItemObject['mod_item_' + mod.id].amount) {
							mod.price = modifierItemObject['mod_item_' + mod.id].amount
							update = true
							priceAlert = true
						}
						if (mod.name !== modifierItemObject['mod_item_' + mod.id].name) {
							mod.name = modifierItemObject['mod_item_' + mod.id].name
							update = true
						}
					} else {
						console.log('DELETING MOD ITEM DUE TO DATA MISMATCH', mod.id)
						//mod item in cart no longer in data, delete
						list.options = list.options.filter(item => item.id !== mod.id)
						update = true
						modAlert = true
					}
				})
			})
		}
		return { list: modList || [], isUpdate: update, isAlert: priceAlert, isModAlert: modAlert }
	}

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(user => {
			if (user) {
				// User is signed in
				const fetchData = async () => {
					try {
						const data = await getUserData(user.uid)
						setUserData(data)
						// Retrieve recent order for order banner
						const orderRef = collection(doc(db, DATABASE_NAME, global.org), 'Orders')
						const timeWindow = new Date(Date.now() - 2 * 60 * 60 * 1000)
						const fetchOrders = async (startAfterTimestamp = null) => {
							// Fetch last 3 orders
							let q = query(
								orderRef,
								where('userId', '==', user.uid),
								where('orderDate', '>=', timeWindow), // ENABLE
								orderBy('orderDate', 'desc'),
								limit(3)
							)

							if (startAfterTimestamp) {
								q = query(q, startAfter(startAfterTimestamp))
							}

							const snapshot = await getDocs(q)
							let foundOrder = null

							// Check for non-pending status
							for (const doc of snapshot.docs) {
								const data = doc.data()
								if (
									data.status !== 'Pending' &&
									data.status !== 'Failed' &&
									data.status !== 'Cancelled'
								) {
									foundOrder = data
									break
								}
							}

							if (foundOrder) {
								setActiveOrder(foundOrder)
							} else if (!snapshot.empty) {
								// re-fetch next 3 orders if all are pending
								const lastVisible = snapshot.docs[snapshot.docs.length - 1]
								fetchOrders(lastVisible)
							}
						}
						fetchOrders()
					} catch (error) {
						console.error('Failed to fetch user data:', error)
					}
				}
				fetchData()
			}
		})

		// Cleanup subscription on component unmount
		return () => unsubscribe()
	}, [])

	useEffect(() => {
		const fetchData = async () => {
			try {
				var orgObject = {}
				console.log('LOADING DATA ' + new Date().toLocaleString())

				// Run all data fetching operations in parallel
				// wait for all data to be retrieved before continuing
				await getCuisines()
				const [
					restaurantObject,
					result,
					menuObject,
					taxObject,
					menuItemCategoryObject,
					modifierItemObject,
					modifierListObject,
					promoImageObject,
					thirdPartyPOSTypes,
					loyaltyRewardObject,
					pickupPoints,
					cuisineObject,
				] = await Promise.all([
					getRestaurantData(),
					getOrgData(),
					getMenuData(),
					getTaxData(),
					getMenuItemCategories(),
					getModifierItemData(),
					getModifierListData(),
					getPromoImageData(),
					getThirdPartyPOSTypes(),
					getLoyaltyRewardData(),
					getPickupPoints(),
					getCuisines(),
				])
				const menuItemObject = await getMenuItemData(
					menuItemCategoryObject,
					modifierListObject,
					modifierItemObject,
					restaurantObject
				)
				if (Platform.OS === 'web') {
					//since web caches cart
					console.log('VALIDATING CART')
					validateItems(menuItemObject, modifierItemObject, restaurantObject, menuObject)
				}

				const data: any = result
				setWebAppURL(data.webURL || '')
				setAppleAppId(data.iosAppId || '')
				setPaymentProvider(data.paymentProvider || 'stripe')
				setTransparentLogo(data.transparentLogo || false)
				setPosProvider(data.posProvider || '')
				setPickupPointsEnabled(data.pickupPointsEnabled || false)
				setAllowOrdersOutsideOrgHours(data.allowOrdersOutsideOrgHours || false)

				global.dineIn = data.dineInEnabled || false
				orgObject[global.org] = data
				if (data.stripe && data.stripe.publishableKey) {
					if (data.environment === 'production') {
						setStripePublishableKey(data.stripe.publishableKey.production)
					} else {
						setStripePublishableKey(data.stripe.publishableKey.sandbox)
					}
				}
				setDeliveryProvider(data.deliveryProvider || 'inHouse')
				if (data.delivery) {
					setFreeDeliveryMultiplier(data.delivery.freeDeliveryMultiplier || -1)
					setDeliveryIsSandbox(
						data.delivery.isSandbox
							? data.delivery.isSandbox
							: data.environment === 'sandbox'
							? true
							: false
					)
					setDeliveryEnabled(data.delivery.enabled && !global.kiosk ? data.delivery.enabled : false)
					//setDeliveryEnabled(true)
					//setIsDelivery(true)
					setIsDelivery(data.delivery.enabled && !global.kiosk ? data.delivery.enabled : false)
					if (data.delivery.organizationCoverage) {
						setOrgDeliveryCoverage(data.delivery.organizationCoverage.amount)
						setOrgDeliveryCoverageType(data.delivery.organizationCoverage.type)
					}
					if (data.delivery.actionIfUndeliverable) {
						const action =
							data.delivery.actionIfUndeliverable === 'dispose' ? 'dispose' : 'return_to_pickup'
						setDeliveryActionIfUndeliverable(action)
					}

					setDeliveryPickupInstructions(data.delivery.pickupInstructions || '')
					setDeliveryMinSpend(data.delivery.minimumOrder || 0)
				}

				const restaurantArray = arrayFromObjects(restaurantObject)
				setRestaurantList(restaurantArray)
				if (restaurantArray.length === 1) {
					setActiveRID(restaurantArray[0].id)
				}
				const updatedData = _.merge(
					{},
					menuObject,
					taxObject,
					orgObject,
					loadedData,
					restaurantObject,
					menuItemCategoryObject,
					modifierItemObject,
					modifierListObject,
					menuItemObject,
					promoImageObject,
					thirdPartyPOSTypes,
					loyaltyRewardObject,
					pickupPoints,
					cuisineObject,
					{
						restaurants: restaurantArray,
					}
				)
				if (data.pickupPointsEnabled) {
					if (updatedData['pickupPoints'].length === 1) {
						setSelectedPickupPoint(updatedData['pickupPoints'][0])
					}
				}
				dispatch(setRestaurants(restaurantArray))
				dispatch(setLoadedData(updatedData))
				dispatch(setLocalTimezone(data.timezone ?? 'America/Vancouver'))
				dispatch(setOrganizationHeader(data.organizationHeader))
				if (global.kiosk && data.kioskOrganizationLogo !== undefined) {
					dispatch(setOrganizationLogo(data.kioskOrganizationLogo))
				} else {
					dispatch(setOrganizationLogo(data.organizationLogo))
					dispatch(setOrganizationLogoDark(data.organizationLogoDark))
				}
				if (global.kiosk && data.kioskOrganizationColor !== undefined) {
					global.orgColor = data.kioskOrganizationColor
				} else {
					global.orgColor = data.organizationColor
				}

				global.orgColor2 = data.organizationTextColor || '#848484'

				if (Platform.OS === 'web') {
					document.documentElement.style.setProperty('--safe-area-color', data.organizationColor)
				}

				global.singleBrandEnabled = data.singleBrandEnabled || false
				global.homeLayout = data?.appDisplayOptions?.homeLayout || 'categories'
				global.showCuisines = data?.appDisplayOptions?.showCuisines || false
				setIsSingleBrand(data.singleBrandEnabled || false)
				setPosLoyaltyEnabled(data.posLoyaltyEnabled || false)
				setDineInEnabled(data.dineInEnabled || false)
				setUsesTableNumbers(data.usesTableNumbers || false)
				dispatch(setServiceFee(data.serviceFee))
				dispatch(setServiceFeeTax(data.serviceFeeTax))

				if (Platform.OS !== 'web' && data.organizationHeader) {
					preLoadImage(data.organizationHeader)
				}
				if (Platform.OS !== 'web' && data.organizationLogo && data.organizationLogoDark) {
					preLoadImage(data.organizationLogo)
					preLoadImage(data.organizationLogoDark)
				}
				if (
					Platform.OS !== 'web' &&
					data.kioskOrganizationLogo !== undefined &&
					data.kioskOrganizationLogo !== ''
				) {
					preLoadImage(data.kioskOrganizationLogo)
				}

				setAllDataLoaded(true)

				setSpinner(false)
				setLoading(false)
				console.log('ALL DATA LOADED ' + new Date().toLocaleString())

				if (loadingTracker !== null) {
					loadingTracker(true) //data is done loading
					//use for kiosk since data load happens after initial render
				}
			} catch (error) {
				console.log(error)
				if (global.kiosk) {
					alertResponse(
						'Error loading data',
						'',
						'Refresh',
						'Logout',
						'default',
						'default',
						() => {
							RNRestart.Restart()
						},
						() => {
							auth.signOut()
							RNRestart.Restart()
						}
					)
				} else {
					alertResponseSingle('Error loading data', '', 'Refresh', null, () => {
						RNRestart.Restart()
					})
				}
			}
		}
		fetchData()
	}, [])
	return (
		<>
			{Platform.OS === 'web' && appleAppId !== '' && (
				<>
					<Helmet>
						<meta name="apple-itunes-app" content={`app-id=${appleAppId}`} />
					</Helmet>
				</>
			)}
			<Spinner
				visible={global.kiosk ? !allDataLoaded : spinner}
				overlayColor="rgba(255, 255, 255, 1)"
				headerHeight={0}
				footerHeight={0}
				color="black"
				customIndicator={
					<Image
						style={{ width: 100, height: 100, alignSelf: 'center', marginTop: -20 }}
						source={require('../../assets/images/loadImg.gif')}
					/>
				}
			/>
			{loading ? (
				<></>
			) : (
				<StripeLoader stripePublishableKey={stripePublishableKey}>{children}</StripeLoader>
			)}
		</>
	)
}
