import * as React from 'react'
import { View, TouchableOpacity } from 'react-native'
import { Text } from '../../../components/Themed'
import { Ionicons } from '@expo/vector-icons'
import {
	MED_WIDTH,
	headerTextSize,
	mediumTextSize,
	titleTextSize,
} from '../../../constants/GlobalStyle'
import ImageHeader from './ImageHeader'
import Colors from '../../../constants/Colors'
import DeliveryPickupToggle from '../../../components/Delivery/DeliveryPickupToggle'
import { DataContext } from '../../../state/context'
import { useContext } from 'react'

/**
 * Shows hero image and restaurant/category name
 * For brand menu it also shows hours, search, and logo
 */
export function MenuHeader({
	HeroImageURI,
	LogoImageURI,
	navigation,
	fill,
	rName = '',
	hoursText = null,
	toggle = null,
	sidebarVisible = false,
	address = null,
	setLocationPopup = null,
	rId = '',
}) {
	const { hasInternet } = useContext(DataContext)

	return (
		<View style={{ flex: 1 }}>
			<ImageHeader
				image={HeroImageURI}
				logo={LogoImageURI}
				navigation={navigation}
				fill={fill}
				toggle={toggle}
				sidebarVisible={sidebarVisible}
			/>
			{MED_WIDTH ? (
				<View
					style={{
						paddingHorizontal: '5%',
						paddingTop: 20,
						paddingBottom: 5,
						zIndex: 1,
						backgroundColor: fill,
						flexDirection: 'row',
					}}
				>
					<View style={{ flex: 8, alignItems: 'flex-start' }}>
						<Text style={{ fontSize: titleTextSize, fontWeight: '600' }}>{rName}</Text>
						{setLocationPopup !== null && address.line1.trim() !== '' && (
							<View style={{ flexDirection: 'row', alignItems: 'center' }}>
								<View>
									<Text
										style={{
											fontSize: mediumTextSize,
											fontWeight: '300',
											color: global.orgColor2,
											paddingVertical: 3,
										}}
									>
										{address.line1}
									</Text>
								</View>
								{setLocationPopup !== -1 && hasInternet && (
									<View>
										<TouchableOpacity onPress={() => setLocationPopup(true)}>
											<Text
												style={{
													marginLeft: 3,
													textDecorationLine: 'underline',
												}}
											>
												Change Location
											</Text>
										</TouchableOpacity>
									</View>
								)}
							</View>
						)}
						<Text
							style={{
								fontSize: mediumTextSize,
								fontWeight: '300',
								color: global.orgColor2,
								paddingVertical: 3,
								display: global.kiosk ? 'none' : 'flex',
							}}
						>
							{hoursText}
						</Text>
					</View>
					{toggle && !global.kiosk && (
						<TouchableOpacity
							activeOpacity={0.96}
							onPress={toggle}
							style={{ flex: 1, alignItems: 'flex-end' }}
						>
							<Ionicons
								name="ios-search-outline"
								size={headerTextSize * 0.9}
								color={Colors.greyscale[9]}
							/>
						</TouchableOpacity>
					)}
				</View>
			) : (
				<View style={{ paddingHorizontal: '3.5%', paddingLeft: 270 }}>
					<Text style={{ fontSize: headerTextSize, fontWeight: '600' }}>{rName}</Text>
					{setLocationPopup !== null && address.line1.trim() !== '' && (
						<View style={{ flexDirection: 'row', alignItems: 'center' }}>
							<View>
								<Text
									style={{
										fontSize: mediumTextSize,
										fontWeight: '300',
										color: global.orgColor2,
										paddingVertical: 3,
									}}
								>
									{address.line1}
								</Text>
							</View>
							{setLocationPopup !== -1 && hasInternet && (
								<View>
									<TouchableOpacity onPress={() => setLocationPopup(true)}>
										<Text
											style={{
												marginLeft: 3,
												textDecorationLine: 'underline',
											}}
										>
											Change Location
										</Text>
									</TouchableOpacity>
								</View>
							)}
						</View>
					)}

					<Text
						style={{
							fontSize: mediumTextSize,
							fontWeight: '300',
							color: global.orgColor2,
							paddingVertical: 3,
							display: global.kiosk ? 'none' : 'flex',
						}}
					>
						{hoursText}
					</Text>
					<DeliveryPickupToggle />
				</View>
			)}
		</View>
	)
}
