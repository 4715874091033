import * as React from 'react'
import {
	Image,
	ScrollView,
	Dimensions,
	TextInput,
	Keyboard,
	TouchableWithoutFeedback,
} from 'react-native'
import { Text, View } from '../../components/Themed'
import Colors from '../../constants/Colors'
import { auth, db } from '../../firebase/config'
import { useContext, useEffect, useState } from 'react'
import { styles } from './style'
import GlobalStyle, { formStyle } from '../../constants/GlobalStyle'
import { Spacer } from '../../components/Spacer'
import { RootTabScreenProps } from '../../navigation/types'
import Spinner from '../../components/LoadingSpinner'
import SignupScreen from '../Signup'
import { Header } from './Components/Header'
import AsyncStorage from '@react-native-async-storage/async-storage'
import PinpadReaderList from './Components/PinpadReaderList'
import { setLocationSettings, setUserId } from '../../state/actions'
import { useDispatch } from 'react-redux'
import SignInButton from './Components/SignInButton.kiosk'
import { updateProfile, signInWithEmailAndPassword } from 'firebase/auth'
import { httpsCallable, getFunctions } from 'firebase/functions'
import { DataContext } from '../../state/context'
import RetrieveData from '../../helpers/fbDataRetrieval/RetrieveData'
import { LocationSelectModal } from '../../components/LocationSelect'
import { getRestaurantData } from '../../helpers/fbDataRetrieval/getRestaurantData'
import { getOrgData } from '../../helpers/fbDataRetrieval/getOrgData'
import { LocationSelectKioskModal } from '../../components/LocationSelect.kiosk'
import { set } from 'date-fns'
import { DATABASE_NAME } from '@env'
import { doc, collection, getDoc } from 'firebase/firestore'
import { getPickupPoints } from '../../helpers/fbDataRetrieval/getPickupPoints'
import { PickupPointKioskModal } from '../../components/PickupPoint.kiosk'

interface OrganizationData {
	stripeLocationId?: string
	stripe?: any
	restaurants: any
	organizationLogo: string
	organizationHeader: string
	organizationColor: string
	organizationFee: number
	organizationFeeTax: number
	organizationTip: number
	organizationTextColor: string
	organizationName: string
	squareTerminalDeviceCodes?: any
}

export default function KioskHomeScreen({ navigation }: RootTabScreenProps<'TabHome'>) {
	const [spinner, setSpinner] = useState(true)
	const [failedToLoad, setFailedToLoad] = useState('')
	const [readerList, setReaderList] = useState<any>()
	const [reset, setReset] = useState([])
	const [orgError, setOrgError] = useState('')
	const [readerError, setReaderError] = useState('')
	const [orgInput, setOrgInput] = useState('')
	const [isSignup, setIsSignup] = useState(false)
	const [showPinpads, setShowPinpads] = useState(false)
	const [showContinue, setShowContinue] = useState(false)
	const [isUser, setIsUser] = useState(true)
	const [kioskNumber, setKioskNumber] = useState('')
	const [continueButtonText, setContinueButtonText] = useState('Continue')
	const [selected, setSelected] = useState('KIOSK')
	const [accountLoading, setAccountLoading] = useState(false)
	const [accountError, setAccountError] = useState('')

	const [restaurantData, setRestaurantData] = useState(null)

	const [orgData, setOrgData] = useState(null)
	const [restaurantLength, setRestaurantLength] = useState(0)

	const [posPassword, setPosPassword] = useState('')

	const {
		setSelectedLocation,
		setDataIsDoneLoading,
		selectedLocation,
		setShowKioskLocations,
		showKioskLocations,
	} = useContext(DataContext)

	const kioskPass = '12345678' //DEFAULT PASSWORD FOR KIOSK USED FOR FIREBASE AUTH
	function updateKioskUser() {
		var displaySplit = auth.currentUser.displayName.split('_')
		global.kioskName = displaySplit[0]

		if (displaySplit[0] === 'pos') {
			global.pos = true
		}
		if (global.readerId) {
			updateProfile(auth.currentUser, {
				displayName: displaySplit[0] + '_' + displaySplit[1] + '_' + global.readerId,
			})
		} else {
			global.readerId = 'tmr_' + displaySplit[3]
		}
	}

	function loadKioskUser() {
		if (global.kiosk && auth.currentUser && isUser) {
			updateKioskUser()
			navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
		}
	}

	//populate store ID with cached value
	useEffect(() => {
		AsyncStorage.getItem('storeId').then(value => {
			setOrgInput(value)
			global.org = value
		})
	}, [selected, isSignup])

	useEffect(() => {
		AsyncStorage.getItem('location').then(value => {
			if (value) {
				setLocationSettings(JSON.parse(value))
				setSelectedLocation(JSON.parse(value))
			}
		})
	}, [])

	useEffect(() => {
		const unsubscribe = navigation.addListener('focus', async () => {
			auth.onAuthStateChanged(async user => {
				if (user) {
					setIsUser(true)
				}
				if (global.kiosk && user && auth.currentUser && auth.currentUser.displayName) {
					const displaySplit = auth.currentUser.displayName.split('_')
					global.kioskName = displaySplit[0]
					const nameSplit = displaySplit[0].split(' ')
					if (nameSplit[0] === 'POS') {
						global.pos = true
					} else {
						global.pos = false
					}
					if (global.readerId) {
						updateProfile(auth.currentUser, {
							displayName: displaySplit[0] + '_' + displaySplit[1] + '_' + global.readerId,
						})
					} else {
						global.readerId = 'tmr_' + displaySplit[3]
					}
					await verifyOrg(false)
					navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
				} else {
					clearState()
				}
			})
		})
		return () => {
			unsubscribe()
		}
	}, [])

	function dataRow(title, value, isPassword, changeText) {
		return (
			<View
				style={{
					flexDirection: 'row',
				}}
			>
				<TextInput
					returnKeyType={'done'}
					style={[formStyle.textInput, { flex: 3, padding: 10, marginHorizontal: 20 }]}
					placeholder={title}
					value={value}
					secureTextEntry={isPassword}
					onChangeText={changeText}
					keyboardType={title === 'Kiosk #' ? 'numeric' : 'default'}
				/>
			</View>
		)
	}

	const dispatch = useDispatch()
	async function accountAuth(email: string, password: string) {
		try {
			const res = await signInWithEmailAndPassword(auth, email, password)
			const getUserInDB = httpsCallable(functions, 'getUserInDB')
			const user = await getUserInDB({
				orgId: global.org,
				userId: res.user.uid,
			})
			if (user.data !== null) {
				dispatch(setUserId(res.user.uid))
				console.log('User successfully signed in')
			} else {
				dispatch(setUserId(''))
				if (global.pos) {
					setAccountError('Cannot connect to POS account.')
				} else {
					setAccountError('Kiosk # does not exist.')
				}

				auth.signOut()
				console.log('Email exists in other organization')
			}
		} catch (error) {
			if (global.pos) {
				setAccountError('POS connect error: ' + error.message)
			} else {
				setAccountError('Kiosk # does not exist.')
			}

			console.log(error)
		}
	}

	const functions = getFunctions()

	async function verifyOrg(accountLoad) {
		const retrieveOrgData = httpsCallable(functions, 'retrieveOrgDataV2')
		retrieveOrgData({
			orgId: global.org,
		})
			.then(async (res: { data: OrganizationData }) => {
				const publicSettingsRef = doc(
					collection(doc(db, DATABASE_NAME, global.org), 'Settings'),
					'Public'
				)

				const pubSettings = await getDoc(publicSettingsRef)
				const pubData = pubSettings.data()

				setOrgError('')
				var data = res.data
				if (
					((selectedLocation && selectedLocation.id) || data.squareTerminalDeviceCodes) &&
					pubData.paymentProvider === 'square'
				) {
					let squareDeviceList
					if (selectedLocation && selectedLocation.id) {
						const deviceCodes = httpsCallable(functions, 'squareDevicesList')
						const devices: any = await deviceCodes({
							restaurantId: selectedLocation.id,
							orgId: global.org,
						})
						squareDeviceList = devices.data.map((device: any) => {
							return { id: 'tmr_' + device.deviceId, name: 'Square Terminal' }
						})
					} else {
						squareDeviceList = data.squareTerminalDeviceCodes.map((code: any) => {
							return { id: 'tmr_' + code, name: 'Square Terminal' }
						})
					}
					setReaderList({ data: { data: squareDeviceList } })
					setShowPinpads(true)
					setContinueButtonText('Continue')
					if (global.org !== undefined || global.org !== '') {
						AsyncStorage.setItem('storeId', global.org)
					}
				} else if (data.stripe && data.stripe.locationId) {
					let locId = ''
					if (pubData.environment === 'production') {
						locId = data.stripe.locationId.production
					} else {
						locId = data.stripe.locationId.sandbox
					}

					const stripeReaderList = httpsCallable(functions, 'stripeReaderList')
					const readers: any = await stripeReaderList({
						locationId: locId,
						orgId: global.org,
					})
					setReaderList(readers)
					setShowPinpads(true)
					setContinueButtonText('Continue')
					if (global.org !== undefined || global.org !== '') {
						AsyncStorage.setItem('storeId', global.org)
					}
				}
			})
			.catch(e => {
				console.log(e)
				setReaderList({})
				setShowPinpads(false)
				setShowContinue(false)
				setContinueButtonText('Continue')
				setOrgError('Cannot connect to store. Please verify ID.')
			})
		loadKioskUser()
		setSpinner(false)
		if (accountLoad) {
			setAccountLoading(true)
		}
	}

	function clearState() {
		setReaderList({})
		setShowContinue(false)
		setShowPinpads(false)
		setOrgInput('')
		setReaderError('')
		setOrgError('')
		setKioskNumber('')
		setSpinner(false)
		setPosPassword('')
		setIsSignup(false)
		setAccountError('')
		global.readerId = null
	}

	function checkForMultiLocation(restaurantObject, result) {
		const restaurantList = restaurantObject ? Object.keys(restaurantObject) : []
		let isMulti = false
		if (result.singleBrandEnabled && restaurantList.length > 1) {
			isMulti = true
			setShowKioskLocations(true)
			setRestaurantLength(restaurantList.length)
		}
		return isMulti
	}

	function locationText() {
		if (selectedLocation && selectedLocation.address) {
			let addressSpace = ''
			const address = selectedLocation.address
			if (address.addressLine2 !== '') {
				addressSpace = ' '
			}
			const formattedAddressLine = `${selectedLocation.address.addressLine1} ${
				selectedLocation.address.addressLine2 ? selectedLocation.address.addressLine2 : ''
			}`
			const formattedCityLine = `${selectedLocation.address.city}, ${selectedLocation.address.provinceState} ${selectedLocation.address.postCode}`
			return 'at ' + formattedAddressLine + addressSpace + formattedCityLine
		} else {
			return ''
		}
	}

	//Kiosk Sign In View
	function SignInView() {
		const useType = selected === 'POS' ? 'pos' : 'kiosk'
		if (auth.currentUser && isUser && auth.currentUser.displayName) {
			global.org = auth.currentUser.displayName.split('_')[1].trim()
			loadKioskUser()
			return null
		} else if (accountLoading) {
			return (
				<Image
					style={{
						width: 100,
						height: 100,
						alignSelf: 'center',
						justifyContent: 'center',
						marginTop: Dimensions.get('window').height / 2 - 50,
					}}
					source={require('../../assets/images/loadImg.gif')}
				/>
			)
		} else if (!spinner) {
			return (
				<TouchableWithoutFeedback
					onPress={() => {
						Keyboard.dismiss()
					}}
				>
					<ScrollView>
						<Header
							selected={selected}
							setSelected={setSelected}
							isSignup={isSignup}
							setIsSignup={setIsSignup}
							clearState={clearState}
							isPOS={selected === 'POS'}
						/>
						{dataRow('Store ID', orgInput, false, text => {
							setOrgInput(text)
							if (showPinpads) {
								setShowPinpads(false)
								setReaderList({})
								setShowContinue(false)
							}
							global.org = text.trim()
						})}
						<Spacer size={20} />
						{dataRow(selected === 'POS' ? 'POS #' : 'Kiosk #', kioskNumber, false, text => [
							setKioskNumber(text),
						])}
						<Spacer size={20} />
						{selected === 'POS'
							? dataRow('Password', posPassword, true, text => [setPosPassword(text)])
							: null}
						<Spacer size={selected === 'POS' ? 20 : 5} />
						<SignInButton
							text={continueButtonText}
							onPress={async () => {
								setContinueButtonText('Retrieving pinpads...')
								//await verifyOrg(false)
								const [restaurantObject, result, _]: any = await Promise.all([
									getRestaurantData(),
									getOrgData(),
									verifyOrg(false),
								])
								delete restaurantObject['defaultRestaurant']
								setRestaurantData(restaurantObject)
								let isMultiLocation = false
								if (result && result.singleBrandEnabled) {
									isMultiLocation = checkForMultiLocation(restaurantObject, result)
								}
								if (!isMultiLocation) {
									AsyncStorage.setItem('location', '')
								}

								setReset([])
							}}
						/>

						<Text
							style={{
								alignSelf: 'center',
								color: Colors.custom.pinkRed,
								paddingTop: 5,
							}}
						>
							{orgError}
						</Text>

						{showPinpads &&
						!showKioskLocations &&
						(restaurantData || (restaurantLength && restaurantLength <= 1)) ? (
							<View style={{ height: Dimensions.get('window').height / 3 }}>
								{readerError !== '' ? (
									<Text
										style={[
											GlobalStyle.addItemBtnText,
											{
												alignSelf: 'center',
												marginBottom: 5,
												color:
													readerError === 'Cannot connect to pinpad.'
														? Colors.custom.pinkRed
														: 'green',
											},
										]}
									>
										{readerError}
									</Text>
								) : (
									<Text
										style={[GlobalStyle.addItemBtnText, { alignSelf: 'center', marginBottom: 5 }]}
									>
										{`Select pinpad to connect with ${useType.toUpperCase()} ${kioskNumber.trim()} ${locationText()}`}
									</Text>
								)}

								<View
									style={{
										paddingVertical: 20,
										marginHorizontal: Dimensions.get('window').width / 10,
										marginBottom: 75,
									}}
								>
									<PinpadReaderList
										pinpads={readerList.data}
										setReaderError={setReaderError}
										setShowContinue={setShowContinue}
									/>
								</View>

								<View
									style={{ position: 'absolute', bottom: 25, alignSelf: 'center', width: '100%' }}
								>
									{showContinue ? (
										isSignup ? (
											<SignupScreen
												onStateChange={setIsSignup}
												onCheckout={false}
												navigation={navigation}
												kioskNum={kioskNumber.trim()}
												kioskPass={selected === 'POS' ? posPassword.trim() : kioskPass.trim()}
												kioskFunction={async () => {
													setSpinner(true)
													setAccountLoading(true)
													await verifyOrg(true)
													setReset([])
													setAccountLoading(false)
												}}
												isPOS={selected === 'POS'}
											/>
										) : (
											<>
												<SignInButton
													text={'Sign in'}
													onPress={async () => {
														accountAuth(
															useType +
																kioskNumber.trim() +
																'_' +
																orgInput.trim() +
																'@nextgenkitchens.com',
															selected === 'POS' ? posPassword.trim() : kioskPass.trim()
														)
													}}
												/>
												<Text
													style={{
														textAlign: 'center',
														marginTop: 5,
														fontSize: 18,
														color: 'crimson',
													}}
												>
													{accountError}
												</Text>
											</>
										)
									) : (
										<></>
									)}
								</View>
							</View>
						) : (
							<></>
						)}
					</ScrollView>
				</TouchableWithoutFeedback>
			)
		}
	}

	if (global.kiosk && global.org !== undefined && spinner) {
		return (
			<>
				<RetrieveData referralCode={''} loadingTracker={setDataIsDoneLoading}>
					<LocationSelectKioskModal
						visible={showKioskLocations}
						setVisible={setShowKioskLocations}
						restaurantObject={restaurantData}
						setShowPinpads={setShowPinpads}
					/>
					<Spinner
						visible={spinner}
						overlayColor="rgba(255, 255, 255, 1)"
						headerHeight={0}
						footerHeight={0}
						color="grey"
					/>
					<View style={styles.homeContainer}>
						<View style={{ justifyContent: 'center', alignItems: 'center' }}>
							{global.kiosk ? SignInView() : null}
							<Text
								style={{
									textAlign: 'center',
									marginTop: 20,
									paddingHorizontal: 50,
									color: Colors.custom.pinkRed,
								}}
							>
								{failedToLoad}
							</Text>
						</View>
					</View>
				</RetrieveData>
			</>
		)
	}

	return (
		<>
			<LocationSelectKioskModal
				visible={showKioskLocations}
				setVisible={setShowKioskLocations}
				restaurantObject={restaurantData}
				setShowPinpads={setShowPinpads}
			/>
			<Spinner
				visible={spinner}
				overlayColor="rgba(255, 255, 255, 1)"
				headerHeight={0}
				footerHeight={0}
				color="grey"
			/>
			<View style={styles.homeContainer}>
				<View style={{ justifyContent: 'center', alignItems: 'center' }}>
					{global.kiosk ? SignInView() : null}
					<Text
						style={{
							textAlign: 'center',
							marginTop: 20,
							paddingHorizontal: 50,
							color: Colors.custom.pinkRed,
						}}
					>
						{failedToLoad}
					</Text>
				</View>
			</View>
		</>
	)
}
