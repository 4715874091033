import React, { useState } from 'react'
import { View, Dimensions } from 'react-native'
import { styles } from '../../Helpers/style'
import { useContext } from 'react'
import { CheckoutScreenContext, DataContext } from '../../../../state/context'
import Button from '../../../../components/Button'
import { MobileCardElement } from '../../../../App'
import { AddTip } from '../AddTip/AddTip'
import { continueButtonKiosk, receiptText } from '../AddTip/TipModalFunctions'
import Colors from '../../../../constants/Colors'
import { AddPromo } from '../AddPromo/AddPromo'
import { CashPayment } from './CashPayment'
import { FontAwesome } from '@expo/vector-icons'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../state/reducer'
import ThirdPartyPaymentList from '../../Components/ThirdPartyPaymentList.pos'
import GlobalStyle from '../../../../constants/GlobalStyle'
import CheckoutNavFooter from '../../../../components/Footers/CheckoutNavFooter'
import { Text } from '../../../../components/Themed'

/**
 * POS screen only - allows different payment methods other than credit/debit
 */
export function SelectPOSMethod({ toggleConfirmModal, tipData, promoData, navigation }) {
	const { setShowPOSMethods, typePOS, setTypePOS, setUseNewPaymentMethod, setPosExtraData } =
		useContext(CheckoutScreenContext)

	promoData.isStaffMeal = true

	const { setPhoneNumberInput, setShowPhoneNumber, prices } = useContext(CheckoutScreenContext)

	const { setIsCardValid } = useContext(DataContext)

	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)

	const setPOSData = type => {
		setPosExtraData({
			name: '',
			firebaseId: '',
			note: '',
			type: type,
		})
	}

	const handleManualCCPress = () => {
		setTypePOS('manual')
		setPOSData('manual')
		setShowTips(false)
	}

	const handlePinpadPress = () => {
		setTypePOS('pinpad')
		setPOSData('pinpad')
		setShowPOSMethods(false)
	}

	const handleCashPress = () => {
		setTypePOS('cash')
		setPOSData('cash')
	}

	const handleThirdPartyPress = () => {
		setTypePOS('external')
	}

	const handleStaffMealPress = () => {
		setTypePOS('staffMeal')
		setPOSData('staffMeal')
	}

	const handleContinuePress = () => {
		setShowTips(true)
		setUseNewPaymentMethod(true)
	}

	const handleTipPress = () => {
		setShowTips(false)
		setUseNewPaymentMethod(true)
		setShowPOSMethods(false)
	}

	const [showTips, setShowTips] = useState(false)

	const hideButtons = ['manual', 'staffMeal', 'cash', 'external']

	const icon = () => {
		return (
			<FontAwesome
				name="dollar"
				size={global.kiosk ? 35 : 20}
				color={'white'}
				style={{ alignSelf: 'center', justifyContent: 'center' }}
			/>
		)
	}

	function BottomRowButtons() {
		return (
			<CheckoutNavFooter
				onBackPress={() => {
					setShowPOSMethods(true)
					setTypePOS('')
					setPOSData('')
				}}
				onNextPress={() => {
					if (setShowPOSMethods !== undefined) {
						setShowPOSMethods(false)
					}
					setPhoneNumberInput(true)
					setShowPhoneNumber(false)
				}}
				style={{ marginBottom: '10%' }}
			/>
		)
	}

	return (
		<>
			<View style={styles.container}>
				<View
					style={[
						{
							display: hideButtons.includes(typePOS) ? 'none' : 'flex',
							marginHorizontal: 30,
						},
					]}
				>
					<View
						style={{
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<Text
							style={[styles.buttonText, { color: Colors.custom.darkGrey, fontWeight: 'bold' }]}
						>
							TOTAL DUE:
						</Text>
						<Text
							style={[styles.buttonText, { color: Colors.custom.darkGrey, fontWeight: 'bold' }]}
						>
							{'$' + prices.total.toFixed(2)}
						</Text>
					</View>
					<Text
						style={[
							styles.buttonText,
							{
								color: Colors.custom.darkGrey,
								fontWeight: 'bold',
								textAlign: 'center',
								paddingTop: 40,
								paddingBottom: 20,
							},
						]}
					>
						SELECT A PAYMENT METHOD:
					</Text>
					<Button
						title="SEND TO PINPAD"
						onPress={handlePinpadPress}
						buttonStyle={[
							GlobalStyle.button,
							{ backgroundColor: global.orgColor, borderColor: global.orgColor, marginBottom: 30 },
						]}
						textStyle={[styles.buttonText, { color: 'white' }]}
						isLoading={false}
						icon={undefined}
					/>
					<Button
						title="ENTER CC MANUALLY"
						onPress={handleManualCCPress}
						buttonStyle={[
							GlobalStyle.button,
							{ backgroundColor: '#818181', borderColor: '#818181', marginBottom: 30 },
						]}
						textStyle={[styles.buttonText, { color: 'white' }]}
						isLoading={false}
						icon={undefined}
					/>
					<Button
						title="THIRD PARTY PAYMENT"
						onPress={handleThirdPartyPress}
						buttonStyle={[
							GlobalStyle.button,
							{ backgroundColor: '#818181', borderColor: '#818181', marginBottom: 30 },
						]}
						textStyle={[styles.buttonText, { color: 'white' }]}
						isLoading={false}
						icon={undefined}
					/>
					<Button
						title="STAFF MEAL"
						onPress={handleStaffMealPress}
						buttonStyle={[
							GlobalStyle.button,
							{ backgroundColor: '#818181', borderColor: '#818181', marginBottom: 30 },
						]}
						textStyle={[styles.buttonText, { color: 'white' }]}
						isLoading={false}
						icon={undefined}
					/>

					<Button
						title="CASH / CHEQUE"
						onPress={handleCashPress}
						buttonStyle={[
							GlobalStyle.button,
							{ backgroundColor: '#818181', borderColor: '#818181', marginBottom: 50 },
						]}
						textStyle={[styles.buttonText, { color: 'white' }]}
						isLoading={false}
						icon={undefined}
					/>
				</View>

				{typePOS === 'manual' ? (
					!showTips ? (
						<View
							style={{
								width: '100%',
								flex: 1,
								justifyContent: 'space-between',
								marginBottom: 30,
								paddingHorizontal: 30,
							}}
						>
							<Text
								style={[
									GlobalStyle.headerText,
									{
										textAlign: 'center',
										marginVertical: 50,
										flexGrow: 0.1,
									},
								]}
							>
								ENTER A CREDIT CARD NUMBER
							</Text>
							<MobileCardElement setIsCardValid={setIsCardValid} />

							<View style={{ flex: 1 }}></View>
							<CheckoutNavFooter
								onBackPress={() => {
									setShowPOSMethods(true)
									setTypePOS('')
									setPOSData('')
								}}
								onNextPress={handleContinuePress}
								style={{ marginBottom: '5%' }}
							/>
						</View>
					) : (
						<View
							style={{
								width: '100%',
								flex: 1,
								justifyContent: 'space-between',
							}}
						>
							<AddTip prices={prices} tipFunctions={tipData} isPOS={true} />
							<CheckoutNavFooter
								onBackPress={handleManualCCPress}
								onNextPress={() => {
									if (setShowPOSMethods !== undefined) {
										setShowPOSMethods(false)
									}
									setPhoneNumberInput(true)
									setShowPhoneNumber(false)
								}}
								style={{ marginBottom: 30 }}
							/>
						</View>
					)
				) : (
					<></>
				)}

				{typePOS === 'external' ? (
					<>
						<Text
							style={[
								GlobalStyle.headerText,
								{
									textAlign: 'center',
									marginVertical: 20,
									flexGrow: 0.1,
								},
							]}
						>
							SELECT A 3RD PARTY BRAND
						</Text>
						<ThirdPartyPaymentList brands={loadedData['thirdPartyTypes']} />
						<BottomRowButtons />

						<View style={{ paddingBottom: 30 }}></View>
					</>
				) : (
					<></>
				)}

				{typePOS === 'staffMeal' ? (
					<View
						style={{
							width: Dimensions.get('window').width * 0.7,
							flex: 1,
							justifyContent: 'space-between',
						}}
					>
						<View style={{ flex: 1, justifyContent: 'center' }}>
							<View style={{ marginHorizontal: 30, marginBottom: 50 }}>
								<AddPromo promoFuncData={promoData} navigation={navigation} staffMeal={true} />
							</View>
							{receiptText('TOTAL', prices.total.toFixed(2), icon())}
						</View>
						<View style={{ marginHorizontal: 20 }}>
							<BottomRowButtons />
						</View>
					</View>
				) : (
					<></>
				)}

				{typePOS === 'cash' ? (
					<>
						<CashPayment />
						<BottomRowButtons />
					</>
				) : (
					<></>
				)}
			</View>
		</>
	)
}
