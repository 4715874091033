import { useEffect, useContext } from 'react'
import { DataContext, RestaurantScreenContext } from '../../../state/context'
import { setLocalTimezone } from '../../../state/actions'
import { convertHours, parsePrepTime, prepTimeDur } from '../../../helpers/prepTimeFunctions'
import { utcToZonedTime } from 'date-fns-tz'
import Formatter from '../../../helpers/Formatter'
import { checkSnooze } from '../../../helpers/checkSnooze'
import { useSelector } from 'react-redux'
import { getDBHours } from '../../../components/ScheduleHours/RestaurantHours'
import { ReduxStoreState } from '../../../state/reducer'

const useMenuData = ({ rIdToken, navigation }) => {
	const {
		setLoading,
		setCtgTokens,
		setSpinner,
		setStoreAddress,
		setRName,
		setRSocial,
		featuredItemsList,
		setCategoryList,
		setStoreOpen,
		setMenuList,
		setLoadingItems,
		setWebHero,
		setWebLogo,
		setReset,
		updateData,
	} = useContext(RestaurantScreenContext)

	const { allDataLoaded, allowOrdersOutsideOrgHours } = useContext(DataContext)

	const loadedData = useSelector<ReduxStoreState, ReduxStoreState['loadedData']>(
		state => state.loadedData
	)

	const schedDuration = useSelector<ReduxStoreState, ReduxStoreState['schedDuration']>(
		state => state.schedDuration
	)

	const isSnoozed = useSelector<ReduxStoreState, ReduxStoreState['isSnoozed']>(
		state => state.isSnoozed
	)

	const localTimezone = useSelector<ReduxStoreState, ReduxStoreState['localTimezone']>(
		state => state.localTimezone
	)

	const prepTimeMs = useSelector<ReduxStoreState, ReduxStoreState['prepTimeMs']>(
		state => state.prepTimeMs
	)
	function isStoreOpen(hours, timezone) {
		if (isSnoozed) {
			return false
		}
		const prepTimeFormatted =
			schedDuration === '' || schedDuration === undefined
				? prepTimeDur(loadedData[rIdToken].prepTime)
				: schedDuration

		const parsedPrep = parsePrepTime(prepTimeFormatted)

		const restoHours = getDBHours(
			loadedData[global.org].hours ?? null,
			hours,
			timezone,
			parsedPrep,
			schedDuration !== '',
			isSnoozed,
			false,
			allowOrdersOutsideOrgHours
		)
		return restoHours.isOpen
	}

	useEffect(() => {
		if (!rIdToken.startsWith('category')) {
			let menuItemIds: any[] = []
			let featuredItemsIds: any[] = []
			featuredItemsList.length = 0
			if (loadedData) {
				if (loadedData[rIdToken]) {
					setStoreOpen(isStoreOpen(loadedData[rIdToken].hours, localTimezone))
					setRName(loadedData[rIdToken].name)
					setRSocial(loadedData[rIdToken].social)
					setWebHero(loadedData[rIdToken].heroImg)
					setWebLogo(loadedData[rIdToken].logoImg)
					const address = loadedData[rIdToken].address
					if (address !== undefined) {
						let addressSpace = ''
						if (address.addressLine2 !== '') {
							addressSpace = ' '
						}
						setStoreAddress(
							`${address.addressLine1}${addressSpace}${address.addressLine2}, ${address.city}, ${address.provinceState} ${address.postCode}`
						)
					}
				}
				if (loadedData['menus_for_' + rIdToken]) {
					loadedData['menus_for_' + rIdToken].forEach(data => {
						const currentDate =
							prepTimeMs === undefined || prepTimeMs === ''
								? new Date().getTime()
								: parseInt(prepTimeMs)
						const activeDatesArray = data.activeDateRanges
						const activeTimes = convertHours(data.activeTimesOfWeek, loadedData[rIdToken].prepTime)

						const itemData = getMenuItemData(
							data.menuContent,
							loadedData,
							localTimezone,
							schedDuration
						)
						if (
							data.id == 'DefaultMenu-' + rIdToken ||
							data.default === true ||
							data.activeDateRanges.length <= 0 ||
							data.activeTimesOfWeek.length <= 0
						) {
							global.menuId = data.id
							menuItemIds = itemData.itemList

							setMenuList(itemData.itemList)
							setCategoryList(itemData.categoryList)

							featuredItemsIds = itemData.featuredItems
						} else {
							if (activeDatesArray.length <= 0) {
								const dateCompare = Math.abs(
									new Date(parseInt(prepTimeMs)).getTime() - new Date().getTime()
								)
								const d =
									prepTimeMs === undefined || prepTimeMs === '' || dateCompare < 30000 //less than half a minute difference, not scheduled
										? utcToZonedTime(new Date(), localTimezone)
										: new Date(parseInt(prepTimeMs))
								//convert current time to decimal i.e. 2:30 = 14.5
								const dec = d.getMinutes() / 60
								const decString = (dec + '').slice(2)
								const currentTime = d.getHours() + '.' + decString
								const prepDay = new Date(parseInt(prepTimeMs))
								const currentDay = prepTimeMs !== '' ? prepDay.getDay() : d.getDay()
								for (const [key, value] of Object.entries(activeTimes)) {
									//key = 0 -> 6 (day of week)
									//value = {startTimes:[], endTimes: []}
									//times are in number format i.e. 3:45 = 15.75
									if (parseInt(key) === currentDay) {
										var startTimes = value['startTimes']
										var endTimes = value['endTimes']
										startTimes.forEach((time, i) => {
											if (startTimes[i] <= currentTime && endTimes[i] >= currentTime) {
												global.menuId = data.id

												menuItemIds = itemData.itemList
												setMenuList(itemData.itemList)
												setCategoryList(itemData.categoryList)
												featuredItemsIds = itemData.featuredItems
											}
										})
									}
								}
							}

							// Check if valid date range
							activeDatesArray.forEach(dateRange => {
								if (
									(dateRange.startDate !== 0 &&
										dateRange.endDate !== 0 &&
										dateRange.startDate.toMillis() <= currentDate &&
										dateRange.endDate.toMillis() >= currentDate) ||
									(dateRange.startDate === 0 && dateRange.endDate === 0)
								) {
									const dateCompare = Math.abs(
										new Date(parseInt(prepTimeMs)).getTime() - new Date().getTime()
									)
									const d =
										prepTimeMs === undefined || prepTimeMs === '' || dateCompare < 30000 //less than half a minute difference, not scheduled
											? utcToZonedTime(new Date(), localTimezone)
											: new Date(parseInt(prepTimeMs))
									//convert current time to decimal i.e. 2:30 = 14.5
									const dec = d.getMinutes() / 60
									const decString = (dec + '').slice(2)
									const currentTime = d.getHours() + '.' + decString
									const prepDay = new Date(parseInt(prepTimeMs))
									const currentDay = prepTimeMs !== '' ? prepDay.getDay() : d.getDay()
									for (const [key, value] of Object.entries(activeTimes)) {
										//key = 0 -> 6 (day of week)
										//value = {startTimes:[], endTimes: []}
										//times are in number format i.e. 3:45 = 15.75
										if (parseInt(key) === currentDay) {
											const startTimes = value['startTimes']
											const endTimes = value['endTimes']
											startTimes.forEach((time, i) => {
												if (startTimes[i] <= currentTime && endTimes[i] >= currentTime) {
													global.menuId = data.id

													menuItemIds = itemData.itemList
													setMenuList(itemData.itemList)
													setCategoryList(itemData.categoryList)
													featuredItemsIds = itemData.featuredItems
												}
											})
										}
									}
								}
							})
						}
					})
				}
			} else {
				navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
			}
			const localCtgTokens = {}
			let tokenAmts = 0
			menuItemIds.forEach(item => {
				const itemData = loadedData[item.id]
				tokenAmts++
				if (itemData && !checkSnooze(itemData.isSnoozed, itemData.snoozeUntil)) {
					if (item.category in localCtgTokens) {
						localCtgTokens[item.category].push({
							id: item.id,
							name: itemData.name, //toUpperCase
							desc: itemData.description,
							price: Formatter.currencyFormat(itemData.price / 100),
							image: itemData.imageUri ? itemData.imageUri : '',
							promoPrice: itemData.promo ? Formatter.currencyFormat(itemData.promo / 100) : '',
							menuId: global.menuId,
							category: item.category,
							thumbnail: itemData.thumbnailUri,
							rId: rIdToken,
							variationMinPrice:
								itemData.variationMinPrice !== -1
									? Formatter.currencyFormat((itemData.variationMinPrice + itemData.price) / 100)
									: -1,
							variationMaxPrice:
								itemData.variationMaxPrice !== -1
									? Formatter.currencyFormat((itemData.variationMaxPrice + itemData.price) / 100)
									: -1,
						})
					} else {
						localCtgTokens[item.category] = [
							{
								id: item.id,
								name: itemData.name, //toUpperCase
								desc: itemData.description,
								price: Formatter.currencyFormat(itemData.price / 100),
								image: itemData.imageUri ? itemData.imageUri : '',
								promoPrice: itemData.promo ? Formatter.currencyFormat(itemData.promo / 100) : '',
								menuId: global.menuId,
								category: item.category,
								thumbnail: itemData.thumbnailUri,
								rId: rIdToken,
								variationMinPrice:
									itemData.variationMinPrice !== -1
										? Formatter.currencyFormat((itemData.variationMinPrice + itemData.price) / 100)
										: -1,
								variationMaxPrice:
									itemData.variationMaxPrice !== -1
										? Formatter.currencyFormat((itemData.variationMaxPrice + itemData.price) / 100)
										: -1,
							},
						]
					}

					if (featuredItemsIds.length > 0) {
						if (featuredItemsIds.includes(item.id)) {
							if (item.id === item.id && featuredItemsList.length < featuredItemsIds.length) {
								featuredItemsList.push({
									id: item.id,
									image: itemData.imageUri,
									name: itemData.name,
									menuId: global.menuId,
									price: itemData.price,
									bogo: itemData.bogo,
								})
							}
						}
					}
				}
			})
			setCtgTokens(localCtgTokens)
			setReset([])
			setLoadingItems(false)
			setSpinner(false)
			setLoading(false)
			return () => {
				setLoading(false)
			}
		}
		return () => {
			setLoading(false)
		}
	}, [prepTimeMs, navigation, rIdToken, updateData, allDataLoaded])
	return
}

// Make object of each item {cat, id}
// Add categories to array
function getMenuItemData(menuContent, data, timezone, scheduleTime) {
	const categories = []
	const items = []
	const featuredItems = []
	menuContent.forEach(catGroup => {
		if (!catGroup.isDeleted) {
			categories.push(catGroup.categoryName)
			catGroup.itemIds.forEach(itemId => {
				const isAvailable = checkItemAvailability(data[itemId], timezone, scheduleTime)
				if (isAvailable) {
					items.push({ category: catGroup.categoryName, id: itemId })
				}
			})
		}
	})
	return {
		categoryList: categories,
		itemList: items,
		featuredItems: featuredItems,
	}
}

function checkItemAvailability(data, timezone, scheduleTime) {
	if (data && data.hours !== null) {
		const response = getDBHours(
			null,
			data.hours,
			timezone,
			null,
			scheduleTime !== '',
			false,
			true,
			null
		).isOpen
		return response
	} else {
		return true
	}
}
export default useMenuData
