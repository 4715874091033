import React, { useContext, useState } from 'react'
import { TextInput } from 'react-native'
import { Text, View } from '../../../../components/Themed'
import GlobalStyle, { headerTextSize } from '../../../../constants/GlobalStyle'
import { CheckoutScreenContext } from '../../../../state/context'
import { receiptText } from '../AddTip/TipModalFunctions'
import { MaterialCommunityIcons, FontAwesome, Ionicons } from '@expo/vector-icons'
import Colors from '../../../../constants/Colors'
import NumberPad from '../../../../components/NumberPad'

const CashInput = ({ name, price, icon, onChange, onEndEditing }) => {
	return (
		<>
			<View
				style={{
					flexDirection: 'row',
					justifyContent: 'center',
					marginBottom: '3%',
				}}
			>
				<View
					style={{
						flex: 0.125,
						justifyContent: 'center',
					}}
				>
					<View
						style={{
							backgroundColor: global.orgColor ? global.orgColor : Colors.custom.restaurantMain,
							borderRadius: 30,
							width: global.kiosk ? 40 : 30,
							height: global.kiosk ? 40 : 30,
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						{icon}
					</View>
				</View>

				<View style={{ flex: 0.4, justifyContent: 'center' }}>
					<Text style={GlobalStyle.boldedText}>{name}</Text>
				</View>

				<View
					style={{
						flex: 0.3,
						marginLeft: 15,
						justifyContent: 'center',
						alignItems: 'flex-end',
					}}
				>
					<TextInput
						showSoftInputOnFocus={false}
						autoFocus={true}
						editable={true}
						selectTextOnFocus={false}
						style={[
							GlobalStyle.boldedText,
							{
								//fontSize: mediumTextSize,
							},
						]}
						placeholder={'0.00'}
						placeholderTextColor={'black'}
						onChange={onChange}
						value={price}
						onEndEditing={onEndEditing}
					/>
				</View>
			</View>
		</>
	)
}

export function CashPayment() {
	const { prices } = useContext(CheckoutScreenContext)

	const [cashReceived, setCashReceived] = useState('')
	const [isTyping, setIsTyping] = useState(false)

	const cashIcon = () => {
		return <MaterialCommunityIcons name="cash" size={global.kiosk ? 35 : 20} color="white" />
	}

	const registerIcon = () => {
		return (
			<MaterialCommunityIcons name="cash-register" size={global.kiosk ? 35 : 20} color="white" />
		)
	}

	const dollarIcon = () => {
		return (
			<FontAwesome
				name="dollar"
				size={global.kiosk ? 35 : 20}
				color={'white'}
				style={{ alignSelf: 'center', justifyContent: 'center' }}
			/>
		)
	}

	return (
		<View style={{ flex: 1 }}>
			<Text
				style={[
					GlobalStyle.headerText,
					{
						textAlign: 'center',
						marginBottom: 50,
						marginTop: 50,
					},
				]}
			>
				HOW MUCH CASH ARE YOU RECEIVING?
			</Text>
			<CashInput
				name="RECEIVED"
				price={cashReceived}
				icon={cashIcon()}
				onChange={value => setCashReceived(value)}
				onEndEditing={() => setIsTyping(false)}
			/>
			<View style={{ marginBottom: 30, marginTop: 15 }}>
				<NumberPad
					style={{
						marginBottom: 0,
						marginRight: 30,
						marginLeft: 35,
					}}
					numLength={12}
					buttonSize={60}
					buttonItemStyle={{ backgroundColor: '#d6d6d6', borderRadius: 12, width: '80%' }}
					activeOpacity={0.1}
					onValueChange={value => setCashReceived(value)}
					allowDecimal={true}
					rightBottomButton={
						<Ionicons name={'ios-backspace-outline'} size={headerTextSize} color={'#000'} />
					}
				/>
			</View>
			{receiptText('TOTAL', prices.total.toFixed(2), dollarIcon())}
			{isTyping || cashReceived === ''
				? null
				: receiptText(
						'CHANGE',
						(parseFloat(cashReceived) - parseFloat(prices.total.toFixed(2))).toFixed(2),
						registerIcon()
				  )}
		</View>
	)
}
