import * as React from 'react'
import { Text, View } from '../Themed'
import GlobalStyle, { largeTextSize, xlTextSize } from '../../constants/GlobalStyle'
import LineItem from './LineItem'
import { PricesView } from './PricesView'
import Colors from '../../constants/Colors'
import DoorDashTracking from '../Delivery/DoorDashTracking'

export function DisplayOrder({
	items,
	prices,
	isCheckout,
	isConfirmed,
	navigation,
	deletePopup,
	loadedData,
	trackingURL,
}) {
	var agg = innerObjects(items, isCheckout, isConfirmed, navigation, deletePopup, loadedData)
	var inner = []
	for (const [key, value] of Object.entries(agg)) {
		var innerVals: any = value
		var rTotal = value[0]
		var restaurantName = value[1]
		inner.push(
			<View key={restaurantName + rTotal}>
				{/* {trackingURL !== null && trackingURL !== '' && (
					<>
						<DoorDashTracking url={trackingURL} />
					</>
				)} */}
				<RestaurantHeader
					name={restaurantName}
					isCheckout={isCheckout}
					key={restaurantName + rTotal}
				/>
			</View>
		)
		for (var i = 2; i < innerVals.length; i++) {
			var singleItem = innerVals[i]
			inner.push(singleItem)
		}
	}
	if (!isCheckout) {
		inner.push(<PricesView key={'prices_' + prices.total} prices={prices} />)
	}
	return <>{inner}</>
}

//aggregate all items under their specific restaurant
//agg[key][0] = restaurant's total
//agg[key][1] = restaurant heading
//agg[key][2] = array of items
function innerObjects(items, isCheckout, isConfirmed, navigation, deletePopup, loadedData) {
	var agg = {}
	items.forEach(item => {
		var curr = agg[item.rId]
		var num = (isCheckout ? item.ppu / 100 : Number(item.total / 100)) * item.qty
		var modAmount = 0
		item.mods.forEach(mod => {
			var modPrice = isCheckout ? mod.priceMoney.amount / 100 : Number(mod.price)
			modAmount += modPrice
		})
		num += modAmount

		if (curr) {
			curr[0] += num
			curr.push(
				<LineItem
					key={'LINE_ITEM_' + item.id + item.idempotencyKey}
					item={item}
					navigation={navigation}
					isCheckout={isCheckout}
					isConfirmed={isConfirmed}
					deletePopup={deletePopup}
					loadedData={loadedData}
				/>
			)
			agg[item.rId] = curr
		} else if (loadedData[item.rId]) {
			agg[item.rId] = [num]
			agg[item.rId].push(loadedData[item.rId].name)

			agg[item.rId].push([
				<LineItem
					key={'LINE_ITEM_' + item.id + item.idempotencyKey}
					item={item}
					navigation={navigation}
					isCheckout={isCheckout}
					isConfirmed={isConfirmed}
					deletePopup={deletePopup}
					loadedData={loadedData}
				/>,
			])
		}
	})

	return agg
}

//display the restaurant heading above the items in the order from that restaurant
function RestaurantHeader({ name, isCheckout }) {
	isCheckout = false
	return (
		<View>
			<Text
				style={{
					paddingHorizontal: 15,
					paddingBottom: 10,
					fontSize: xlTextSize,
					marginBottom: 10,
					fontWeight: '200',
					color: Colors.greyscale[8],
				}}
			>
				{name}
			</Text>
		</View>
	)
}
